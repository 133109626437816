import { Component, Output, Input, EventEmitter, HostBinding } from '@angular/core';


import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { VideoTemplatesService } from 'rev-shared/video/VideoTemplates.Service';
import { WebcastTemplatesService } from 'rev-shared/webcast/WebcastTemplates.Service';

import styles from './TemplateMenu.module.less';

export interface ITemplate {
	id: string;
	name: string;
	metadata: any;
}

export interface ITemplateMenuService {
	getTemplates(): Promise<ITemplate[]>;
}

@Component({
	selector: 'template-menu',
	templateUrl: './TemplateMenu.Component.html'
})
export class TemplateMenuComponent {
	@Input() public tooltipMsg: string;
	@Input() public templatesService: WebcastTemplatesService | VideoTemplatesService;
	@Output() public onTemplateSelected: EventEmitter<any> = new EventEmitter();

	@HostBinding('style.--accentColor')
	public get accentColor(): string {
		return this.themeService.brandingSettings.themeSettings.accentColor;
	}

	@HostBinding('style.--primaryColor')
	public get primaryColor(): string {
		return this.themeService.brandingSettings.themeSettings.primaryColor;
	}

	public readonly styles = styles;

	constructor(
		private themeService: ThemeService,
	) { }

	public applyTemplate(id: string): void {
		this.onTemplateSelected.emit(id);
	}
}
