import {
	Input,
	OnChanges,
	Directive,
	OnDestroy,
	OnInit
} from '@angular/core';

import { appendStyles, applyStyles, removeStyle } from './CssRules';
import { IRules } from './CssRules.Contract';

@Directive({
	selector: '[vbCssRules]',
})
export class CssRulesDirective implements OnInit, OnChanges, OnDestroy {
	@Input() public vbCssRules: IRules;
	@Input() public id: string;

	public style: HTMLStyleElement;

	public ngOnInit(): void {
		this.style = appendStyles(this.id);
		applyStyles(this.style, this.vbCssRules);
	}

	public ngOnChanges(): void {
		applyStyles(this.style, this.vbCssRules);
	}

	public ngOnDestroy(): void {
		removeStyle(this.style);
	}
}
