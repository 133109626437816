import { Injectable } from '@angular/core';

import { PushService } from 'rev-shared/push/PushService';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';

const presentationUploadUrl = '/uploads/presentations/';

@Injectable({
	providedIn: 'root'
})
export class WebcastPresentationService {
	constructor(
		private PushService: PushService
	){
	}

	public setCurrentPresentationSlide(webcastId: string, index: number): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:SetCurrentPresentationSlide', {
			webcastId,
			slideNumber: index + 1
		});
	}

	public uploadPresentationFile(file: FileWrapper, webcastId: string): Promise<void> {
		file.setOptions({
			url: presentationUploadUrl + webcastId
		});

		return file.submit()
			.catch(() => Promise.reject('UploadFailed')) as any; // TODO: temporary
	}

	public removePresentation(webcastId: string): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:RemoveWebcastPresentation', { webcastId });
	}
}
