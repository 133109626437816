import {
	Component,
	Input
} from '@angular/core';

import { BaseInlineEditAngularComponent } from './BaseInlineEditAngularComponent';

@Component({
	selector: 'vb-ui-inline-text-edit',
	templateUrl: './VbUiInlineTextEdit.Component.html'
})
export class VbUiInlineTextEditComponent extends BaseInlineEditAngularComponent<string> {
	@Input() public text: string;
}
