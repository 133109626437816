import {
	Directive,
	Input,
	OnChanges
} from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { pick } from 'underscore';

@Directive({
	selector: '[vbValidationConditions]',
	providers: [{ provide: NG_VALIDATORS, useExisting: VbValidationConditionsDirective, multi: true }]
})
export class VbValidationConditionsDirective implements Validator, OnChanges {
	@Input() public vbValidationConditions: { readonly [errorKey: string]: boolean };

	public control: AbstractControl;

	public ngOnChanges(): void {
		if (!this.control) {
			return;
		}

		const validFlags = this.vbValidationConditions;
		if(!validFlags) {
			return;
		}

		this.errorKeys.forEach(errorKey => {
			const isValid = validFlags[errorKey];
			if (isValid || this.control?.errors?.[errorKey]) {
				window.setTimeout(() => {
					this.control.updateValueAndValidity();
				}, 200);
			}
		});
	}

	public validate(control: AbstractControl): ValidationErrors | null {
		if (!control || !this.vbValidationConditions) {
			return;
		}
		this.control = control;

		const errorFlags = pick(this.vbValidationConditions, (value, _key) => !!value);

		return Object.keys(errorFlags).some(key => errorFlags[key]) ? errorFlags : null;
	}

	private get errorKeys(): string[] {
		return Object.keys(this.vbValidationConditions || {});
	}
}
