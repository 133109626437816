import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VbUiFormGroupComponent } from './VbUiFormGroupAngular.Component';
import { VbUiHorizontalFormGroupComponent } from './VbUiHorizontalFormGroup.Component';

@NgModule({
	declarations: [
		VbUiFormGroupComponent,
		VbUiHorizontalFormGroupComponent
	],
	exports: [
		VbUiFormGroupComponent,
		VbUiHorizontalFormGroupComponent
	],
	imports: [
		CommonModule
	]
})
export class FormGroupAngularModule { }
