
import { Injectable } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/angular';
import { trace } from '@uirouter/core';
import { StateTransitionScrollToTop } from './StateTransitionScrollToTop.Run';
import { configureStateTransitionLogging } from './ConfigureStateTransitionLogging.Config';

@Injectable({
	providedIn: 'root'
})
export class BootstrapConfig {
	constructor(
		transitions: TransitionService,
		stateService: StateService
	) {
		StateTransitionScrollToTop(transitions);
		configureStateTransitionLogging(stateService, trace, transitions);
	}
}
