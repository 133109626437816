import {
	AfterViewInit,
	Directive,
	ElementRef,
	Input
} from '@angular/core';

@Directive({
	selector: '[vbAutoFocus]',
})
export class VbAutoFocusDirective implements AfterViewInit {
	@Input() public disableAutoFocus: boolean;

	private nativeElement: HTMLElement;

	constructor(
		elementRef: ElementRef
	) {
		this.nativeElement = elementRef.nativeElement;
	}

	public ngAfterViewInit(): void {
		this.setFocus();
	}

	public setFocus(): void {
		if (this.disableAutoFocus) {
			return;
		}

		setTimeout(() => this.nativeElement?.focus(), 300);
	}
}
