import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { StateService, Transition, TransitionService } from '@uirouter/angular';

/**
 * An alternative to ui-router uiSrefActive directive. The existing uiSrefActive does not work in some
 * scenario where there are nested views and it needed to be used in parent element.
 *  check PlaylistDetail.Component.html for usage.
 */
@Directive({
	selector: '[vbUiSrefActive]',
})
export class VbUiSrefActiveDirective {
	@Input() public vbUiSrefActive: string;
	@Input() public activeState: string;

	constructor(
		private elementRef: ElementRef,
		private $state: StateService,
		$transitions: TransitionService
	) {
		$transitions.onSuccess({}, trans => this.onTransitionComplete(trans));
	}

	public ngOnInit(): void {
		this.toggleActiveState(this.$state.current.name);
	}

	private onTransitionComplete(trans: Transition): void {
		this.toggleActiveState(trans?.to()?.name);
	}

	private toggleActiveState(currentState: string): void {
		this.elementRef.nativeElement.classList.toggle(this.vbUiSrefActive, currentState?.includes(this.activeState));
	}
}
