import { ControlValueAccessor } from '@angular/forms';

export class BaseControlValueAccessor<T> implements ControlValueAccessor {
	protected onChangeFn: (val: T) => void;
	protected onTouchFn: () => void;

	public isDisabled: boolean;
	public value: T;

	protected updateModelValue(val: T, ignoreOnChange?: boolean): void {
		this.value = val;
		if (!ignoreOnChange) {
			this.onChangeFn?.(val);
			this.onTouchFn?.();
		}
	}

	public writeValue(val: T): void {
		this.value = val;
	}

	public registerOnChange(fn: any): void {
		this.onChangeFn = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouchFn = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}
}
