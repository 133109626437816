import { Component, Inject, OnInit } from '@angular/core';

import { SignalRHubsConnection, SignalRHubsConnectionState } from './SignalRHubsConnection';
import { SignalRHubsConnectionToken } from './SignalRHubsConnectionToken';

@Component({
	selector: 'signalr-connection-status',
	templateUrl: './SignalRConnectionStatus.Component.html'
})
export class SignalrConnectionStatusComponent implements OnInit {
	public pushConnectionInitialized: boolean;
	public pushConnectionHealthy: boolean;

	constructor(
		@Inject(SignalRHubsConnectionToken) public signalrHubsConnection: SignalRHubsConnection
	) {}

	public ngOnInit(): void {
		this.signalrHubsConnection.on({
			stateChanged: event => {
				this.pushConnectionHealthy =
					!this.signalrHubsConnection.started ||
					event.newState === SignalRHubsConnectionState.Connected;

				if (this.pushConnectionHealthy) {
					this.pushConnectionInitialized = true;
				}
			}
		});
	}
}
