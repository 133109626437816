import {
	Directive,
	Input,
	forwardRef
} from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';

import { ValidationRules } from 'rev-shared/util/ValidationRules';

@Directive({
	selector: '[vbHostname]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => VbHostNameDirective),
		multi: true
	}]

})
export class VbHostNameDirective {
	@Input() public vbEmailActive: boolean;

	public validate(control: AbstractControl): ValidationErrors {
		return ValidationRules.checkHostnameText(control.value) ? null : { vbHostname: true };
	}
}
