import { Directive, Input, OnChanges } from '@angular/core';
import { TabDirective } from 'ngx-bootstrap/tabs';

/**
 * Created to be used with ngx-bootstrap's tab compnonent
 *
 * Given an input of tab index, other tabs in the tabset with this directive
 * will be ordered in ascending.
 *
 * This addresses a bug with ngx-bootstrap's tab component. When tabs have
 * a dynamic directive (ngIf, ngFor, etc.) they will not appear in the correct
 * order.
 */

@Directive({
	selector: '[tabOrder]'
})
export class NgxBoostrapTabOrderDirective implements OnChanges {
	@Input() public tabOrder = 0;

	constructor(private tab: TabDirective) {}

	public ngOnChanges(): void {
		(this.tab as any).__tabOrder = +this.tabOrder;
		this.tab.tabset.tabs.sort((a: any, b: any) => a.__tabOrder - b.__tabOrder);
	}
}
