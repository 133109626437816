
import {
	Category,
	Rejection,
	StateService,
	TransitionService,
	Trace
} from '@uirouter/angular';

export function configureStateTransitionLogging($state: StateService, $trace: Trace, $transitions: TransitionService){
	//enable uirouter transition logging
	$trace.enable(Category.TRANSITION);

	$transitions.onError({}, transition => console.log('Unable to transition to state: ', transition.to().name, transition.error()));

	$state.onInvalid(toState => console.log('Invalid state transition: ', toState));

	// custom error handler based on the default in uirouter
	$state.defaultErrorHandler($error$ => {
		if ($error$ instanceof Error && $error$.stack) {
			console.error($error$);
			console.error($error$.stack);
		} else if ($error$ instanceof Rejection) {
			// rejections are expected, so use log() rather than error() to prevent confusion
			console.log($error$.toString());

			if ($error$.detail && $error$.detail.stack) {
				console.log($error$.detail.stack);
			}
		} else {
			console.error($error$);
		}
	});
}
