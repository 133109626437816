import {
	Component,
	ElementRef,
	OnInit
} from '@angular/core';


import styles from './VbGridList.module.less';

@Component({
	selector: 'vb-grid-list-row',
	template: '<ng-content></ng-content>'
})
export class VbGridListRowComponent implements OnInit {
	constructor(private el: ElementRef<HTMLElement>) {
	}

	public ngOnInit(): void {
		this.el.nativeElement.classList.add(styles.gridRow);
	}

}
