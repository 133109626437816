<ul class="nav" [ngClass]="classMap"
    (click)="$event.preventDefault()"
    [attr.aria-label]="ariaLabel"
    role="tablist">
  <li *ngFor="let tabz of tabs; let i = index" [ngClass]="['nav-item', tabz.customClass || '']"
      [class.active]="tabz.active" [class.disabled]="tabz.disabled" (keydown)="keyNavActions($event, i)">
    <a href="javascript:void(0);" class="nav-link" role="tab"
       [attr.aria-controls]="tabz.id ? tabz.id : ''"
       [attr.aria-selected]="!!tabz.active"
       [attr.id]="tabz.id ? tabz.id + '-link' : ''"
       [class.active]="tabz.active" [class.disabled]="tabz.disabled"
       (click)="tabz.active = true">
      <span [ngTransclude]="tabz.headingRef">{{ tabz.heading }}</span>
      <span *ngIf="tabz.removable" (click)="$event.preventDefault(); removeTab(tabz);" class="bs-remove-tab"> &#10060;</span>
    </a>
  </li>
</ul>
<div class="tab-content">
  <ng-content></ng-content>
</div>
