import {
	Directive
} from '@angular/core';

import styles from './vb-ui-radio-btn-group.module.less';

/**
 * <div vbUiRadioBtnGroup>
			<button
				type="button"
				[(ngModel)]="buttonValue"
				vbUiRadioBtn="Public">
				Public
			</button>
			<button
				type="button"
				[(ngModel)]="buttonValue"
				vbUiRadioBtn="AllUser">
				All User
			</button>
			<button
				type="button"
				[(ngModel)]="buttonValue"
				vbUiRadioBtn="Private">
				Private
			</button>
 * </div>
 */

@Directive({
	selector: '[vbUiRadioBtnGroup]',
	host: {
		'[class]': 'styles.btnToggleWrapper',
		role: 'radiogroup'
	}
})
export class VbUiRadioBtnGroupDirective {
	public readonly styles = styles;
}
