import {
	each,
	indexOf,
	Dictionary
} from 'underscore';

export interface IObservableConfig {
	context?: any;
	listeners?: Dictionary<Listener>;
}
import { Observable as rxjsObservable } from 'rxjs';

type Listener = (...args: any[]) => void;
interface IListeners {
	[eventType: string]: Listener[];
}

export interface IObservable {
	fire(eventType: string, ...args: string[]): void;
	hasSubscribers(eventType?: string): boolean;
	off(eventType: string, listener: Listener): void;

	on(eventType: string, listener: Listener): void;
	on(listeners: Dictionary<Listener>): void;

	asRxObservable<T>(eventType: string): rxjsObservable<T>;
}

export class Observable implements IObservable {
	private context: any;
	private eventListeners: IListeners;

	constructor(cfg?: IObservableConfig) {
		cfg = cfg || {};

		// eventType -> array of listener functions
		this.eventListeners = {};
		this.context = cfg.context || this;

		if (cfg.listeners) {
			this.on(cfg.listeners);
		}
	}

	public on(eventType: string | Dictionary<Listener>, listener?: Listener): void {
		if (arguments.length === 1) {
			each(eventType as Dictionary<Listener>, (l, e) => this.on(e, l));
		} else {
			const e = eventType as string;
			let subscribers = this.eventListeners[e];

			if (!subscribers) {
				subscribers = this.eventListeners[e] = [];
			}

			subscribers.push(listener);
		}
	}

	//creates an observable for one event type. data will be 1st arg passed to fire()
	public asRxObservable<T>(eventType: string): rxjsObservable<T> {
		return new rxjsObservable<T>(observer => {
			const listener = (data: T) => observer.next(data);
			this.on(eventType, listener);
			return () => this.off(eventType, listener);
		});
	}

	public off(eventType: string, listener: Listener): void {
		const listeners = this.eventListeners[eventType] || [];
		const i = indexOf(listeners, listener);

		if (i >= 0) {
			listeners.splice(i, 1);
		}
	}

	public fire(eventType: string, ...args: any[]): void {
		const subscribers = this.eventListeners[eventType] || [];

		subscribers.slice().forEach(listener => {
			listener.apply(this.context, args);
		}, this);
	}

	public hasSubscribers(eventType?: string): boolean {
		let numListeners = 0;
		const listeners = this.eventListeners;

		if (eventType) {
			return listeners[eventType] && listeners[eventType].length > 0;
		}

		const events = Object.keys(listeners);

		each(events, event => {
			numListeners += listeners[event] ? listeners[event].length : 0;
		});

		return numListeners > 0;
	}
}
