import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { TranslateLoader, TranslateModule, TranslateParser, TranslateService } from '@ngx-translate/core';

import { SessionExtendHttpInterceptorProvider } from 'rev-shared/security/SessionExtendHttpInterceptor.Provider';
import { StateChangeStatus } from 'rev-shared/util/StateChangeStatus.Service';

import { HttpParamsInterceptorProvider } from './HttpParamsInterceptor.Provider';
import { LocaleProvider } from './Locale.Provider';
import { VbTranslateParser } from './VbTranslateParser';
import { VbXsrfCookieExtractorProvider } from './VbXsrfCookieExtractor.Provider';
import { createTranslateHttpLoader } from './TranslateHttpLoader.Provider';
import { locale$ } from './LocaleContext';

import { BootstrapConfig } from '../config/BootstrapConfig.Service';
import { ExceptionHandler } from '../config/ExceptionHandler.config';

//Import on lazy loaded root modules
@NgModule({
	imports: [
		HttpClientModule
	],
	providers: [
		HttpParamsInterceptorProvider,
		SessionExtendHttpInterceptorProvider,
		VbXsrfCookieExtractorProvider, // faciliates our dyanmic cookie naming
	]
})
export class AngularRootModuleDeps {}

//import on app root module
@NgModule({
	imports: [
		AngularRootModuleDeps,
		BrowserAnimationsModule,
		BrowserModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateHttpLoader,
				deps: [
					HttpClient
				]
			},
			parser: {
				provide: TranslateParser,
				useClass: VbTranslateParser
			},
			useDefaultLang: false
		})
	],
	providers: [
		LocaleProvider,
		{
			provide: APP_INITIALIZER,
			deps: [TranslateService, BootstrapConfig, StateChangeStatus],
			useFactory: (translateService: TranslateService, _bootstrapConfig: BootstrapConfig, _StateChangeStatus: StateChangeStatus) => () => {
				locale$.subscribe(({ language }) => translateService.use(language));
			},
			multi: true
		},
		{ provide: ErrorHandler, useClass: ExceptionHandler }
	]
})
export class AngularAppBootstrapDepsModule {}
