import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { PushBus } from 'rev-shared/push/PushBus.Service';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { PushService } from 'rev-shared/push/PushService';

import { Poll } from './Poll';
import { PollStatus } from './PollStatus';
import { Polls } from './Polls';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { WebcastModel } from '../webcast/model/WebcastModel';
import { PollsService } from './Polls.Service';

@Injectable({
	providedIn: 'root'
})
export class PollsModelService {
	constructor(
		private PollsService: PollsService,
		private PushBus: PushBus,
		private PushService: PushService,
		private UserContext: UserContextService
	) {}

	public initPolls(webcast: WebcastModel): Polls {
		return new Polls(webcast, this, this.PollsService);
	}

	public subscribePush(webcast: WebcastModel): Observable<void> {
		return webcast.currentUser.isEventAdminOrModerator$.pipe(
			switchMap(() => new Observable<void>(() =>
				webcast.currentUser.canManagePolls ?
					this.registerPollManagementPushHandlers(webcast.polls) :
					this.registerAttendeePushHandlers(webcast.polls)
			))
		);
	}

	public registerAttendeePushHandlers(polls: Polls): IUnsubscribe {
		const unsubscribe = this.PushBus.subscribe(polls.webcast.id, {
			PollOpened: data => this.onPollOpened(data, polls),
			PollClosed: removePoll,
			PollPublished: data => this.onPollPublished(data, polls),
			PollUnpublished: removePoll,

			WebcastDetailsSaved: data => polls.onWebcastDetailsSaved(data),
			WebcastEnded: data => data.shouldResetPolls && this.resetPolls(polls)
		});

		function removePoll(data) {
			polls.$polls = (polls.$polls || []).filter(poll => poll.id !== data.pollId);
		}

		return unsubscribe;
	}

	public registerPollManagementPushHandlers(polls: Polls): IUnsubscribe {
		const unsubscribePollAnswerRecorded = this.PushBus.subscribe(polls.webcast.id, 'Webcast.EventAdmin', {
			PollAnswerRecorded: data => {

				polls.$polls = polls.$polls.map(p => {
					return p.id !== data.pollId ? p : p.copy({
						totalResponses: data.totalResponses,
						answers: p.answers.map((a, i) => {
							return {
								...a,
								count: data.results[i] || 0
							};
						})
					});
				});
			},

			WebcastPollsAdded: data => {
				const newPolls = data.polls.map(p => new Poll(p, polls.webcast, this));
				polls.$polls = (polls.$polls || []).concat(newPolls);
			},

			WebcastPollsUpdated: data => {
				polls.$polls = (polls.$polls || []).map(poll => {
					const newPoll = (data.polls || []).find(p => p.id === poll.id);
					if (newPoll) {
						Object.assign(poll, {
							question: newPoll.question,
							multipleChoice: newPoll.multipleChoice,
							answers: (newPoll.answers || []).map(answer => {
								return {
									text: answer
								};
							})
						});
					}
					return poll;
				});
			},

			WebcastPollsDeleted: data => {
				polls.$polls = (polls.$polls || []).map(poll => {
					if (data.pollIds.find(id => id === poll.id)) {
						return;
					}
					return poll;
				}).filter(Boolean);
			}
		});

		const unsubscribePollUpdates = this.PushBus.subscribe(polls.webcast.id, {

			WebcastDetailsSaved: data => polls.onWebcastDetailsSaved(data),

			PollOpened: data => this.onPollOpened(data, polls),
			PollClosed: data => this.onClosedPoll(data, polls),

			PollPublished: data => this.onPollPublished(data, polls),
			PollUnpublished: data => this.onClosedPoll(data, polls)
		});

		return this.PushBus.composeUnsubscribeFn([
			unsubscribePollAnswerRecorded,
			unsubscribePollUpdates
		]);
	}

	private onPollOpened(data: any, polls: Polls): void {
		const poll = (polls.$polls || []).find(poll => poll.id === data.pollId);
		if (poll) {
			poll.status = PollStatus.Open;
			polls.$polls = this.getUpdatedPollCollection(polls.$polls, poll);
			return;
		}

		polls.$polls = polls.$polls.concat([
			new Poll({
				id: data.pollId,
				status: PollStatus.Open,
				question: data.question,
				multipleChoice: data.multipleChoice,
				answers: (data.answers || []).map(answer => {
					return {
						text: answer
					};
				})
			},
			polls.webcast,
			this)]
		);
	}

	public resetPolls(polls: Polls): void {
		polls.$polls = [];
	}

	private onPollPublished(data: any, polls: Polls): void {
		const poll = (polls.$polls || []).find(poll => poll.id === data.pollId);

		if (poll) {
			poll.status = PollStatus.Published;
			polls.$polls = this.getUpdatedPollCollection(polls.$polls, poll);
			return;
		}
		polls.$polls = polls.$polls.concat([
			new Poll({
				id: data.pollId,
				status: PollStatus.Published,
				question: data.question,
				multipleChoice: data.multipleChoice,
				answers: data.answers,
				totalResponses: data.totalResponses
			},
			polls.webcast,
			this)]
		);
	}

	private onClosedPoll(data: any, polls: Polls): void {
		polls.$polls = this.getUpdatedPollCollection(polls.$polls, { id: data.pollId, status: PollStatus.Closed });
	}


	private getUpdatedPollCollection(polls: Poll[], updatedPoll: Partial<Poll>): Poll[] {
		return polls.map(p => {
			if (p.id === updatedPoll.id) {
				Object.assign(p, updatedPoll);
			}
			return p;
		});
	}

	public openPoll(pollId: string): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:OpenPoll', { pollId });
	}

	public closePoll(pollId: string): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:ClosePoll', { pollId });
	}

	public publishPoll(pollId: string): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:PublishPoll', { pollId });
	}

	public unpublishPoll(pollId: string): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:UnpublishPoll', { pollId });
	}

	public answerPoll(webcastId: string, runNumber: number, pollId: string, answers: boolean[]): Promise<void> {
		const userId = this.UserContext.getUser().id;
		return this.PushService.dispatchCommand('scheduledEvents:AnswerPoll', {
			scheduledEventId: webcastId,
			runNumber,
			userId,
			pollId,
			answers
		});
	}
}
