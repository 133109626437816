import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VbUiThumbnailSheetPreviewComponent } from './VbUiThumbnailSheetPreview.Component';
import { UIRouterModule } from '@uirouter/angular';

const declarations = [
	VbUiThumbnailSheetPreviewComponent
];

@NgModule({
	declarations: declarations,
	exports: declarations,
	imports: [
		CommonModule,
		UIRouterModule
	]
})
export class ThumbnailSheetPreviewModule {}
