import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { Directive, forwardRef, ElementRef, Renderer2, HostListener } from '@angular/core';

import { parseMeetingId, validateMeetingIdFormat, formatMeetingId } from './ZoomMeetingId';

@Directive({
	selector: '[ngModel][vbZoomMeetingId]',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => VbZoomMeetingIdDirective),
		multi: true,
	}, {
		multi: true,
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => VbZoomMeetingIdDirective)
	}]
})
export class VbZoomMeetingIdDirective implements Validator {
	private nativeElement: HTMLInputElement;
	private onChange: (value: string) => void;
	public onTouched: () => void;

	constructor(
		Element: ElementRef,
		private renderer : Renderer2
	) {
		this.nativeElement = Element.nativeElement;
	}

	@HostListener('input', ['$event.target.value'])
	public input(value: string): void {
		this.onTouched();
		const parsedVal = parseMeetingId(value);
		setTimeout(() => {
			this.setMeetingId(parsedVal);
			this.onChange(parsedVal);
		}, 200);

	}

	@HostListener('blur')
	public onBlur(): void {
		this.onTouched();
	}

	public writeValue(value : string) : void {
		this.setMeetingId(value);
	}

	public registerOnChange( fn : any ) : void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public validate(control: AbstractControl): ValidationErrors {
		return !validateMeetingIdFormat(control.value) ? { zoomMeetingId: true } : null;
	}

	private setMeetingId(value: string): void {
		this.renderer.setProperty(this.nativeElement, 'value', formatMeetingId(value) ?? '');
	}
}
