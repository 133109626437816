import { AfterContentInit, Component, ContentChildren, EventEmitter, HostBinding, Input, Output, QueryList, SimpleChanges, TemplateRef } from '@angular/core';

import { VbUiTabComponent } from './VbUiTab.Component';

import styles from './VbUiTabPanel.Component.module.less';

@Component({
	selector: 'vb-ui-tab-panel',
	templateUrl: './VbUiTabPanel.Component.html'
})
export class VbUiTabPanelComponent implements AfterContentInit {
	@Input() public allowSelectedTabClick: boolean;
	@Input() public activeTabId: string;
	@Input() public verticalFill: boolean;
	@Input() public tabPanelInfoTemplate: TemplateRef<any>;
	@Input() public themed: boolean;

	@Output() public onSelect = new EventEmitter<{ tabId: string }>();

	@ContentChildren(VbUiTabComponent) public tabs: QueryList<VbUiTabComponent>;

	public readonly styles = styles;
	public readonly tabPanelId: string = '' + Math.random();
	public readonly themedTabSelectedClass = `${styles.tabActive} theme-primary-important theme-primary-font-border-fade-50`;
	public readonly tabSelectedClass = `${styles.tabActive} ${styles.tabActiveFixedColor}`;

	public selectedTab: VbUiTabComponent;

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.verticalFill
				&& !changes.verticalFill.isFirstChange()) {
			this.selectedTab?.toggleCssClass(styles.tabVerticalFill, this.verticalFill);
		}
	}

	public ngAfterContentInit(): void {
		setTimeout(() => {
			const tab = this.activeTabId ? this.tabs.find(tab => tab.tabId === this.activeTabId) : this.tabs.first;
			this.selectTab(tab);
		});
	}

	@HostBinding('attr.layout')
	public get layout(): string {
		return this.verticalFill ?
			'column' :
			undefined;
	}

	public selectTab(tab: VbUiTabComponent): void {
		if (!this.allowSelectedTabClick && this.selectedTab === tab) {
			return;
		}

		if(this.selectedTab) {
			this.setTabActive(this.selectedTab, false);
		}
		this.setTabActive(tab, true);
		this.selectedTab = tab;

		this.onSelect.emit({ tabId: tab.tabId });
	}

	private setTabActive(tab: VbUiTabComponent, isActive: boolean): void {
		tab.toggleCssClass(styles.tabVerticalFill, this.verticalFill && isActive);
		tab.isActive = isActive;
	}
}
