import { IVbrickPlayerConfig } from 'vbrick-player-src/IVbrickPlayerConfig';

import { IRevPlayerSettings } from './IRevPlayerSettings';

export const VbrickPlayerDefaultConfig: IVbrickPlayerConfig = {
	hls: {
		isBitrateObserved: true,
		isFragLoadWorker: false,
		isFragLoadWorkerForDual: true,
		isMbrPlaybackDisabledForDual: false,
		isParseTimeIgnored: true
	}
};

export function mergeWithDefaultPlayerConfig(overrides: Partial<IRevPlayerSettings>): IVbrickPlayerConfig {
	return {
		hls: {
			...VbrickPlayerDefaultConfig.hls,
			...overrides // flat object, but presently consists exclusively of hls settings
		}
	};
}
