<vb-title [title]="'Title_ResetPassword' | translate"></vb-title>

<vb-auth-layout>

		<div [hidden]="!status.processing && !status.loading">
			<vb-loading-spinner [msg]="'Loading' | translate" [size]="'large'">
			</vb-loading-spinner>
		</div>

		<div [hidden]="!status.invalidToken" [ngClass]="authLayoutStyles.authMsg">
			<div [ngClass]="[authLayoutStyles.centered, authLayoutStyles.logoWrapper]">
				<branding-logo></branding-logo>
			</div>
			<h3 [ngClass]="authLayoutStyles.centered">{{ 'PasswordReset_InvalidLink' | translate }}</h3>
			<button class="btn btn-primary theme-accent theme-accent-txt margin-top-20" [ngClass]="authLayoutStyles.authBtn" uiSref="login">{{ 'ReturnToLogin' | translate }}</button>
		</div>

		<div [hidden]="!status.error" [ngClass]="authLayoutStyles.authMsg">
			<div [ngClass]="[authLayoutStyles.centered, authLayoutStyles.logoWrapper]">
				<branding-logo></branding-logo>
			</div>
			<h3 [ngClass]="authLayoutStyles.centered">{{ 'PasswordReset_Error' | translate }}</h3>
			<button class="btn btn-primary theme-accent theme-accent-txt margin-top-20" [ngClass]="authLayoutStyles.authBtn" uiSref="login">{{ 'ReturnToLogin' | translate }}</button>
		</div>

		<div [hidden]="!status.awaitingSecurityQuestionReset" [ngClass]="authLayoutStyles.authMsg">
			<div [ngClass]="[authLayoutStyles.centered, authLayoutStyles.logoWrapper]">
				<branding-logo></branding-logo>
			</div>
			<h3 [ngClass]="authLayoutStyles.centered">{{ 'PasswordReset_LimitReached' | translate }}</h3>
			<button class="btn btn-primary theme-accent theme-accent-txt margin-top-20" [ngClass]="authLayoutStyles.authBtn" uiSref="login">{{ 'ReturnToLogin' | translate }}</button>
		</div>

		<div [hidden]="!status.active">

			<div [ngClass]="[authLayoutStyles.centered, authLayoutStyles.logoWrapper]">
				<branding-logo></branding-logo>
			</div>

			<div [ngClass]="authLayoutStyles.heading">
				<h2>{{ 'ForgotPasswordNoQuestion' | translate }}
			</h2></div>

			<form #passwordResetForm="ngForm" [ngClass]="authLayoutStyles.authForm" name="passwordResetForm" (submit)="submit()">

				<div [hidden]="!status.passwordResetFailed" [ngClass]="authLayoutStyles.authMsg">
					{{ 'PasswordReset_Failed' | translate }}
				</div>

				<h4 class="margin-top-20">{{ 'SecurityQuestion' | translate }}</h4>

				<div>
					<label for="rpSecurityAnswer">{{ passwordReset.securityQuestion }}</label>
					<input type="text" id="rpSecurityAnswer" name="securityAnswer" class="form-control theme-header-bg theme-header-txt" [placeholder]="'Answer_Placeholder' | translate" [(ngModel)]="passwordReset.securityAnswer" #securityAnswerControl="ngModel" trim="blur" required>
				</div>

				<h4 class="margin-top-20">{{ 'UserEdit_ResetPasswordButton' | translate }}</h4>

				<div class="margin-top-10">
					<label for="rpNewPassword">{{ 'New_Password' | translate }}</label>
					<input type="password" id="rpNewPassword" name="password" class="form-control theme-header-bg theme-header-txt margin-bottom-5" [placeholder]="'Password_APlaceholder' | translate" [(ngModel)]="passwordReset.password" #passwordControl="ngModel" trim="blur" required>
					<div [ngClass]="authLayoutStyles.pwValidation">
						<div [hidden]="!ctrlPasswordResult?.errorMinimumLength">{{ 'UserConfirm_MinimumLengthIs' | translate }} {{ passwordRules?.minimumLength }}.</div>
						<div [hidden]="!ctrlPasswordResult?.errorMinimumUppercaseLetterCount">{{ 'UserConfirm_MustContainOneUppercaseLetter' | translate }}</div>
						<div [hidden]="!ctrlPasswordResult?.errorMinimumLowercaseLetterCount">{{ 'UserConfirm_MustContainOneLowercaseLetter' | translate }}</div>
						<div [hidden]="!ctrlPasswordResult?.errorMinimumNumberCount">{{ 'UserConfirm_MustContainOneDigit' | translate }}</div>
						<div [hidden]="!ctrlPasswordResult?.errorMinimumSpecialCharacterCount">{{ 'UserConfirm_MustContainOneSpecialCharacter' | translate }}</div>
					</div>
				</div>

				<div class="margin-top-10">
					<label for="rpConfirmPassword">{{ 'ConfirmPassword' | translate }}</label>
					<input type="password" id="rpConfirmPassword" name="confirmPassword" class="form-control theme-header-bg theme-header-txt margin-bottom-5" [placeholder]="'Password_Reenter' | translate" [(ngModel)]="passwordReset.confirmPassword" #confirmPasswordControl="ngModel" trim="blur" required>
					<div [ngClass]="authLayoutStyles.pwValidation">
						<div [hidden]="confirmPasswordControl.pristine || confirmPasswordControl.valid">{{ 'PasswordNoMatch' | translate }}</div>
					</div>
				</div>

				<button type="submit" class="btn btn-primary theme-accent theme-accent-txt margin-top-30" [ngClass]="authLayoutStyles.authBtn" [disabled]="passwordResetForm.invalid">
					{{ 'PasswordReset_Submit' | translate }}
				</button>

			</form>

		</div>

</vb-auth-layout>
