<div id="player-wrap" class="player-wrap vbrick-player vbrick-modal-player" [ngClass]="{'hide-video': isPlaybackOptionsMenuOpen, 'no-native-full-screen': !isNativeFullScreenSupport}" [hidden]="!hasPlugin" (mouseenter)="showControls = true" (mouseleave)="showControls = false">

	<div class="overlay-play" (click)="play()" [hidden]="!(hasPlugin && stream.state !== 'playing')">

		<div class="circle">
			<span class="glyphicons play"></span>
		</div>

		<div class="fixed-ratio-wrapper">
			<div class="fixed-ratio">
				<img [src]="thumbnailUri" [hidden]="!(thumbnailUri && canShowThumbnail)">
			</div>
		</div>
	</div>


	<div class="fixed-ratio-wrapper">
		<div class="fixed-ratio">
			<div class="item">
				<div #playerPlaceholder class="player-placeholder"></div>
			</div>
		</div>
	</div>

	<div class="normal-target">
		<div class="player-controls group" [hidden]="!(showControls || noVideoOverlay)">
			<div class="player-controls-inner">
				<div class="table-cell">
					<button type="button" class="btn icon-button play-pause-btn" [hidden]="stream.state === 'playing'" (click)="play()">
						<span class="glyphicons play"></span>
					</button>
					<button class="btn icon-button play-pause-btn" type="button" [hidden]="!(!stream.isLive && stream.state === 'playing')" (click)="pause()">
						<span class="glyphicons pause"></span>
					</button>
					<button type="button" class="btn icon-button play-pause-btn" [hidden]="!(stream.isLive && stream.state === 'playing')" (click)="stop()">
						<span class="glyphicons stop"></span>
					</button>
				</div>
				<div class="table-cell table-cell-fill video-slider-bar" [hidden]="stream.isLive">
					<div class="fix-firefox">
						<div #progressCtrl class="progress-indicator"></div>
						<div class="time-label" [ngStyle]="currentLocation()">
							{{ stream.currentTime | vbTimespan }}
						</div>
					</div>
				</div>

				<div class="table-cell table-cell-fill" [hidden]="!stream.isLive">
					<div class="video-length video-length-live">
						{{ 'UI_Player_Live' | translate }}
					</div>
				</div>

				<div class="table-cell" [hidden]="stream.isLive">
					<div class="video-length">
						{{ stream.currentTime | vbTimespan }} / {{ stream.duration | vbTimespan }}
					</div>
				</div>

				<div class="table-cell control-spacer"></div>

				<div class="table-cell">
					<button type="button" class="btn icon-button volume-btn" [hidden]="controls.isMuted" (click)="setMute()">
						<span class="glyphicons volume_up"></span>
					</button>
					<button type="button" class="btn icon-button volume-btn" [hidden]="!controls.isMuted" (click)="setMute()">
						<span class="glyphicons mute"></span>
					</button>
				</div>

				<div class="table-cell volume-slider-bar">
					<div #volumeCtrl class="progress-indicator-volume"></div>
				</div>

				<div class="table-cell">
					<button type="button" class="btn icon-button closed-caption-icon" (click)="toggleClosedCaption()">
						CC
					</button>
				</div>

				<vb-playback-menu-button-legacy class="table-cell" [playbackOptions]="playbackOptions" [selectedPlaybackUrl]="videoUrl" (onPlaybackOptionChange)="onPlaybackOptionChange($event)" (onToggle)="onPlaybackOptionMenuToggle($event)">
				</vb-playback-menu-button-legacy>

				<div class="table-cell control-spacer"></div>

				<div class="table-cell">
					<button type="button" class="btn icon-button" (click)="toggleFullScreen()" [hidden]="controls.isIEEmbedVideo">
						<span class="glyphicons fullscreen"></span>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>