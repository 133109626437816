import { Rect } from './Contract';

type FNum = (x: number) => number;
const wideAspect = 16/9;

/**
 * Divide space according to weights in 1 dimension. Returns [start, end] pairs with the margin between each pair
 */
export function divideSpace(weights: number[], margin: number, start: number, range: number): Array<[number, number]> {
	const num = weights.length;
	const marginTotal = (num - 1) * margin;
	const usableSpace = range - marginTotal;
	const totalWeight = weights.reduce((a, b) => a + b, 0);

	let x0 = 0;
	const results = [];
	for(const weight of weights) {
		const size = weight / totalWeight * usableSpace;
		const x1 = x0 + size;
		results.push([
			x0 + start,
			x1 + start
		]);
		x0 = x1 + margin;
	}

	return results;
}

/**
 * Divide space evenly in 1 dimension. Returns [start, end] pairs with the margin between each pair
 */
export function divideSpaceEven(num: number, margin: number, start: number, range: number): Array<[number, number]> {
	const totalMargin = (num - 1) * margin;
	const usableRange = range - totalMargin;
	const itemSize = usableRange / num;

	return Array.from({ length: num }).map((_, i) => {
		const x0 = start + i * (itemSize + margin);
		return [x0, x0 + itemSize];
	});
}

export function insertPadding(rect: Rect, top: number, right: number, bottom: number, left: number): Rect {
	return {
		x: rect.x + left,
		y: rect.y + top,
		w: rect.w - left - right,
		h: rect.h - top - bottom
	};
}

export function scaleRect(rect: Rect, xScale: number, yScale: number): Rect {
	return {
		x: rect.x * xScale,
		y: rect.y * yScale,
		w: rect.w * xScale,
		h: rect.h * yScale
	};
}

/**
 * Adds letterbox on sides or top/bottom as needed to resize the rect to aspect ratio
 */
export function insertLetterbox(rect: Rect, aspect: number = wideAspect): Rect {
	const rectAspect = rect.w / rect.h;
	if(rectAspect < aspect) {
		const h = rect.w / aspect;
		const padding = (rect.h - h) / 2;
		return {
			x: rect.x,
			w: rect.w,
			y: rect.y + padding,
			h
		};
	}

	const w = rect.h * aspect;
	const padding = (rect.w - w) / 2;
	return {
		y: rect.y,
		h: rect.h,
		x: rect.x + padding,
		w
	};
}

/**
 * Creates an nx * ny grid. Each tile will have the same size, 16/9 aspect ratio, and be separated vertically/horizontally by margin
 */
export function gridLayout(container: Rect, nx: number, ny: number, margin: number): Rect[] {
	container = resizeGridContainer(container, nx, ny, margin, wideAspect);
	const xs = divideSpaceEven(nx, margin, container.x, container.w);
	const ys = divideSpaceEven(ny, margin, container.y, container.h);

	return ys.flatMap(([y0, y1]) =>
		xs.map(([x0, x1]) => ({
			x: x0,
			w: x1 - x0,
			y: y0,
			h: y1 - y0
		})));
}

/**
 * Creates a pseudo 'grid' of two rows( nTop, nBottom).
 * Each cell will have the same size, 16/9 aspect, and be separated horizontally and vertically by margin
 */
export function rowGridLayout(container: Rect, nTop: number, nBottom: number, margin: number): Rect[] {
	container = resizeGridContainer(container, Math.max(nTop, nBottom), 2, margin, wideAspect);
	const [[top0, top1], [bottom0, bottom1]] = divideSpaceEven(2, margin, container.y, container.h);

	const top = {
		x: container.x,
		w: container.w,
		y: top0,
		h: top1 - top0
	};


	const bottom = {
		x: container.x,
		w: container.w,
		y: bottom0,
		h: bottom1 - bottom0
	};

	return [
		...gridLayout(top, nTop, 1, margin),
		...gridLayout(bottom, nBottom, 1, margin)
	];
}

/**
 * Resizes a grid container such that each cell can have the exact same size and aspect ratio.
 * Similar to letterboxing, the container will either shrink horizontally or vertically
 */
function resizeGridContainer(container: Rect, nx: number, ny: number, margin: number, aspect: number): Rect {
	let cellW = (container.w - (nx - 1) * margin) / nx;
	let cellH = (container.h - (ny - 1) * margin) / ny;
	const cellAspect = cellW / cellH;

	if(cellAspect < aspect) {
		//pack vertically
		cellH = cellW / aspect;
		const containerH = cellH * ny + margin * (ny - 1);
		const top = container.y + (container.h - containerH) / 2;
		return {
			...container,
			y: top,
			h: containerH
		};
	}

	//pack horizontally
	cellW = cellH * aspect;
	const containerW = cellW * nx + margin * (nx - 1);
	const left = container.x + (container.w - containerW) / 2;
	return {
		...container,
		x: left,
		w: containerW
	};
}

/**
 * Large rect on top, row of rects on bottom
 * separation between large rect and bottom row not necessarily equal to margin, tweak topFraction as needed to adjust this
 */
export function singleTopRowBottom(container: Rect, topFraction: number, n: number, margin: number): Rect[] {
	const [[top0, top1], [bottom0, bottom1]] = divideSpace([topFraction, 1 - topFraction], margin, container.y, container.h);

	const top = {
		...container,
		y: top0,
		h: top1 - top0
	};

	const bottom = {
		...container,
		y: bottom0,
		h: bottom1 - bottom0
	};

	return [
		insertLetterbox(top, wideAspect),
		...gridLayout(bottom, n, 1, margin)
	];
}

/**
 * Large rect on left, column of rects on right
 * separation between large rect and column not necessarily equal to margin, tweak leftFraction as needed to adjust this
 */
export function singleLeftColRightLayout(container: Rect, leftFraction: number, n: number, margin: number): Rect[] {
	const [[left0, left1], [right0, right1]] = divideSpace([leftFraction, 1 - leftFraction], margin, container.x, container.w);

	const left = {
		y: container.y,
		h: container.h,
		x: left0,
		w: left1 - left0
	};

	const right = {
		y: container.y,
		h: container.h,
		x: right0,
		w: right1 - right0
	};

	return [
		insertLetterbox(left, wideAspect),
		...gridLayout(right, 1, n, margin)
	];
}

/**
 * Single large rect on left, and a column of n rects on right, aligned on top/bottom
 */
export function packedRightColumnLayout(container: Rect, n: number, margin: number): Rect[] {
	/**
	 * To Solve for left and right dimensions:
		container.width == left.w + right.w + margin
		left.h == n * right.h + (n - 1) * margin

		right.w == container.width - margin - left.w
		right.h == left.w / (n*aspect) - (n - 1)/n * margin
		right.w == aspect * right.h
		container.width - margin - left.w == aspect * (left.w / (n*aspect) - (n - 1)/n * margin)
		...
	 */
	const aspect = wideAspect;
	const leftW = n / (n + 1) * (container.w + margin * (aspect - aspect / n - 1));
	const leftH = leftW / aspect;
	const top = container.y + (container.h - leftH) / 2;

	const rightW = container.w - margin - leftW;
	const rightH = rightW / aspect;

	const left: Rect = {
		x: container.x,
		w: leftW,
		y: top,
		h: leftH
	};

	const right: Rect[] = Array.from({ length: n }).map((_, i) => ({
		x: container.x + leftW + margin,
		w: rightW,
		y: top + i * (rightH + margin),
		h: rightH
	}));

	return [left, ...right];
}

export function getPercentPositionStyle(rect: Rect): string {
	const pos = scaleRect(rect, 1 / 1920, 1 / 1080);
	return `left: ${formatPercent(pos.x)}; top: ${formatPercent(pos.y)}; height: ${formatPercent(pos.h)}; width: ${formatPercent(pos.w)};`;
}

export function formatPercent(n: number): string {
	return (n * 100 + '') + '%';
}
