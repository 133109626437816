import { Injectable } from '@angular/core';
import { isMobile, isIosSafari } from './UserAgentUtil';

export function storageAvailable(type: 'localStorage' | 'sessionStorage'): boolean {
	// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
	let storage;
	try {
		storage = window[type];
		const x = '__storage_test__';
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
	}
	catch(e) {
		return e instanceof DOMException && (
			// everything except Firefox
			e.code === 22 ||
			// Firefox
			e.code === 1014 ||
			// test name field too, because code might not be present
			// everything except Firefox
			e.name === 'QuotaExceededError' ||
			// Firefox
			e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
			// acknowledge QuotaExceededError only if there's something already stored
			(storage && storage.length !== 0);
	}
}

@Injectable({
	providedIn: 'root'
})
export class BrowserFeatureService {
	private document: HTMLDocument;
	public storageAvailable = storageAvailable;

	constructor() {
		this.document = window.document;
	}

	public dragAndDrop(): boolean {
		const div = this.document.createElement('div');

		return ('draggable' in div) || ('ondragstart' in div && 'ondrop' in div);
	}

	public fileDragAndDrop(): boolean {
		return window.FileReader && !isMobile() && this.dragAndDrop();
	}

	public volumeControl(): boolean {
		// iOS Safari blocks volume control through the standard APIs in favor of device controls
		return !isIosSafari();
	}
}
