<vb-loading-spinner [block]="true" [iconClass]="'glyphicons link'" [msgDisplayBelowSpinner]="true" [size]="'large'">

	<span *ngIf="destination">
		{{ 'Media_Recording_Connecting_To' | translate }}
		<br>
		<span class="theme-accent-link">{{ destination }}</span>
	</span>

	<span *ngIf="!destination">
		{{ 'Media_Recording_Connecting' | translate }}
	</span>
	<ng-content></ng-content>
</vb-loading-spinner>