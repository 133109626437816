import { Pipe, PipeTransform } from '@angular/core';

import { escape } from 'rev-shared/util';
import { escapeRegexSpecialChars } from 'rev-shared/util/RegexUtils';

/**
 * Wraps all occurences in the input value of the given match string with a span having the given highlight CSS class name.
 * HTML content is involved, so this won't work with just any output to the template. It is assumed to be used with something like
 * an [innerHTML] property binding.
 */
@Pipe({
	name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
	public transform(value: string, match: string, highlightClassName: string, tokenize: boolean = false): string {
		if(!value?.length) {
			return value;
		}

		const escapedValue = escape(value);

		if(!match?.length) {
			return escapedValue;
		}

		const regExpStr = tokenize ?
			match.trim().split(' ').map(q => escapeRegexSpecialChars(q)).join('|') :
			escapeRegexSpecialChars(match);

		return escapedValue.replace(
			new RegExp(regExpStr, 'gi'),
			`<span class="${highlightClassName}">$&</span>`);
	}
}
