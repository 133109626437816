import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ITranslationStrings } from './ITranslationStrings';
import { getLocale } from 'rev-shared/bootstrap/LocaleContext';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

const languageParam = 'lang';

@Injectable({
	providedIn: 'root'
})
export class TranslationsRequestService {

	constructor(
		private http: HttpClient
	) {}

	/**
	 * DEPRECATED. Use TranslateService.instant() method instead
	 * Request translation strings dictionary from an html template
	 * template file must contain a single <vb-translation-strings> element
	 * @param templateUrl
	 * @param translationsProperty must equal the vb-translation-strings[name] attribute
	 */
	public requestTranslations(templateUrl: string, translationsProperty: string): Promise<ITranslationStrings> {
		const language = getLocale().language;

		// This is effectively a template request, so the CDN and language parameter must be applied.
		return lastValueFrom(this.http.get(templateUrl, {
			observe: 'body',
			params: {
				[languageParam]: language
			},
			responseType: 'text'
		}))
			.then(response => this.parseResponse(response, translationsProperty));
	}

	/**
	 * Modern browsers support Element.children(), but not IE
	 */
	private getTranslationsChildren(translationsElement: Element): HTMLCollection {
		return translationsElement.children ||
			Array.from(translationsElement.childNodes)
				.filter(node => node.nodeType === Node.ELEMENT_NODE) as any;
	}

	/**
	 * Parses the given raw XML string and extracts the translations matching the desired translationsProprety.
	 * @param xmlString
	 * @param translationsProperty
	 */
	private parseResponse(xmlString: string, translationsProperty: string): ITranslationStrings {
		const xml: Document = new DOMParser().parseFromString(xmlString, 'text/xml');
		const translationsElement = xml.querySelector(`vb-translation-strings[name="${translationsProperty}"]`);

		return Array.from(this.getTranslationsChildren(translationsElement))
			.reduce((output, current) => {
				output[current.getAttribute('key')] = current.textContent.trim();

				return output;
			}, {});
	}
}
