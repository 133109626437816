import { isIe } from 'rev-shared/util/UserAgentUtil';
import 'jquery';

import 'moment';

import 'bootstrap/dist/css/bootstrap.css';

//glyphicons-pro
import 'glyphicons-pro/css/glyphicons.css';
import 'glyphicons-pro/css/filetypes.css';

//karla font
import 'karla/karla.css';

//misc
import 'underscore'; //should be imported only where used
import 'imports-loader?wrapper=window!signalr';
import 'classlist-polyfill';
import './polyfill/string.repeat';

/**
 * IE11 - tagify polyfills changes
 * clean this up when IE11 support ends
 */
import '@yaireo/tagify/dist/tagify.polyfills.min.js';

//need to set it only for IE11.
if (isIe()) {
	window.crypto = window.msCrypto;
}
/*** End of Tagify IE11 changes ***/
