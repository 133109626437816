import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleData, defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import moment from 'moment';

import { DateUtil } from 'rev-shared/date/DateUtil';
import { MomentLocaleLoader } from 'rev-shared/date/MomentLocaleLoader.Provider';
import { locale$ } from 'rev-shared/bootstrap/LocaleContext';
import { distinct } from 'rxjs/operators';
import { noop } from 'rev-shared/util';

@Injectable({
	providedIn: 'root'
})
export class NgxLocaleLoader {
	private result: Promise<void>;

	constructor(
		private MomentLocaleLoader: MomentLocaleLoader,
		private BsLocaleService: BsLocaleService,
		private TranslateService: TranslateService,
	){}

	public load(): Promise<void> {
		if(this.result) {
			return this.result;
		}
		return this.result = this.init();
	}

	private init(): Promise<any> {
		return this.MomentLocaleLoader.load()
			.then(() => new Promise(resolve => {
				locale$.pipe(distinct())
					.subscribe(({ momentLocale }) => {
						defineLocale(momentLocale, this.getLocaleConfig(momentLocale));
						this.BsLocaleService.use(momentLocale);
						resolve(undefined);
					});
			}));
	}

	private getLocaleConfig(localeId: string): LocaleData {
		return {
			abbr: localeId,
			months: moment.months(),
			monthsShort: moment.monthsShort(),
			weekdays: moment.weekdays(),
			weekdaysShort: moment.weekdaysShort(),
			weekdaysMin: moment.weekdaysMin(),
			longDateFormat: DateUtil.constructMomentLongDateFormat(localeId),
			invalidDate: this.TranslateService.instant('InvalidDate')
		};
	}
}
