import {
	Directive,
	forwardRef
} from '@angular/core';
import {
	NG_VALIDATORS,
	AbstractControl,
	ValidationErrors
} from '@angular/forms';

import { ValidationRules } from 'rev-shared/util/ValidationRules';

@Directive({
	selector: '[vbUiMacAddress]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => VbMacAddressDirective),
		multi: true
	}]
})
export class VbMacAddressDirective {

	public validate(control: AbstractControl): ValidationErrors {
		if (!control) {
			return null;
		}

		return ValidationRules.checkMacAddress(control.value) ? null : { vbMacAddress: true };
	}
}
