export interface IPasswordRules {
	minimumLength: number;
	minimumUppercaseLetterCount: number;
	minimumLowercaseLetterCount: number;
	minimumNumberCount: number;
	minimumSpecialCharacterCount: number;
}

export interface IPasswordValidationCharacterCounts {
	digit: number;
	length: number;
	lower: number;
	special: number;
	upper: number;
}

export interface IPasswordValidationResult {
	errorMinimumLength?: boolean;
	errorMinimumNumberCount?: boolean;
	errorMinimumLowercaseLetterCount?: boolean;
	errorMinimumSpecialCharacterCount?: boolean;
	errorMinimumUppercaseLetterCount?: boolean;
	valid: boolean;
}

export class PasswordRulesChecker {
	constructor(
		public rules: IPasswordRules
	) {}

	public validatePassword(password: string): IPasswordValidationResult {
		const counts: IPasswordValidationCharacterCounts = this.getCharacterCounts(password || '');
		const result: IPasswordValidationResult = { valid: true };

		if(counts.length < this.rules.minimumLength) {
			result.valid = false;
			result.errorMinimumLength = true;
		}

		if (counts.upper < this.rules.minimumUppercaseLetterCount) {
			result.valid = false;
			result.errorMinimumUppercaseLetterCount = true;
		}

		if (counts.lower < this.rules.minimumLowercaseLetterCount) {
			result.valid = false;
			result.errorMinimumLowercaseLetterCount = true;
		}

		if (counts.digit < this.rules.minimumNumberCount) {
			result.valid = false;
			result.errorMinimumNumberCount = true;
		}

		if (counts.special < this.rules.minimumSpecialCharacterCount) {
			result.valid = false;
			result.errorMinimumSpecialCharacterCount = true;
		}

		return result;
	}

	private getCharacterCounts(password: string): IPasswordValidationCharacterCounts {
		const counts: IPasswordValidationCharacterCounts = {
			length: password.length,
			lower: 0,
			digit: 0,
			special: 0,
			upper: 0
		};

		for (const currentChar of password) {
			if (currentChar.match(/[A-Z]/)) {
				counts.upper++;
			} else if (currentChar.match(/[a-z]/)) {
				counts.lower++;
			} else if (currentChar.match(/[0-9]/)) {
				counts.digit++;
			} else if(currentChar.match(/[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]/)) {
				counts.special++;
			}
		}

		return counts;
	}
}
