export function offsetFromDocument(element: HTMLElement): { top: number; left: number, width: number, height: number } {
	if (!element.getClientRects().length) {
		return { top: 0, left: 0, width: 0, height: 0 };
	}

	const rect = element.getBoundingClientRect();
	const win = element.ownerDocument.defaultView;
	return {
		top: rect.top + win.pageYOffset,
		left: rect.left + win.pageXOffset,
		width: rect.width,
		height: rect.height
	};
}

export function scrollToElementVertically(element: HTMLElement, offset?: number): void {
	window.scrollTo({
		top: offsetFromDocument(element).top - offset ?? 0,
		left: 0,
		behavior: 'smooth'
	});
}
