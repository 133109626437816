import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { INamedConstant } from 'rev-shared/constants/INamedConstant';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

@Injectable()
export class LanguageCodes {

	public languageCodes: INamedConstant[];
	public readonly defaultLanguageCode = 'en';

	constructor(
		private http: HttpClient
	) {
	}

	public load(): Promise<void> {
		return this.languageCodes ?
			Promise.resolve() :
			lastValueFrom(this.http.get('/languages'))
				.then((result: any) => {
					this.languageCodes = result.languages;
				});
	}
}
