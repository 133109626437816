import { Component, ContentChild, ElementRef, HostBinding, Input } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

import styles from './VbNgSelectWrap.Component.module.less';

@Component({
	selector: 'vb-ng-select-wrap',
	host: {
		'[class]': 'styles.root'
	},
	template: '<ng-content></ng-content>'
})
export class VbNgSelectWrap {
	@Input() public noTheme: boolean;
	public readonly styles = styles;

	@ContentChild(NgSelectComponent, { read: ElementRef }) private ngSelectEleRef: ElementRef<HTMLElement>;

	public ngAfterContentInit(): void {
		this.ngSelectEleRef.nativeElement.classList.add('custom');
	}
}
