import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

import { ValidationRules } from 'rev-shared/util/ValidationRules';

@Directive({
	selector: '[vbLong]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => VbLongDirective),
		multi: true
	}]
})
export class VbLongDirective implements Validator {
	public validate(control: AbstractControl): ValidationErrors {
		return ValidationRules.checkLongText(control.value) ? null : { vbLong: true };
	}
}
