import { Component, Input } from '@angular/core';
import { InsightComponent } from '../ngx/Insight.Component';
import { IInsightRecord } from '../ngx/Insight.Contract';

import styles from './InsightTemplates.module.less';
@Component({
	template: ''
})
export class InsightRowComponent {
	@Input() public item: IInsightRecord;
	@Input() public insight: InsightComponent;

	public readonly styles = styles;

}

@Component({
	selector: 'access-entity-option-row',
	host: {
		'[class]': 'styles.optionRow'
	},
	templateUrl: './AccessEntityOptionRow.Component.html'
})
export class AccessEntityOptionRowComponent extends InsightRowComponent {
}

@Component({
	selector: 'access-entity-assigned-row',
	host: {
		'[class]': 'styles.optionRow'
	},
	templateUrl: './AccessEntityAssignedRow.Component.html'
})
export class AccessEntityAssignedRowComponent extends InsightRowComponent {

}

@Component({
	selector: 'category-admin-assigned-row',
	host: {
		'[class]': 'styles.optionRow'
	},
	templateUrl: './CategoryAdminAssignedRow.Component.html'
})
export class CategoryAdminAssignedRowComponent extends InsightRowComponent {
}

@Component({
	selector: 'team-admin-assigned-row',
	host: {
		'[class]': 'styles.optionRow'
	},
	templateUrl: './TeamAdminAssignedRow.Component.html'
})
export class TeamAdminAssignedRowComponent extends InsightRowComponent {

	public get itemRole() {
		return this.item.roleTypes?.[0];
	}

	public set itemRole(role: string) {
		if(!this.item.roleTypes) {
			this.item.roleTypes = [];
		}

		this.item.roleTypes[0] = role;
	}
}

@Component({
	selector: 'approver-assigned-row',
	host: {
		'[class]': 'styles.optionRow'
	},
	templateUrl: './ApproverAssignedRow.Component.html'
})
export class ApproverAssignedRowComponent extends InsightRowComponent {
}


@Component({
	selector: 'event-moderator-assigned-row',
	host: {
		'[class]': 'styles.optionRow'
	},
	templateUrl: './EventModeratorAssignedRow.Component.html'
})
export class EventModeratorAssignedRowComponent extends InsightRowComponent {
}
