import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { VbUiTooltipComponent } from './VbUiTooltip.Component';

const components = [
	VbUiTooltipComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		TooltipModule,
		TranslateModule
	]
})
export class VbUiTooltipModule {}
