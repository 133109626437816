import {
	AfterViewInit,
	Component,
	Input,
	OnInit,
	ViewChild
} from '@angular/core';
import { NgModel, Validators } from '@angular/forms';

import { StateService, UrlService } from '@uirouter/angular';

import { IPasswordValidationResult } from 'rev-shared/security/PasswordRulesChecker';
import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { IPasswordRules, PasswordRulesChecker } from 'rev-shared/security/PasswordRulesChecker';
import { PasswordValidationService } from 'rev-shared/security/PasswordValidation.Service';
import { UserAuthenticationService } from 'rev-shared/security/UserAuthentication.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';

import authLayoutStyles from 'rev-shared/ui/authLayout/VbAuthLayout.Module.less';

interface IUserConfirmation {
	confirmPassword?: string;
	password?: string;
	securityAnswer?: string;
	securityQuestion?: string;
	token: string;
	userId: string;
	username: string;
}

@Component({
	selector: 'user-confirmation',
	templateUrl: './UserConfirmation.Component.html'
})
export class UserConfirmationComponent implements OnInit, AfterViewInit {
	@Input() public token: string;

	@ViewChild('userConfirmedDialog', { static: true }) public userConfirmedDialog: VbConfirmationDialogComponent;
	@ViewChild('passwordControl', { static: true }) public ctrlPassword: NgModel;
	@ViewChild('confirmPasswordControl', { static: true }) public ctrlConfirmPassword: NgModel;

	public readonly authLayoutStyles = authLayoutStyles;
	private readonly savingStatus: string = 'saving';
	private readonly errorStatus: string = 'error';
	private readonly activeStatus: string = 'active';
	private readonly loadingStatus: string = 'loading';
	private readonly invalidTokenStatus: string = 'invalidToken';
	private readonly userConfirmedStatus: string = 'userConfirmed';
	private readonly invalidLicenseStatus: string = 'invalidLicense';

	private accountId: string;
	private loadedLanguage: string;
	public passwordRules: IPasswordRules;
	private passwordRulesChecker: PasswordRulesChecker;
	public passwordRulesCheckerResult: IPasswordValidationResult;
	public status: string;
	public userConfirmation: IUserConfirmation = {} as any;
	private userId: string;

	constructor(
		private $state: StateService,
		private LoginRedirectService: LoginRedirectService,
		private PasswordValidationService: PasswordValidationService,
		private UserAuthenticationService: UserAuthenticationService,
		private UserContext: UserContextService,
		private urlService: UrlService
	) {}

	public ngOnInit(): void {
		this.loadedLanguage = this.UserContext.getCurrentLanguage();
		this.status = this.loadingStatus;
		this.getUserConfirmation();
	}

	public ngAfterViewInit(): void {
		this.ctrlConfirmPassword.control.setValidators(Validators.compose([
			this.ctrlConfirmPassword.control.validator,
			() => this.validateCtrlConfirmPassword()
		]));

		this.ctrlPassword.control.setValidators(Validators.compose([
			this.ctrlPassword.control.validator,
			() => this.validateCtrlPassword()
		]));
	}

	public submit(): void {
		this.status = this.savingStatus;

		this.UserAuthenticationService.confirmUser(this.userConfirmation)
			.then((entry: any) => {
				if (entry.eventType === 'AcquireUserLicenseFailed') {
					this.status = this.invalidLicenseStatus;
				} else {
					this.status = this.userConfirmedStatus;

					this.userConfirmedDialog.open()
						.result
						.finally(() => this.authenticateUser());
				}
			}, () => {
				this.status = this.errorStatus;
			});
	}

	private authenticateUser(): void {
		this.UserContext.authenticateUser(this.userConfirmation.username, this.userConfirmation.password)
			.then(() => {
				const fwdUrl: string = '/';
				const newLanguage: string = this.UserContext.getCurrentLanguage();

				if (this.loadedLanguage !== newLanguage) {

					return window.setTimeout(() => {
						window.location.href = '#' + fwdUrl;
						window.location.reload();
					}, 100, false);
				}

				this.urlService.url(fwdUrl);
			}, (result: any) => {
				if (result.userAgreementRequired) {
					this.$state.go('user-agreement', {
						userId: result.userId
					});
				} else {
					this.LoginRedirectService.redirectToLogin();
				}
			});
	}

	private getPasswordRulesChecker(accountId: string): Promise<void> {
		return this.PasswordValidationService.getPasswordRulesChecker(accountId)
			.then((result: PasswordRulesChecker) => {
				this.passwordRulesChecker = result;
				this.passwordRules = this.passwordRulesChecker.rules;
			});
	}

	// internal function to help us check the validity of the token
	private getUserConfirmation(): void {
		this.UserAuthenticationService.getUserConfirmation(this.token)
			.then((data: any) => {
				Object.assign(this, {
					userConfirmation: {
						userId: data.userId,
						username: data.username,
						token: this.token
					},
					userId: data.userId,
					accountId: data.accountId
				});
			})
			.then(() => this.getPasswordRulesChecker(this.accountId))
			.then(() => {
				this.status = this.activeStatus;
			}, (err: any) => {
				if(err && err.status === 404) {
					this.status = this.invalidTokenStatus;
				} else {
					this.status = this.errorStatus;
				}
			});
	}

	private validateCtrlConfirmPassword(): { [key: string]: boolean } {
		if (this.ctrlPassword.value !== this.ctrlConfirmPassword.value) {
			return { noMatch: true };
		}

		return null;
	}

	private validateCtrlPassword(): { [key: string]: boolean } {
		// trigger revalidation of the confirmation to check for a match
		this.ctrlConfirmPassword.control.updateValueAndValidity();

		if (!this.passwordRulesChecker) {
			return null;
		}

		const result: IPasswordValidationResult = this.passwordRulesChecker.validatePassword(this.ctrlPassword.value);

		this.passwordRulesCheckerResult = result;

		if (!result.valid) {
			return { rules: true };
		}

		return null;
	}
}
