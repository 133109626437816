
import { IPlaybackCfg } from 'rev-shared/videoPlayer/VideoPlayerAdapter.Service';

export function getPlaybackCfg(stream: MediaStream): IPlaybackCfg {
	return {
		selected: true,
		srcObject: stream,
		videoFormat: 'h264'
	};
}

export function getPlaybacks(stream: MediaStream): IPlaybackCfg[] {
	return stream ?
		[getPlaybackCfg(stream)] :
		null;
}

export function stopStream(stream: MediaStream): void {
	stream?.getTracks().forEach(t => {
		t.stop?.();
		t.enabled = false;
	});
}

export function hasVideo(stream: MediaStream): boolean {
	return stream
		? stream.getVideoTracks().length > 0
		: false;
}

export function hasAudio(stream: MediaStream): boolean {
	return stream
		? stream.getAudioTracks().length > 0
		: false;
}

export function getPlaceholderVideo(): [MediaStream, () => void] {
	const canvas = document.createElement('canvas') as HTMLCanvasElement;
	canvas.width = 1280;
	canvas.height = 720;
	const ctx = canvas.getContext('2d');
	const stream = (canvas as any).captureStream?.(30);
	const img = new Image();
	const render = () => ctx.drawImage(img, 0, 0);
	let interval: number;
	let stopped: boolean;

	img.addEventListener('load', () => {
		if(stopped) {
			return;
		}

		render();
		interval = window.setInterval(render, 1000/30); // Generate 30 images/frames per sec
	});

	img.src = '/img/placeholder.png';

	return [stream, () => {
		stopped = true;
		window.clearInterval(interval);
	}];
}

export function getScaledVideoStream(mediaStream: MediaStream): [MediaStream, () => void] {
	const canvasId = 'screenCaptureCanvas';
	const oldCanvas = document.getElementById(canvasId);
	if (oldCanvas) {
		document.removeChild(oldCanvas);
	}

	const canvas = document.createElement('canvas') as HTMLCanvasElement;
	canvas.id = canvasId;
	canvas.width = 1280;
	canvas.height = 720;
	const ctx = canvas.getContext('2d');

	const videoId = 'screenCaptureVideoId';
	var video = document.createElement('video');
	const oldVideoElem = document.getElementById(videoId);
	if (oldVideoElem) {
		document.removeChild(oldVideoElem);
	}

	video.id = videoId;
	video.controls = false;
	video.muted = true;
	video.srcObject = mediaStream;

	const videoSettings = mediaStream.getVideoTracks()[0].getSettings();
	let x = (canvas.width - videoSettings.width) / 2;
	let y = (canvas.height - videoSettings.height) / 2;

	console.log(videoSettings, x, y);

	let interval: number;
	let stopped: boolean;
	const frameTime = 1000/30;

	video.addEventListener('play', () => {
		if(stopped) {
			return;
		}

		render(ctx, video, x, y);
		interval = window.setInterval(() => render(ctx, video, x, y), frameTime);
	});

	video.addEventListener('resize', e => {
		const { videoWidth, videoHeight } = video;
		x = (canvas.width - videoWidth) / 2;
		y = (canvas.height - videoHeight) / 2;

		ctx.clearRect(0, 0, canvas.width, canvas.height);
	}, false);

	video.play();

	const stream = (canvas as any).captureStream?.(30);

	const captureTracks = [stream.getVideoTracks()[0]];
	const audioTracks = mediaStream.getAudioTracks();
	if (audioTracks.length > 0) {
		captureTracks.push(audioTracks[0]);
	}

	const scaledVideoStream = new MediaStream(captureTracks);

	console.log('Stream: ', scaledVideoStream);

	return [scaledVideoStream, () => {
		stopped = true;
		window.clearInterval(interval);
		stopStream(mediaStream);
		stopStream(stream);
	}];
}

function render(ctx: CanvasRenderingContext2D, video: HTMLVideoElement, x: number, y: number) {
	ctx.drawImage(video, x, y);
}
