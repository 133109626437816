import {
	Directive,
	EventEmitter,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges
} from '@angular/core';

import { isLegacyEdge, isIe } from 'rev-shared/util/UserAgentUtil';

const DELAY_TIME_MS = 1000;
const DEFAULT_TOTAL_DELAY_TIME_MS = 100000;

@Directive({
	selector: '[vbImageRetry]',
	host: {
		'[src]':'src'
	}
})
export class VbImageRetryDirective implements OnChanges, OnDestroy {
	@Input() public src: string;
	@Input() public totalDelayTimeMs: number = DEFAULT_TOTAL_DELAY_TIME_MS;

	@Output() public onRetriesExhausted: EventEmitter<void> = new EventEmitter();

	private delay: number;
	private imgElement: HTMLImageElement;
	private isIgnoreNextError: boolean;
	private timeoutRef: number;
	private totalDelay: number;

	constructor(
		element: ElementRef
	) {
		this.imgElement = element.nativeElement;
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.src && !changes.src.isFirstChange) {
			this.reset();
		}
	}

	public ngOnDestroy(): void {
		this.reset();
	}

	@HostListener('error')
	public onImageError(): void {
		if (this.isIgnoreNextError) {
			this.isIgnoreNextError = false;
			return;
		}

		if (!this.delay) {
			this.delay = 0;
			this.totalDelay = 0;
		}

		this.delay += DELAY_TIME_MS;
		this.totalDelay += this.delay;

		if (this.totalDelay <= this.totalDelayTimeMs) {
			this.timeoutRef = window.setTimeout(() => this.reapplySrc(), this.delay);
		} else {
			this.retriesExhausted();
		}
	}

	@HostListener('load')
	public onImageLoad(): void {
		this.reset();
	}

	public resetAndTryAgain(): void {
		this.reset();
		this.reapplySrc();
	}

	private reapplySrc(): void {
		const src: string = this.imgElement.src.split('#')[0];
		const hash: string = isLegacyEdge() ? // Edge has weird internal caching behavior and won't even attempt a request, so need to bust it
			'#' + Date.now() :
			'';

		this.isIgnoreNextError = isIe(); // IE11 emits an error after setting src to ''

		this.imgElement.src = ''; // changing the value gets it playing nice with IE/Edge
		this.imgElement.src = src + hash;
	}

	private reset(): void {
		if (this.timeoutRef) {
			window.clearTimeout(this.timeoutRef);
			this.timeoutRef = null;
		}

		this.delay =
			this.totalDelay =
			0;

		this.isIgnoreNextError = false;
	}

	private retriesExhausted(): void {
		this.onRetriesExhausted?.emit();
	}
}
