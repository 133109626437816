import {
	Directive,
	Input,
	forwardRef
} from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';

import { AccessControl } from 'rev-shared/media/MediaConstants';

enum MediaType {
	Webcast = 'Webcast',
	Video = 'Video'
}

@Directive({
	selector: '[prohibitPublicAccessValidator]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => ProhibitPublicAccessValidatorDirective),
		multi: true
	}]

})
export class ProhibitPublicAccessValidatorDirective {
	@Input('prohibitPublicAccessValidator') public mediaType: MediaType;
	@Input() public mediaFeatures: IMediaFeatures;

	public validate(control: AbstractControl): ValidationErrors {
		if (!control?.value) {
			return null;
		}

		return this.validatePublicAccess(control.value) ?
			{ isProhibitPublicAccess: true } : null;
	}

	private validatePublicAccess(modelValue): boolean {
		const value: AccessControl = modelValue && modelValue.accessControl ?
			modelValue.accessControl :
			modelValue;
		const isPublic: boolean = value === AccessControl.Public;
		const mediaTypeAccess: boolean = this.mediaType === MediaType.Webcast
			? this.mediaFeatures.enablePublicWebcastAccess
			: this.mediaFeatures.enablePublicVideoAccess;
		const isProhibitPublicAccess: boolean = isPublic && !mediaTypeAccess;

		return isProhibitPublicAccess;
	}
}
