import { PollStatus } from './PollStatus';
import { PollsModelService } from './PollsModel.Service';
import { IPollAnswer } from './Polls.Contract';
import { WebcastModel } from '../webcast/model/WebcastModel';

export class Poll {
	public active: boolean;
	public id: string;
	public status: PollStatus;
	public question: string;
	public multipleChoice: boolean;
	public answers: IPollAnswer[];
	public isNew: boolean;
	public totalResponses: number;
	public responseSubmitted: boolean;
	public selectedAnswer: string;
	public canBeEdited: boolean;
	public canBeDeleted: boolean;
	public readOnly?: boolean;

	constructor(
		poll: any,
		private webcast: WebcastModel,
		private PollsModelService: PollsModelService) {
		Object.assign(this, poll);
	}

	public copy(data): Poll {
		return new Poll({
			...this,
			...data
		},
		this.webcast,
		this.PollsModelService);
	}

	public open(): Promise<void> {
		return this.PollsModelService.openPoll(this.id);
	}

	public isOpen(): boolean {
		return this.status === PollStatus.Open;
	}

	public close(): Promise<void> {
		return this.PollsModelService.closePoll(this.id);
	}

	public isClosed(): boolean {
		return this.status === PollStatus.Closed;
	}

	public publish(): Promise<void> {
		return this.PollsModelService.publishPoll(this.id);
	}

	public isPublished(): boolean {
		return this.status === PollStatus.Published;
	}

	public unpublish(): Promise<void> {
		return this.PollsModelService.unpublishPoll(this.id);
	}

	public answer(answers: boolean[]): Promise<void> {
		return this.PollsModelService.answerPoll(this.webcast.id, this.webcast.currentRun.runNumber, this.id, answers);
	}
}
