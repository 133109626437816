import { BehaviorSubject } from 'rxjs';
import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';
import { registerLocaleData } from '@angular/common';

const ANGULAR_DEFAULT_LOCALE: string = 'en-US';

interface ILocale {
	language: string;
	bcp47Locale: string;
	ckEditorLocale: string;
	fullcalendarLocale: string;
	momentLocale: string;
}

const localeSubject$ = new BehaviorSubject<ILocale>(toLocale(BootstrapContext));

export const locale$ = localeSubject$.asObservable();

export function getLocale(): ILocale {
	return localeSubject$.value;
}

function toLocale(localeData: Partial<ILocale>): ILocale {
	return {
		language: localeData.language,
		bcp47Locale: localeData.bcp47Locale,
		ckEditorLocale: localeData.ckEditorLocale,
		fullcalendarLocale: localeData.fullcalendarLocale,
		momentLocale: localeData.momentLocale
	};
}

export function updateLocale(localeData: any): void {
	localeSubject$.next(toLocale(localeData));

	importLocale(localeData.bcp47Locale);
}

export function initLocale(): void {
	importLocale(BootstrapContext.bcp47Locale);
}

function importLocale(bcp47Locale: string): Promise<void> {
	if (bcp47Locale !== ANGULAR_DEFAULT_LOCALE) {
		return import(
			/* webpackMode: 'lazy' */
			/* webpackInclude: /(de|en|en-GB|es|es-AR|es-MX|fr|it|ja|ko|nl|pt|ru|sv|tr|zh-Hans|zh|id)\.mjs$/ */
			/* webpackExclude: /extra/ */
			`/node_modules/@angular/common/locales/${bcp47Locale}.mjs`
		)
			.then(({ default: localeData }) => registerLocaleData(localeData, bcp47Locale));
	}

	return Promise.resolve();
}
