import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


export class ApiVirtualScrollDataSource<T> extends DataSource<T> {
	public readonly data$ = new BehaviorSubject<T[]>([]);
	private sub: Subscription;
	public isLoading: boolean;
	public isPaused: boolean;
	public viewEnd: number;
	private currentLoad: Promise<T[]>;

	constructor(
		private readonly loadNext: () => Promise<T[]>,
		private readonly isPagedQueries: boolean = false
	) {
		super();
	}

	public setPaused(paused: boolean) {
		this.isPaused = paused;
	}

	public connect(collectionViewer: CollectionViewer): Observable<T[]> {
		this.sub = collectionViewer.viewChange.pipe(
			map(({ end }) => end),
			startWith(0)
		)
			.subscribe(end => {
				this.viewEnd = end;
				if(!this.shouldLoad(end)) {
					return;
				}
				this.loadPages();
			});

		return this.data$.asObservable();
	}

	private shouldLoad(viewEnd: number): boolean {
		return viewEnd + 3 > this.data$.value.length &&
			!this.isPaused;
	}

	public loadPages(): void {
		if(this.isLoading) {
			return;
		}

		this.isLoading = true;
		this.loadPagesUntilViewFilled()
			.then(() => {
				this.isLoading = false;
			})
			.catch(err => console.error('Virtual Scroll Data Source Error: ', err));
	}

	private loadPagesUntilViewFilled(){
		const load = this.currentLoad = this.loadNext()
			.then(data => {
				if(this.currentLoad !== load){
					return;
				}

				this.data$.next(
					this.isPagedQueries ? this.data$.value.concat(data) : data);

				return this.shouldLoad(this.viewEnd) && this.loadPagesUntilViewFilled();
			});
		return load;
	}

	public update(items: T[]): void {
		this.cancelLoad();
		this.data$.next(items);
	}

	public disconnect(): void {
		this.data$.complete();
		this.cancelLoad();
		this.sub?.unsubscribe();
	}

	private cancelLoad(): void {
		this.isLoading = false;
		this.currentLoad = null;
	}
}

