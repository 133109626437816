import { Directive, AfterViewInit, Input, HostListener, Inject } from '@angular/core';
import { ParentUIViewInject, StateService, UIView } from '@uirouter/angular';

/**
 * Allows a ui-sref directive to act as a toggle button.
 * It keeps a copy of user in which state. if the user is on uiSref state and then click on element
 * then it redirects user to vbUiSrefToggle state.
 *
 * Ex:  <a uiSref="sideview" vbUiSrefToggle >...</a>
 */
@Directive({
	selector: '[vbUiSrefToggle]',
})
export class VbUiSrefToggleDirective implements AfterViewInit {
	@Input() public uiOptions: any;
	@Input() public uiSref: string;

	private toggleState;
	private mainState;
	private srefOpts;

	constructor(
		private $state: StateService,
		@Inject(UIView.PARENT_INJECT) private parent: ParentUIViewInject
	) {}

	public ngAfterViewInit(): void {
		this.toggleState = this.parent.context.name;
		this.mainState = this.parseSref(this.uiSref);
		this.srefOpts = this.uiOptions;
	}

	@HostListener('click', ['$event'])
	public onClick(event: MouseEvent): void {
		if (this.$state.includes(this.mainState, null, { relative: this.toggleState })) {
			event.preventDefault();
			this.$state.go(this.toggleState, null, this.srefOpts);
		}
	}

	private parseSref(sref: string): string {
		const match = sref.match(/^([^(]+?)\s*(\((.*)\))?$/);

		return match[1];
	}
}

