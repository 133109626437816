import { TransitionService } from '@uirouter/angular';
import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';

export function StateTransitionScrollToTop($transitions: TransitionService): void {
	$transitions.onSuccess({}, transition => {
		const toStateDeclaration: IVbNg2StateDeclaration = transition.to() as IVbNg2StateDeclaration;

		if (toStateDeclaration.scrollToTop) {
			window.scrollTo(0, 0);
		}
	});
}
