import { Directive, DoCheck, OnDestroy, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { throttle } from 'underscore';

/**
 * Writes the element's height to the scope
 * 	Example:
 * 		<div (vbOffsetHeightModel)="toolbarHeight = $event.offsetHeight"
 */

@Directive({
	selector: '[vbOffsetHeightModel]'
})
export class OffsetHeightModelAngularDirective implements OnInit, DoCheck, OnDestroy {
	@Output() public vbOffsetHeightModel: EventEmitter<any> = new EventEmitter();

	private height: number;

	constructor(
		private el: ElementRef
	) {}

	public ngOnInit(): void {
		window.addEventListener('resize', this.updateHeight);
		this.updateHeight();
	}

	public ngOnDestroy(): void {
		window.removeEventListener('resize', this.updateHeight);
	}

	public ngDoCheck(): void {
		this.updateHeight();
	}

	private updateHeight = throttle(() => setTimeout(() => {
		const offsetHeight: number = this.el.nativeElement.offsetHeight;
		if (offsetHeight !== this.height) {
			this.height = offsetHeight;
			this.vbOffsetHeightModel.emit({ offsetHeight });
		}
	}), 100, { trailing: false });
}
