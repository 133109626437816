import { NgModule } from '@angular/core';

import { HighlightPipe } from './Highlight.Pipe';

const pipes = [
	HighlightPipe
];

@NgModule({
	declarations: pipes,
	exports: pipes
})
export class HighlightModule {}
