import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Pipe({
	name: 'vbTitleCase'
})
export class VbTitleCasePipe extends TitleCasePipe implements PipeTransform {
	public transform(value: string, doTransform?: boolean): any {
		if (!doTransform || !value) {
			return value;
		}
		return super.transform(value);
	}

}
