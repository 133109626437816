import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { VbNgSelectWrap } from './VbNgSelectWrap.Component';

const declarations = [
	VbNgSelectWrap
];

@NgModule({
	declarations,
	exports: declarations,
	imports: [
		CommonModule,
		FormsModule,
		NgSelectModule
	]
})
export class VbNgSelectWrapModule {}
