import { Directive, Input, OnChanges } from '@angular/core';
import { flatten } from 'underscore';
import { noop } from 'rev-shared/util';

const delayMs = 1000;

/**
 * Sets a timer using $timeout to run at specified date(s). This will trigger a digest cycle to update the view.
 * Example: <div vb-schedule-digest="date"
 *  <div vb-schedule-digest="[date1, date2, ...]"
 */
@Directive({
	selector: '[vbScheduleChangeDetection]'
})
export class VbScheduleChangeDetectionDirective implements OnChanges {
	@Input('vbScheduleChangeDetection') public digestTime: Date|Date[];
	private timers: number[];

	public ngOnChanges() {
		if (this.timers) {
			this.timers.forEach(t => t && window.clearTimeout(t));
		}

		this.timers = [...new Set<Date>(flatten([this.digestTime]) as Date[])]
			.filter(Boolean)
			.map(when => {
				const timeMs = when.getTime() - Date.now() + delayMs;

				if(timeMs > 0) {
					return window.setTimeout(noop, timeMs);
				}
			})
			.filter(Boolean);
	}
}
