<vb-title [title]="'Title_ForgotPassword' | translate"></vb-title>

<vb-auth-layout>

	<div [hidden]="!status.processing">
		<vb-loading-spinner [msg]="'ForgotPassword_ProcessingMessage' | translate" [size]="'large'"></vb-loading-spinner>
	</div>

	<div [hidden]="status.processing">

		<div [ngClass]="[authLayoutStyles.centered, authLayoutStyles.logoWrapper]">
			<branding-logo></branding-logo>
		</div>

		<div [ngClass]="authLayoutStyles.heading">
			<h2>{{ 'ForgotPassword_Heading' | translate }}</h2>
		</div>

		<form #forgotPassword="ngForm" [ngClass]="authLayoutStyles.authForm" name="forgotPassword" (submit)="submit()">
			<div [hidden]="!status.error" class="system-msg">
				<div [ngSwitch]="lastError">
					<p *ngSwitchCase="'ForgotPassword_CommandDenied'">{{ 'ForgotPassword_CommandDenied' | translate }}</p>
					<p *ngSwitchDefault>{{ 'UnhandledError' | translate }}</p>
				</div>
				<a class="btn btn-primary" uiSref="login">{{ 'ReturnToLogin' | translate }}</a>
			</div>

			<div [hidden]="!status.success" class="system-msg">
				{{ 'ForgotPassword_EmailSent' | translate }}
			</div>

			<div class="margin-top-20">
				<label for="username">{{ 'Username' | translate }}</label>
				<input type="text" id="username" name="username" class="form-control theme-header-bg theme-header-txt" [attr.aria-label]="'Username' | translate" [placeholder]="'Username_Placeholder' | translate" [(ngModel)]="username" vbAutoFocus required>
			</div>

			<div class="margin-top-20">
				<div [hidden]="!status.active">
					<button type="submit" class="btn btn-primary theme-accent theme-accent-txt" [ngClass]="authLayoutStyles.authBtn" [disabled]="forgotPassword.invalid">
						{{ 'ForgotPassword_Submit' | translate }}
					</button>
					<a class="btn theme-header-bg theme-header-txt margin-top-10" [ngClass]="[authLayoutStyles.authBtn, authLayoutStyles.authCancel]" uiSref="login">{{ 'Cancel' | translate }}</a>
				</div>

				<a [hidden]="!status.success" class="btn btn-primary theme-accent theme-accent-txt" [ngClass]="authLayoutStyles.authBtn" uiSref="login">
					{{ 'SignIn' | translate }}
				</a>
			</div>
		</form>

	</div>

</vb-auth-layout>
