import { Directive } from '@angular/core';

import styles from './vb-ui-btn-fab.module.less';

@Directive({
	selector: '[vbUiBtnFab]',
	host: {
		'[class]': 'hostClass'
	}
})
export class VbUiBtnFabDirective {
	public readonly hostClass: string = `${styles.vbBtnFab} theme-accent theme-accent-border`;
}
