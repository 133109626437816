import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';
import { FormDirectivesAngularModule } from 'rev-shared/util/directives/form/FormDirectivesAngular.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';

import { VbUiInlineEditBooleanComponent } from './VbUiInlineEditBoolean.Component';
import { VbUiInlineTextEditComponent } from './VbUiInlineTextEdit.Component';
import { VbUiTypeaheadAngularComponent } from './VbUiTypeaheadAngular.Component';

const components = [
	VbUiInlineEditBooleanComponent,
	VbUiInlineTextEditComponent,
	VbUiTypeaheadAngularComponent
];

@NgModule({
	imports: [
		ButtonsAngularModule,
		CommonModule,
		FormsModule,
		FormDirectivesAngularModule,
		TextInputAngularModule,
		TranslateModule,
		TypeaheadModule,
		CssRulesAngularModule,
		SpinnerAngularModule,
		TextInputAngularModule
	],
	declarations: components,
	exports: components,
})
export class InlineEditAngularModule { }
