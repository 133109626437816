import {
	Directive,
	ElementRef,
	HostListener,
	Inject,
	Input,
	OnChanges,
	OnInit,
	Renderer2,
	SimpleChanges,
	forwardRef
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator
} from '@angular/forms';
import moment from 'moment';

import { isNumber, isDefined } from 'rev-shared/util';
import { DateParser, DateParserFactory } from './DateParser.Contract';
import { DATE_PARSER_FACTORY_SERVICE_TOKEN } from './DateParser.Provider';

const defaultFormat = 'LT';

/**
 * Input for a time string
 * Attrs:
 *  vb-time-input: a moment time format string. https://momentjs.com/docs/#/displaying/format/
 *  base-date: a date object used to calculate the time of day.  For example: if base-date is 3/9/2014 (DST begins at 2:00am on 3/9), and the input value is 10:00 am. The output value will be 9 hours.
 * Output value:
 *  the parsed time of day in ms
 */
@Directive({
	selector: '[ngModel][vbTimeInput]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VbTimeInputDirective),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => VbTimeInputDirective),
			multi: true,
		}
	],
})
export class VbTimeInputDirective implements OnInit, OnChanges, Validator, ControlValueAccessor {
	@Input() public vbTimeInput: string;
	@Input('baseDate') public baseDateInput: Date;

	private baseDate: Date;
	private format: string;
	private onChange: (value: number | Date) => void;
	private onTouched: () => void;
	private parseTime: DateParser;
	private viewValue: string;

	constructor(
		@Inject(DATE_PARSER_FACTORY_SERVICE_TOKEN)
		private DateParser: DateParserFactory,
		private renderer: Renderer2,
		private element: ElementRef
	) {}

	public ngOnInit(): void {
		this.format = this.vbTimeInput || defaultFormat;
		this.parseTime = this.DateParser(this.format, true);

		this.resetBaseDate();
	}

	@HostListener('input', ['$event.target.value'])
	public onViewValueChange(value: string): void {
		this.viewValue = value;
		const result = this.parseTime(value, this.baseDate);
		this.onTouched();
		this.onChange(result);
	}


	public ngOnChanges(changes: SimpleChanges): void {
		if (isDefined(changes.baseDateInput)) {
			this.resetBaseDate();

			if (this.viewValue) {
				this.onViewValueChange(this.viewValue);
			}
		}
	}

	public writeValue(value: number): void {
		let timeVal: string | number;
		timeVal = value || '';

		if (value) {
			timeVal = moment(this.baseDate)
				.add(value, 'milliseconds')
				.format(this.format);
		}

		this.renderer.setProperty(this.element.nativeElement, 'value', timeVal);
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public validate({ value }: FormControl): ValidationErrors {
		if (!this.viewValue) {
			return null;
		}

		if (!isNumber(value)) {
			return { timeInput: true };
		}
	}

	private resetBaseDate(): void {
		this.baseDate = moment(this.baseDateInput).startOf('d').toDate();
	}
}
