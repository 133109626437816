import {
	Component,
	Input,
	OnChanges,
	SimpleChanges
} from '@angular/core';

import { isString } from 'rev-shared/util';

import { VbTitleService } from './VbTitle.Service';

/**
 * The `vbTitle` directive allows you to set the page's title (document.title property) for any view or state.
 * The original title is saved and restored when the user navigates away. The title may contain dynamic content.
 *
 * example:  <vb-title [title]="'@Js.Strings.MyTitle_With_Param({{account.name}})'"></vb-title>
 */
@Component({
	selector: 'vb-title',
	template: ``
})
export class VbTitleComponent implements OnChanges {
	@Input() public title: string;

	private titleId: number;

	constructor(
		private VbTitleService: VbTitleService
	) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.title && isString(this.title)) {
			if (this.titleId) {
				this.VbTitleService.updateTitle(this.titleId, this.title);
			} else {
				this.titleId = this.VbTitleService.addTitle(this.title);
			}
		}
	}

	public ngOnDestroy(): void {
		this.VbTitleService.removeTitle(this.titleId);
	}
}
