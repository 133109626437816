import { SignalRHubsConnection } from 'rev-shared/push/SignalRHubsConnection';

import { UserContextService } from './UserContext.Service';
import { SessionService } from './Session.Service';

export function SessionRun(Session: SessionService, SignalRHubsConnection: SignalRHubsConnection, UserContext: UserContextService): void {

	SignalRHubsConnection.on({
		// Attempt to extend session on reconnect. This forces the UI to bounce back to the login page if the session ended while the connection was down.
		reconnected: () => Session.tryExtendTimeout()
	});
}
