const UploadUrlType = {
	DIRECT_URL: 'directURL',
	PRESENTATION_PROFILE: 'presentationProfile'
};

const DirectUrlType = {
	LIVE: 'Live',
	VOD: 'Vod'
};

export { UploadUrlType, DirectUrlType };
