import { Pipe } from '@angular/core';

import { formatMediumDateTime, formatShortTime, formatMediumDate } from './DateFormatters';

@Pipe({ name: 'vbDateTimeMedium' })
export class VbDateTimeMediumPipe {
	public transform = formatMediumDateTime;
}

@Pipe({ name: 'vbDateMedium' })
export class VbDateMediumPipe {
	public transform = formatMediumDate;
}

@Pipe({ name: 'vbTimeShort' })
export class VbTimeShortPipe {
	public transform = formatShortTime;
}
