import {
	Directive,
	Input,
	OnChanges,
	OnInit,
	ElementRef,
	SimpleChanges
} from '@angular/core';
import $ from 'jquery';

/**
	This directive will "tail" a scrollable element, scrolling to the bottom when new content is added.

	attributes:
		vbUiTail: a watch expression that triggers a rescroll.  Set this to the ng-repeat array's length normally
		vbTailBottom: how far the user can scroll from the bottom to activate tailing. 32 by default

	example:

	<div class="comments-box" [vbUiTail]="comments.length">
		<div ng-repeat="comments">....
**/
@Directive({
	selector: '[vbUiTail]'
})
export class VbUiTailAngularDirective implements OnInit, OnChanges {
	@Input('vbUiTail') public vbTail: number;
	@Input() public vbTailBottom: number;

	private readonly DEFAULT_TAIL_BOTTOM: number = 128;

	private isInitialUpdate: boolean;
	private $element: JQuery<HTMLElement>;

	constructor(
		element: ElementRef
	) {
		this.$element = $(element.nativeElement);
	}

	public ngOnInit(): void {
		this.isInitialUpdate = true;

		if (!this.vbTailBottom) {
			this.vbTailBottom = this.DEFAULT_TAIL_BOTTOM;
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.vbTail) {
			this.update();
		}
	}

	private getScrollBottomDistance(): number {
		return this.$element.prop('scrollHeight') - this.$element.innerHeight() - this.$element.scrollTop();
	}

	private scrollToBottom(): void {
		this.$element.scrollTop(this.$element.prop('scrollHeight') - this.$element.innerHeight());
	}

	private update(): void {
		if (this.getScrollBottomDistance() < this.vbTailBottom){
			if (this.isInitialUpdate && this.vbTail) {
				this.isInitialUpdate = false;

				//allow extra time when the browser is rendering for the first time
				setTimeout(() => this.scrollToBottom(), 1000);
			} else{
				setTimeout(() => this.scrollToBottom(), 10);
			}
		}
	}
}
