import { Component, Input, OnInit, ElementRef, forwardRef } from '@angular/core';

@Component({
	selector: 'vb-grid-row-collection',
	template: `
		<ng-content></ng-content>
	`
})

export class VbGridRowCollectionComponent {
}
