import { Attribute, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { VbAuthorizationBaseAngular } from './VbAuthorizationBaseAngular';
import { SecurityContextService } from './SecurityContext.Service';

/**
 * vbAuthorizeState
 * Input is either an sref string supplied as a value to vbAuthorizeState OR the value of the uiSref attribute (directive from UI Router).
 * Functions like ng-if where the condition is if you are authorized.
 * <div *vbAuthorizeState="'teams.add'">Content</div>
 *
 * To provide fallback content to users who do not have access to the state, add an else templateRef in the same style as *ngIf.
 * <div *vbAuthorizeState="'teams.add'; else elseTemplateRef">Content</div>
 * <ng-template #elseTemplateRef>Else content!</ng-template>
 */
@Directive({
	selector: '[vbAuthorizeState]'
})
export class VbAuthorizeStateDirective extends VbAuthorizationBaseAngular {
	@Input() public vbAuthorizeState: string;
	@Input('vbAuthorizeStateElse') public elseTemplateRef: TemplateRef<HTMLElement>;

	@Attribute('uiSref') public uiSref: string;

	constructor(
		private SecurityContext: SecurityContextService,
		templateRef: TemplateRef<HTMLElement>,
		viewContainer: ViewContainerRef
	) {
		super(templateRef, viewContainer);
	}

	public checkPermission(): boolean {
		const stateName: string = this.getStateName();

		return getStateHierarchy(stateName)
			.reduce((output, state) => output && this.SecurityContext.allowStateChangeSync(state), true);
	}

	private getStateName(): string {
		return this.uiSref ||
			this.vbAuthorizeState;
	}
}

export function getStateHierarchy(stateName: string): string[] {
	const segments: string[] = stateName.split('.');

	return segments
		.map((_segment: string, i: number) => segments.slice(0, i + 1).join('.'));
}
