import { NgModule } from '@angular/core';
import { VbUiTextInputDirective } from './VbUiTextInput.Directive';

const directives = [
	VbUiTextInputDirective
];

@NgModule({
	declarations: directives,
	exports: directives
})
export class TextInputAngularModule {}
