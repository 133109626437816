import { Component } from '@angular/core';

import { Subscription } from 'rxjs';

import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';
import { ThemeService } from 'rev-portal/branding/Theme.Service';

import { IRules } from 'rev-shared/ui/css/CssRules.Contract';

import styles from './VbAuthLayout.Module.less';

@Component({
	selector: 'vb-auth-layout',
	templateUrl: './VbAuthLayout.Component.html'
})
export class VbAuthLayoutComponent {

	public readonly styles = styles;

	public themeServiceSub: Subscription;
	public themeStyleOverrides: IRules;

	constructor(
		private ThemeService: ThemeService
	) { }

	public ngOnInit(): void {
		this.themeServiceSub = this.ThemeService.brandingSettings$.subscribe(brandingSettings => this.applyCssRules(brandingSettings));
	}

	public ngOnDestroy(): void {
		this.themeServiceSub?.unsubscribe();
	}

	public applyCssRules(brandingSettings: BrandingSettings): void {
		const themeAccentColor = brandingSettings.themeSettings.accentColor;
		const headerBackgroundColor = brandingSettings.headerSettings.backgroundColor;
		const headerFontColor = brandingSettings.headerSettings.fontColor;

		this.themeStyleOverrides = {
			'.form-control:focus:not(:active):not(.active)': {
				['box-shadow']: `inset 0 0 0 1px ${themeAccentColor}, 0 0 10px rgba(0, 0, 0, 0.25) !important`,
				['border-color']: `${themeAccentColor} !important`
			},
			'.form-control:active': {
				['border-color']: `${themeAccentColor} !important`
			},
			[`
				input:-webkit-autofill,
				input:-webkit-autofill:hover,
				input:-webkit-autofill:focus,
				input:-webkit-autofill:active,
				input:-internal-autofill-selected
			`]: {
				['border']: `1px solid #cbcbcb !important`,
				['-webkit-text-fill-color']: `${headerFontColor} !important`,
				['-webkit-box-shadow']: `0 0 0 30px ${headerBackgroundColor} inset !important`,
				['transition']: `background-color 5000s ease-in-out 0s`
			}
		};
	}

}
