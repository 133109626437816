import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';
import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';

import { CssRulesAngularModule } from '../css/Css.AngularModule';
import { DateRangePickerComponent } from './DateRangePicker.Component';
import { VbUiDatePickerComponent } from './VbUiDatePicker.Component';
import { DatePickerStylesComponent } from './DatePickerStyles.Component';

const exports = [
	VbUiDatePickerComponent,
	DateRangePickerComponent
];

@NgModule({
	declarations: [
		...exports,
		DatePickerStylesComponent
	],
	exports,
	imports: [
		BrandingAngularModule,
		BsDropdownModule,
		BsDatepickerModule,
		ButtonsAngularModule,
		CommonModule,
		CssRulesAngularModule,
		DirectivesAngularModule,
		FormsModule,
		TextInputAngularModule,
		TranslateModule
	]
})
export class VbUiDatePickerModule {}
