import { Injectable } from '@angular/core';

@Injectable()
export class VbTitleService {
	private id: number = 0;
	private titles: Array<{ id: number; value: string }>;

	constructor() {
		this.titles = [];

		//populate with the default title
		this.addTitle(document.title);
	}

	public addTitle(title: string): number {
		const id: number = this.nextId();

		this.titles.push({ id, value: title });
		this.syncTitle();

		return id;
	}

	public removeTitle(id: number): void {
		const titleIndex = this.titles.findIndex(title => title.id === id);
		if(titleIndex >= 0) {
			this.titles.splice(titleIndex, 1);
			this.syncTitle();
		}
	}

	public updateTitle(id: number, title: string): void {
		const t = (this.titles || []).find(title => title.id === id);

		if (t) {
			t.value = title;
		}

		this.syncTitle();
	}

	private nextId(): number {
		return this.id++;
	}

	private syncTitle(): void {
		const title = this.titles[this.titles.length - 1].value;
		document.title = title;
	}
}
