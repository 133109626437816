export const NONE: string = 'None';
export const UNCATEGORIZED: string = 'uncategorized';

export enum ApprovalStatus {
	APPROVED = 'Approved',
	PENDING_APPROVAL = 'PendingApproval',
	REJECTED = 'Rejected',
	REQUIRES_APPROVAL = 'RequiresApproval'
}

export enum ApprovalStepStatus {
	APPROVED = 'Approved',
	PENDING = 'Pending',
	REJECTED = 'Rejected'
}

export enum AccessControl {
	Private = 'Private',
	Public = 'Public',
	AllUsers = 'AllUsers'
}
