import { InjectionToken } from '@angular/core';

import moment from 'moment';

import { isString } from 'rev-shared/util';

import { DateParser, DateParserFactory } from './DateParser.Contract';

export const DATE_PARSER_FACTORY_SERVICE_TOKEN: InjectionToken<DateParserFactory> = new InjectionToken('DateParserFactory');

/**
 * Utility for parsing dates.
 * format: string. A moment date format string. https://momentjs.com/docs/#/displaying/format/
 * Example:   var parser = DateParser('LLL');
 * var date = parser("2014-01-25 14:00:00")
 */
export function getDateParserService(): DateParserFactory {
	return (format: string = 'l', timeOnly: boolean = false): DateParser => {

		return (dateStr: string, baseDate?: Date): Date | number => {
			if (!isString(dateStr)) {
				return undefined;
			}

			if (!baseDate) {
				baseDate = new Date();
				baseDate.setHours(0, 0, 0, 0);
			}

			const baseDateParseInput = getBaseDateParseInput(baseDate);

			const parseResult = moment(baseDateParseInput.value + dateStr, baseDateParseInput.format + format, true);

			if (!parseResult.isValid()) {
				return undefined;
			}

			const result = parseResult.local().toDate();

			if (timeOnly) {
				return result.getTime() - baseDate.getTime();
			}

			return result;
		};
	};
}

function getBaseDateParseInput(baseDate: Date) {
	const values: number[] = [baseDate.getFullYear(), baseDate.getMonth() + 1, baseDate.getDate()];
	const formats: string[] = ['Y', 'M', 'D'];
	const separator: string = '$';

	return {
		format: formats.join(separator) + ' ',
		value: values.join(separator) + ' '
	};
}
