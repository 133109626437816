import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DialogRegistrationService } from 'rev-shared/ui/dialog/DialogRegistration.Service';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';
import { VbTagsInputModule } from 'rev-shared/ui/ngTagsInput/Ngx/VbTagsInput.Module';

import { SparkService } from './Spark.Service';
import { SubscribeSparkCategoryComponent } from './SubscribeSparkCategory.Component';
import { VbSubscribeSparkCategoryButtonComponent } from './VbSubscribeSparkCategoryButton.Component';
import { VideoShareSparkComponent } from './VideoShareSpark.Component';

const components = [
	SubscribeSparkCategoryComponent,
	VbSubscribeSparkCategoryButtonComponent,
	VideoShareSparkComponent
];

@NgModule({
	imports: [
		ButtonsAngularModule,
		CommonModule,
		DialogAngularModule,
		FormGroupAngularModule,
		FormsModule,
		SecurityAngularModule,
		SpinnerAngularModule,
		TranslateModule,
		ValidationAngularModule,
		ValidationModule,
		VbTagsInputModule
	],
	declarations: components,
	exports: components,
})

export class SparkModule {
}

@NgModule({
})
export class SparkRootModule {
	constructor(dialog: DialogRegistrationService) {
		dialog.register('ShareVideoToSpark', {
			component: VideoShareSparkComponent
		});

		dialog.register('SubscribeToCategoryInSpark', {
			component: SubscribeSparkCategoryComponent
		});
	}
}
