<span *ngIf="showButton">
	<button type="button" class="btn btn-primary spark-button" (click)="subscribe()" [hidden]="subscribed">
		<span class="spark-icon" aria-hidden="true"></span>
		{{ 'Media_Videos_Spark_Subscribe' | translate }}
	</button>
	<button type="button" class="btn btn-primary spark-button" (click)="unsubscribe()" [hidden]="!(subscribed)">
		<span class="spark-icon" aria-hidden="true"></span>
		{{ 'Media_Videos_Spark_Unsubscribe' | translate : { '0': subscription?.roomTitle } }}
	</button>

	<vb-confirmation-dialog [title]="'Media_Videos_Spark_UnsubscribeTitle' | translate" [message]="'Media_Videos_Spark_UnsubscribeMessage' | translate : { '0': subscription?.roomTitle }" [cancelText]="'Cancel' | translate" [actionText]="'Ok' | translate">
	</vb-confirmation-dialog>
</span>
