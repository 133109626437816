import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TypeaheadDirective } from 'ngx-bootstrap/typeahead';
import { defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ISearchResult } from 'rev-portal/search/InsightSearchHelper.Service';

import { ApiVirtualScrollDataSource } from 'rev-shared/cdkVirtualScroll/ApiVirtualScrollDataSource';
import { BaseControlValueAccessor } from 'rev-shared/util/BaseControlValueAccessor';

@Component({
	template: ''
})
export class VbQuerySelectorBaseComponent extends BaseControlValueAccessor<any> implements OnInit, AfterViewInit {
	@Input() public searchQuery: (query: string, params?: any) => () => Promise<ISearchResult>;

	@ViewChild('itemsViewport') public itemsViewport: CdkVirtualScrollViewport;
	@ViewChild('queryInput') public queryInput: ElementRef;
	@ViewChild(TypeaheadDirective) public typeahead: TypeaheadDirective;

	public readonly dataSource = new ApiVirtualScrollDataSource(this.load.bind(this), true);
	public readonly maxInt = Number.MAX_SAFE_INTEGER;

	public search: string;
	public currentSearch: string;
	public typeaheadInput$: Observable<any[]>;
	public minLength: number = 2;
	public next: () => Promise<ISearchResult>;

	constructor() {
		super();
	}

	public get isValueSelected(): boolean {
		return this.value !== null && this.value !== undefined;
	}

	public ngOnInit(): void {
		this.typeaheadInput$ = defer(() => {
			if(this.search !== this.currentSearch) {
				this.refreshData();
			}
			return this.dataSource.data$.pipe(
				map(data => data.length ? data : [{}])
			);
		});
	}

	public ngAfterViewInit(): void {
		this.itemsViewport?.checkViewportSize();
	}

	public clearSelection(): void {
		this.search = '';
		this.dataSource.update([]);
		this.updateModelValue(null);
		setTimeout(() => {
			this.queryInput.nativeElement.focus();
			this.refreshData();
		}, 10);
	}

	public hide(): void {
		this.typeahead.hide();
	}

	public onSelect(event: any): void {
		if (event.item?.id) {
			this.updateModelValue(event.item);
			return;
		}

		this.search = '';
		this.updateModelValue(null);
	}

	public refreshData(): void {
		this.next = null;
		this.dataSource.setPaused(false);
		this.dataSource.update([]);
		this.dataSource.loadPages();
		this.itemsViewport?.checkViewportSize();
	}

	public getSearchParams(): any {
		return;
	}

	private load(): Promise<any[]> {
		const prevSearch = this.currentSearch;
		this.currentSearch = this.search;

		if ((this.search?.length ?? 0) < this.minLength) {
			this.dataSource.setPaused(true);
			return Promise.resolve([]);
		}
		if (this.search !== prevSearch || !this.next) {
			this.next = this.searchQuery(this.search, this.getSearchParams());
		}

		return this.next()
			.then((result: ISearchResult) => {
				const currentResults = result.items;
				if (!currentResults.length) {
					this.dataSource.setPaused(true);
					return [];
				}
				if (currentResults.length >= result.count) {
					this.dataSource.setPaused(true);
				}

				return currentResults;
			});
	}

	public activateItem(index: number): void {
		const match = this.typeahead.matches[index];
		this.typeahead._container?.selectActive(match);
	}

	public isActive(index: number): boolean {
		if(this.typeahead) {
			const match = this.typeahead.matches?.[index];
			return this.typeahead._container?.isActive(match);
		}
	}

	public selectMatch(index: number, e: any): void {
		const match = this.typeahead.matches[index];
		this.typeahead._container?.selectMatch(match, e);
	}
}
