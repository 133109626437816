import {
	Component,
	forwardRef,
	Input,
} from '@angular/core';
import { parentControlContainerProvider } from 'rev-shared/util/directives/form/validation/validationUtil';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseControlValueAccessor } from 'rev-shared/util/BaseControlValueAccessor';

import styles from './VbUiColorSelector.module.less';

let colorSelectorIndex = 0;
/**
 * This color selector built on top of native html input[type="color"]
 * if no color provided to component then it will fallback to white color.
 * usage -
 * <vb-ui-color-selector
		[accessibilityLabel]="'Admin_Team_Background_Title' | translate"
		name="colorPicker"
		[(ngModel)]="team.bgColor"
		[defaultColor]="defaultColor">
	</vb-ui-color-selector>
 */
@Component({
	selector: 'vb-ui-color-selector',
	templateUrl: './VbUiColorSelector.Component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => VbUiColorSelectorComponent),
		multi: true
	}],
	viewProviders: [parentControlContainerProvider()]
})
export class VbUiColorSelectorComponent extends BaseControlValueAccessor<string> {
	@Input() public accessibilityLabel: string;
	@Input() public defaultColor: string;

	public selectedColor: string;
	public readonly status: { [key: string]: boolean } = { active: true };
	public readonly defaultColorSelectorValue = '#ffffff';

	public readonly styles = styles;
	public readonly index = ++colorSelectorIndex;

	public writeValue(val: string): void {
		super.writeValue(val);

		this.syncColor();
	}
	private syncColor(): void {
		if (!this.value) {
			this.setDefaultColor(true);
			return;
		}
		if (this.value === this.selectedColor) {
			return;
		}
		this.selectedColor = this.extendHexColor(this.value);

	}

	public onInputColorChange(): void {
		//selectedColor can't be empty. it will throw unneccesary warning;
		const color = this.value ? this.value : this.defaultColor || this.defaultColorSelectorValue;
		this.selectedColor = this.extendHexColor(color);
		this.updateModelValue(this.value);
	}

	public onColorSelectorChange($event: Event): void {
		this.selectedColor = ($event.target as HTMLInputElement).value;
		this.updateModelValue(this.selectedColor);
	}

	private setDefaultColor(ignoreUpdate?: boolean): void {
		//selectedColor can't be empty. it will throw unneccesary warning;
		this.selectedColor = this.extendHexColor(this.defaultColor ?? this.defaultColorSelectorValue);
		this.updateModelValue(this.selectedColor, ignoreUpdate);
	}

	/**
	 * This method extends 3 digits hex color to 6 digits hex color.
	 * @param shortHex
	 * @returns
	 */
	private extendHexColor(shortHex: string): string {
		if(!shortHex?.startsWith('#') || shortHex?.length !== 4) {
			return shortHex;
		}

		return '#' +
			shortHex
				.slice(shortHex.startsWith('#') ? 1 : 0)
				.split('')
				.map(x => x + x)
				.join('');
	}
}
