import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VbUiTabPanelComponent } from './VbUiTabPanel.Component';
import { VbUiTabComponent } from './VbUiTab.Component';

const components = [
	VbUiTabComponent,
	VbUiTabPanelComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule
	]
})
export class TabPanelModule {}
