import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { ValidationAngularModule } from 'rev-shared/ui/validation/ValidationAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';

import { EditWebcastZoomInputComponent } from './EditWebcastZoomInput.Component';
import { VbZoomMeetingIdDirective } from './VbZoomMeetingIdAngular.Directive';
import { ZoomMeetingFormatPipe } from './ZoomMeetingFormat.Pipe';

@NgModule({
	declarations: [VbZoomMeetingIdDirective, EditWebcastZoomInputComponent, ZoomMeetingFormatPipe],
	exports: [VbZoomMeetingIdDirective, EditWebcastZoomInputComponent, ZoomMeetingFormatPipe],
	imports: [
		CommonModule,
		FormGroupAngularModule,
		FormsModule,
		NgxTrimDirectiveModule,
		TranslateModule,
		TypeaheadModule,
		ValidationAngularModule,
		ValidationModule
	]
})
export class ZoomAngularModule {}
