import {
	AfterViewInit,
	Component,
	ElementRef,
	forwardRef,
	Input,
	ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import './bs-datepicker.css';

import { getLocale } from 'rev-shared/bootstrap/LocaleContext';
import { DateUtil } from 'rev-shared/date/DateUtil';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import styles from './date-picker.module.less';
import { NgxLocaleLoader } from './NgxLocaleLoader.Service';

@Component({
	selector: 'vb-ui-date-picker',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VbUiDatePickerComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => VbUiDatePickerComponent),
			multi: true,
		}
	],
	host: {
		'[class]': 'styles.root'
	},
	templateUrl: './VbUiDatePicker.Component.html'
})
export class VbUiDatePickerComponent implements AfterViewInit, ControlValueAccessor, Validator {
	@Input() public accessibilityLabel: string;
	@Input() public isDisabled: boolean;

	@ViewChild('datePickerInput')
	public datePickerInput: ElementRef<HTMLInputElement>;
	public date: Date;

	public readonly config: any = {
		dateInputFormat: DateUtil.MOMENT_MEDIUM_DATE_FORMAT, //medium date format in momemt.
		containerClass: 'vb-ui-date-picker',
		customTodayClass: styles.today,
		adaptivePosition: true
	};
	public readonly styles = styles;

	private localeId: string = getLocale().momentLocale;
	private onChange: (value: Date) => void;
	public onTouched: () => void;

	constructor(
		NgxLocaleLoader: NgxLocaleLoader,
		protected translateService: TranslateService,
		private ThemeService: ThemeService
	) {
		NgxLocaleLoader.load();
	}

	public ngAfterViewInit(): void {
		this.manageAccessibility();
	}

	public writeValue(value: any): void {
		this.date = value;
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}


	private manageAccessibility(): void {
		if (!this.accessibilityLabel) {
			console.warn(' vb-ui-date-picker missing accessibility-label parameter');
		}
	}

	private validateDatePicker(value: Date): boolean {
		return this.isEmpty(value) ||
			!!value.getTime?.();
	}

	private isEmpty(value: any): boolean {
		return value === null;
	}

	public onDateChange(value: Date): void {
		this.onTouched();
		this.onChange(value);
	}

	public validate({ value }: FormControl): ValidationErrors {
		const elementVal = this.datePickerInput?.nativeElement?.value;
		if (this.isEmpty(value) || !elementVal) {
			return null;
		}

		//Taking input value into account because in some invalid cases
		//Input value should always be valid date.
		return DateUtil.isValidDate(elementVal, DateUtil.MOMENT_MEDIUM_DATE_FORMAT, this.localeId, true) ? null : { date: true };
	}
}
