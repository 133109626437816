import { range } from 'underscore';

import { prefetchImage } from 'rev-shared/util/ImageUtils';

import { IChapter } from './IChapter';

const focusTimeGap = 5000;
const numSlidesToPrefetch = 3;

export class VideoChaptersModel {

	public chapters: IChapter[];
	public currentChapter: IChapter;
	public currentChapterIndex: number;
	public focusedChapterIndex: number;
	public playbackTime: number;

	protected backupChapters: IChapter[];

	constructor(
		chapters: IChapter[]
	) {
		this.setChapters(chapters);
	}

	public get hasChapters(): boolean {
		return !!this.chapters.length;
	}

	public updatePlaybackTime(time: number): void {
		this.playbackTime = time;
		this.updateCurrentChapter();
	}

	public updateCurrentChapter(): void {
		const chapters: IChapter[] = this.chapters;
		const time: number = this.playbackTime || 0;

		if(time >= 0) {

			const chapter = this.getFocusedChapter();
			if(chapter) {
				this.selectChapter(chapter, this.focusedChapterIndex);
				return;
			}

			for(let i: number = chapters.length - 1; i >= 0; i--) {
				const chapter: IChapter = chapters[i];

				if(time >= chapter.time) {
					this.focusChapter(null);
					this.selectChapter(chapter, i);
					return;
				}
			}
		}

		this.focusChapter(null);
		this.selectChapter(null);
	}

	private selectChapter(chapter: IChapter, index: number = undefined): void {
		if(index !== this.currentChapterIndex || chapter !== this.currentChapter) {
			if (index >= 0) {
				this.prefetchSlides(index);
			}

			this.currentChapter = chapter;
			this.currentChapterIndex = index;
		}
	}

	/*
	 * Time reported by video player is not accurate. Normally reports the slides time minus ~500ms.
	 * To get around this, allow the focused slide to stay active for a few extra seconds, before and after its normal time.
 	 */
	public focusChapter(index: number): void {
		this.focusedChapterIndex = index >= 0 ? index : null;
	}

	public getFocusedChapter(): IChapter {
		const chapter = this.chapters[this.focusedChapterIndex];
		const t = this.playbackTime;
		if(chapter) {
			const chapterTime = chapter.time;

			if(chapterTime - focusTimeGap < t && t < chapterTime + focusTimeGap) {
				return chapter;
			}
		}
	}

	public setChapters(chapters: IChapter[]): void {
		this.chapters = chapters || [];

		this.updateCurrentChapter();
	}

	public areChaptersModified(): boolean {
		if(this.backupChapters) {
			return this.chapters.length !== this.backupChapters.length ||
				this.chapters.some((chapter, i) => !this.areChaptersEqual(this.backupChapters[i], chapter));
		}

		return false;
	}

	public areChaptersEqual(oldChapter: IChapter, newChapter: IChapter): boolean {
		return newChapter.title === oldChapter.title &&
			newChapter.newTime === oldChapter.time &&
			newChapter.imageId === oldChapter.imageId;
	}

	// used for editing. allows presentation to be edited, then canceled
	public saveBackup(): void {
		this.backupChapters = [...this.chapters];
	}

	public restoreFromBackup(): void {
		const chapters = this.backupChapters;

		this.backupChapters = null;
		this.chapters = chapters;
		this.updateCurrentChapter();
	}
	public removeBackup(): void {
		this.backupChapters = null;
	}

	public prefetchSlides(startIndex: number): void {
		range(startIndex, startIndex + numSlidesToPrefetch + 1)
			.map(i => this.chapters[i]?.url)
			.filter(Boolean)
			.forEach(prefetchImage);
	}
}
