import { Component, Input, OnChanges } from '@angular/core';

@Component({
	selector: 'vb-loading-bar',
	host: {
		'class': 'black-box loading-bar'
	},
	template:
		`<div class="progress progress-striped active">
			<div class="progress-bar progress-bar-info" style="width:100%"></div>
		</div>
		<div class="text-align-center">
			<ng-content></ng-content>
		</div>`
})
export class VbLoadingBarComponent implements OnChanges {
	@Input() public isLoading: boolean;

	public ngOnChanges(): void {
		this.isLoading = !this.isLoading;
	}
}
