import { findWhere } from 'underscore';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { VbDialogBaseComponent } from 'rev-shared/ui/dialog/VbDialogBase.Component';
import { orderBy } from 'rev-shared/util/SortUtil';
import { retryUntilSuccess } from 'rev-shared/util/PromiseUtil';

import { SparkService } from './Spark.Service';

@Component({
	selector: 'subscribe-spark-category',
	templateUrl: './SubscribeSparkCategory.Component.html'
})
export class SubscribeSparkCategoryComponent extends VbDialogBaseComponent<SubscribeSparkCategoryComponent> implements OnInit, OnDestroy {
	@Input() public category: any;

	public sparkAuthenticationRequired: boolean;
	private sparkOnAuthenticateUnsubscribe: IUnsubscribe;
	public roomId: string;
	public rooms: any;
	public status: any;

	constructor(
		private SparkService: SparkService,
		bsModalRef: BsModalRef,
		bsModalService: BsModalService
	) {
		super(bsModalRef, bsModalService);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.loadRooms();
	}

	public ngOnDestroy(): void {
		if (this.sparkOnAuthenticateUnsubscribe) {
			this.sparkOnAuthenticateUnsubscribe();
		}
	}

	private loadRooms(isRetry: boolean = false) {
		this.status = { processing: true };
		this.sparkAuthenticationRequired = false;

		return (isRetry ?
			retryUntilSuccess(() => Promise.resolve(this.SparkService.getRooms())) :
			Promise.resolve(this.SparkService.getRooms())
		)
			.then(rooms => {
				this.status = { active: true };
				this.rooms = orderBy(rooms, acc => acc.title, true);
			})
			.catch(err => {
				if (err.authenticationRequired && !isRetry) {
					this.status = { active: true };
					this.sparkAuthenticationRequired = true;

					this.sparkOnAuthenticateUnsubscribe = this.SparkService.onAuthenticate(() => this.loadRooms(true));
				} else {
					this.status = {
						error: true,
						loadRoomsError: true
					};
				}
			});
	}

	public submit(): void {
		this.status = { processing: true };

		const room = findWhere(this.rooms, { id: this.roomId });

		this.SparkService.createCategorySubscription(room, this.category.id)
			.then(() => this.onActionInternal())
			.catch(() => {
				this.status = {
					error: true,
					saveError: true
				};
			});
	}
}
