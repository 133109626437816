import { TranslateService } from '@ngx-translate/core';

export function getInsightStrings(t: TranslateService): any {
	return Object.entries({
		noResultsFound: 'NoResultsFound',
		noItemsAssigned: 'NoItemsAssigned',
		findItemsPlaceholder: 'FindItems',
		done: 'Done',
		recentItems: 'RecentItems',
		recordLimit: 'RecordLimit',
		searching: 'Searching'
	}).reduce((acc, [insightKey, revKey]) => {
		acc[insightKey] = t.instant(revKey);
		return acc;
	}, {});
}
