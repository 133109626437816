import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PushService } from 'rev-shared/push/PushService';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

@Injectable({
	providedIn: 'root'
})
export class SharedDeviceService {
	constructor(
		private http: HttpClient,
		private PushService: PushService
	) {}

	public getDmeDevice(deviceId: string): Promise<any> {
		if (!deviceId) {
			return Promise.reject('device id not provided');
		}

		return lastValueFrom(this.http.get(`/devices/dme/${deviceId}`));
	}

	public addAkamaiDevice(device: any): Promise<any> {
		return this.PushService.dispatchCommand('devices:AddAkamaiDevice', {
			accountId: device.accountId,
			name: device.name,
			hostname: device.hostname,
			isActive: device.isActive,
			isSecureDelivery: device.isSecureDelivery
		});
	}

	public addAkamaiLiveDevice(device: any): Promise<any> {
		return this.PushService.dispatchCommand('devices:AddAkamaiLiveDevice', this.mapAkamaiLiveDevice(device));
	}

	public updateAkamaiDevice(device: any): Promise<any> {
		return this.PushService.dispatchCommand('devices:UpdateAkamaiDevice', {
			accountId: device.accountId,
			deviceId: device.id,
			name: device.name,
			hostname: device.hostname,
			isActive: device.isActive,
			isSecureDelivery: device.isSecureDelivery
		});
	}

	public updateAkamaiLiveDevice(device: any): Promise<any> {
		const akamaiLiveDevice = this.mapAkamaiLiveDevice(device);
		akamaiLiveDevice.deviceId = device.id;
		return this.PushService.dispatchCommand('devices:UpdateAkamaiLiveDevice', akamaiLiveDevice);
	}

	private mapAkamaiLiveDevice(device: any): any {
		return {
			accountId: device.accountId,
			name: device.name,
			hostname: device.hostname,
			isActive: device.isActive,
			streamId: device.streamId,
			sharedAuthKey: device.sharedAuthKey
		};
	}
}
