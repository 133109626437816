import { NgModule } from '@angular/core';

import { DisablePasswordAutoCompleteDirective } from './DisablePasswordAutocompleteAngular.Directive';
import { VbAutoFocusDirective } from './VbAutoFocus.Directive';
import { VbDisableSubmitAngularDirective } from './VbDisableSubmitAngular.Directive';
import { VbKeypressDirective } from './VbKeypressHandler.Directive';

const directives = [
	DisablePasswordAutoCompleteDirective,
	VbAutoFocusDirective,
	VbKeypressDirective,
	VbDisableSubmitAngularDirective
];

@NgModule({
	declarations: directives,
	exports: directives
})
export class FormDirectivesAngularModule {}
