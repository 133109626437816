import { Directive, ElementRef, OnInit } from '@angular/core';

import styles from './VbGridList.module.less';

@Directive({
	selector: '[vbGridToolbarColumn]'
})

export class VbGridToolbarColumnDirective implements OnInit {

	constructor(private el: ElementRef<HTMLElement>) {
	}

	public ngOnInit(): void {
		this.el.nativeElement.classList.add(styles.toolbarColumn);
		this.el.nativeElement.querySelector('button, a').classList.add('btn btn-sm btn-admin btn-white');
	}

}
