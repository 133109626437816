import { Directive, Input, forwardRef } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Apply this directive to a typeahead component and it would restrict users from entering invalid values into typeahead
 * controls. This option was available in earlier versions of typeahead but has not been implemented in the current version.
 *
 * sets error "{ editable: true }" on the control if the selected item is not in the list.
 *
 * Example:
 *
 * 	<input
 * 		[typeahead]="list"
 * 		typeaheadOptionField="key"
 * 		vbRestrictTypeaheadEditable/>
 */


@Directive({
	selector: '[vbRestrictTypeaheadEditable]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => VbRestrictTypeaheadEditableDirective),
			multi: true
		}
	]
})
export class VbRestrictTypeaheadEditableDirective {
	@Input() public typeahead: any[];
	@Input() public typeaheadOptionField: string;

	public validate(control: AbstractControl): ValidationErrors {
		if (!control?.value) {
			return null;
		}

		const matches = this.typeahead.filter(option => option[this.typeaheadOptionField] == control.value);
		return matches.length ? null : { editable: true };
	}
}
