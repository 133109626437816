import { formatTimespanShort } from 'rev-shared/date/DateFormatters';

export class Stopwatch {
	private startTime: number;
	private pauseTime: number;
	private totalPauseDuration: number;

	constructor() {
		this.restart();
	}

	public pause(): void {
		this.pauseTime = Date.now();
	}

	public resume(): void {
		this.totalPauseDuration = this.totalPauseDuration + Date.now() - this.pauseTime;
		this.pauseTime = null;
	}

	public restart(startTime?: number): void {
		this.startTime = startTime ?? Date.now();
		this.pauseTime = null;
		this.totalPauseDuration = 0;
	}

	public get time(): number {
		return Date.now() - this.startTime - this.totalPauseDuration;
	}

	public format(): string {
		return formatTimespanShort(this.time);
	}
}
