import { Component, Input } from '@angular/core';

import { ThemeService } from './Theme.Service';

import './branding-logo.less';

@Component({
	selector: 'branding-logo',
	templateUrl: './BrandingLogo.Component.html'
})
export class BrandingLogoComponent {
	@Input() public brandingLogoUri: string;
	constructor(
		private ThemeService: ThemeService
	) {}

	public get logoUri() {
		return this.brandingLogoUri || this.ThemeService.brandingSettings.themeSettings.logoUri;
	}
}
