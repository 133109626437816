import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { escape } from 'rev-shared/util';

/**
 * Used to hilight matching sections of content. Works by wrapping matches in a span.ui-match element.
 * edgeHilight: Match only at the beginning of each word.
 *
 * Example:  <div> {{text | insightHilight}}</div>
 *
 **/

const rxSpecials = (/[-[\]{}()*+?.,\\^$|#\s]/g);

@Pipe({ name: 'insightHilight' })
export class InsightHilightPipe implements PipeTransform {

	constructor(
		private ds: DomSanitizer
	){}


	public transform(rawText: string, search: string, edgeHilight?: boolean): SafeHtml {
		if(!rawText) {
			return rawText;
		}

		const text = escape(rawText);

		if(!search) {
			return text;
		}

		const s = search.toString().replace(rxSpecials, '\\$&');
		const html = edgeHilight ?
			text.replace(new RegExp(`(^|\\s)(${s})`, 'gi'), '$1<span class="ui-match">$2</span>') :
			text.replace(new RegExp(s, 'gi'), '<span class="ui-match">$&</span>');

		return this.ds.bypassSecurityTrustHtml(html);
	}
}
