import { NgModule } from '@angular/core';

import { CssRulesDirective } from 'rev-shared/ui/css/CssRules.Directive';

@NgModule({
	declarations: [CssRulesDirective],
	exports: [CssRulesDirective]
})
export class CssRulesAngularModule {
}
