export enum EventAccessControl {
	Public = 'Public',
	TrustedPublic = 'TrustedPublic',
	AllUsers = 'AllUsers',
	Private = 'Private'
}

export enum AttendeeJoinMethod {
	Anonymous = 'Anonymous',
	Registration = 'Registration'
}
