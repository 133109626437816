const minDate = '0000-01-01';
const maxDate = '9999-01-01';

export const SortRecommended = 'recommended';
export const SortWhenUploaded = 'whenUploaded';

export const SearchConstants = {
	minDate,
	maxDate,
	minDateTime: minDate + 'T00:00:00Z',
	maxDateTime: maxDate + 'T00:00:00Z',
	defaultSortField: '_score',
	nameSortField: 'name.sort',
	sortAscending: 'asc',
	sortDescending: 'desc',
	initialPageSize: 100,
	pageSize: 300,
	minAccessEntitySearchQueryLength: 1,
	accessEntityPageSize: 100,
	itemTypes: {
		accessEntities: 'accessEntity',
		devices: 'device',
		videos: 'video',
		attendee: 'attendee',
		teammembership: 'teammembership',
		stb: 'stb',
		deviceLogs: 'devicelogs'
	},
	accessEntityTypes: {
		user: 'User',
		team: 'Team',
		group: 'Group',
		role: 'Role'
	},

	rights: {
		createEvents: 'http://vbrick.com/rights/scheduled-events/events/live/create',
		createMedia: 'http://vbrick.com/rights/media/media/create'
	}
};
