import {
	AfterViewInit,
	Directive,
	ElementRef,
	forwardRef,
	HostListener,
	Input,
	Renderer2
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, ValidationErrors } from '@angular/forms';

interface IPortRange {
	end?: string | number;
	start: string | number;
}
const RANGE_SEPARATOR: string = '-';

@Directive({
	selector: '[vbPortRangeInput]',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => VbPortRangeInputDirective),
		multi: true
	}, {
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => VbPortRangeInputDirective),
		multi: true
	}]
})
export class VbPortRangeInputDirective implements AfterViewInit, ControlValueAccessor, Validator {
	@Input() public minPort: number;
	@Input() public maxPort: number;

	private nativeElement: HTMLInputElement;
	private isMinMaxProvided: boolean;

	constructor(
		Element: ElementRef,
		private renderer : Renderer2
	) {
		this.nativeElement = Element.nativeElement;
	}

	private onChangeFn: (val: IPortRange) => void;
	private onTouchFn: () => void;

	public ngAfterViewInit(): void {
		this.isMinMaxProvided = !!this.minPort && !!this.maxPort;
	}

	public writeValue(val: IPortRange): void {
		if (!val) {
			return;
		}
		this.renderer.setProperty(this.nativeElement, 'value', this.format(val));
	}

	public registerOnChange(fn: any): void {
		this.onChangeFn = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouchFn = fn;
	}

	@HostListener('input', ['$event.target.value'])
	public input(value: string): void {
		this.onTouchFn();
		this.onChangeFn(this.parse(value));
	}

	private parse(textInput: string): IPortRange {
		const input = (textInput || '')
			.split(RANGE_SEPARATOR)
			.map(line => line.trim());

		if (!input.length) {
			return null; //to avoid parse error;
		}

		const end = input.slice(1, input.length).join('');

		return {
			start: input[0],
			end
		};
	}

	private format(modelValue: IPortRange): string {
		if (!modelValue || !modelValue.start) {
			return;
		}
		return modelValue.end ? `${modelValue.start}-${modelValue.end}` : modelValue.start.toString();
	}

	public validate(control: AbstractControl): ValidationErrors {
		if (!control?.value) {
			return null;
		}
		const start = +control.value.start;
		const end = +control.value.end;

		if (isNaN(start) || (control.value.end && isNaN(end))) {
			return { invalidPortRange: true };
		}
		const isValid = end ? this.validatePortRange(start, end)
			: this.validatePort(start);

		return isValid ? null : { invalidPortRange: true };
	}

	private validatePort(port: number) {
		return !this.isMinMaxProvided ? true
			: this.validateRange(this.minPort, port) && this.validateRange(port, this.maxPort);
	}

	private validatePortRange(start: number, end: number) {
		return start < end
			&& this.validatePortRangeWithMinMax(start, end);
	}

	private validatePortRangeWithMinMax(start: number, end: number): boolean {
		if (!this.isMinMaxProvided) {
			return true;
		}

		return this.validateRange(this.minPort, start)
			&& this.validateRange(end, this.maxPort);
	}

	private validateRange(min: number, max: number): boolean {
		return min < max;
	}
}
