import { Directive, forwardRef, Input } from '@angular/core';
import { ValidationErrors, AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

import { ValidationRules } from 'rev-shared/util/ValidationRules';

@Directive({
	selector: '[vbMedium]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => VbMediumDirective),
			multi: true
		}
	]
})
export class VbMediumDirective implements Validator {
	@Input() public vbMedium: any;

	public validate(control: AbstractControl): ValidationErrors {
		if (this.vbMedium == null || !control?.value) {
			return null;
		}
		return ValidationRules.checkMediumText(control.value) ? null : { vbMedium: true };
	}
}
