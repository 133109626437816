import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TranslateModule } from '@ngx-translate/core';

import { HighlightModule } from 'rev-shared/pipes/highlight/Highlight.Module';
import { FormDirectivesAngularModule } from 'rev-shared/util/directives/form/FormDirectivesAngular.Module';

import { BrandingAngularModule } from 'rev-portal/branding/BrandingAngular.Module';

import { VbUiCheckboxComponent } from './VbUiCheckbox.Component';
import { VbUiCheckboxGroupComponent } from './VbUiCheckboxGroup.Component';

const components = [
	VbUiCheckboxComponent,
	VbUiCheckboxGroupComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		BrandingAngularModule,
		ButtonsModule,
		CommonModule,
		FormDirectivesAngularModule,
		FormsModule,
		HighlightModule,
		TranslateModule
	]
})
export class CheckboxAngularModule {}
