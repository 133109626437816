import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { IMessageBatch } from '@vbrick/vbrick-logging-client/src';

import { noop } from 'rev-shared/util';
import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { UserLocalIPService } from 'rev-shared/security/UserLocalIP.Service';

import { VodPlayerLoggingClient } from './VodPlayerLoggingClient';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

const HTTP_RESPONSE_STATUS_CODE_UNAUTHORIZED: number = 401;
const RESOURCE_TYPE_VIDEO: string = 'video';
const TOKEN_RESOURCE_AUD: string = 'request_validator';

@Injectable()
export class VodPlayerLoggingService {
	constructor(
		private UserContext: UserContextService,
		private UserLocalIPService: UserLocalIPService,
		private http: HttpClient
	) {}

	public getClient(videoId: string, disableLogHeartbeat: boolean): VodPlayerLoggingClient {
		return new VodPlayerLoggingClient(
			this.UserContext,
			this.UserLocalIPService,
			videoId,
			disableLogHeartbeat,
			{
				getToken: () => this.getToken(videoId),
				sendMessages: (messages: IMessageBatch, token: string) => this.sendMessages(messages, token)
			}
		);
	}

	private getToken(videoId: string): Promise<string> {
		return lastValueFrom(this.http.get<{ token: string }>('/auth/token', {
			params: {
				aud: TOKEN_RESOURCE_AUD,
				resourceId: videoId,
				resourceType: RESOURCE_TYPE_VIDEO
			}
		}))
			.then(result => result.token);
	}

	private sendMessages(messages: IMessageBatch, token: string): Promise<void> {
		return lastValueFrom(this.http.post(BootstrapContext.analyticsUrl, messages, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}))
			.then(noop)
			.catch((reason: HttpErrorResponse) => {
				(reason as any).invalidToken = reason.status === HTTP_RESPONSE_STATUS_CODE_UNAUTHORIZED;

				return Promise.reject(reason);
			});
	}
}
