import { Component } from '@angular/core';
import { map } from 'rxjs/operators';

import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { ThemeSettings } from 'rev-portal/branding/BrandingSettings.Contract';

const root = '.vb-ui-date-picker';

@Component({
	selector: 'date-picker-styles',
	template: `<ng-template [vbCssRules]="cssRules$ | async"></ng-template>`
})
export class DatePickerStylesComponent {

	constructor(
		private ThemeService: ThemeService
	) {}

	public cssRules$ = this.ThemeService.brandingSettings$.pipe(map(settings => {
		const themeSettings: ThemeSettings = settings?.themeSettings || {} as any;
		return {
			[`
				${root} .bs-datepicker-head,
				${root} .bs-datepicker-predefined-btns button.selected,
				${root} .bs-datepicker-head button,
				${root} .bs-datepicker-body table td.selected span,
				${root} .bs-datepicker-body table td[class*=select-] span:after,
				${root} .bs-datepicker-body table td span.selected,
				${root} .bs-datepicker-body table td span[class*=select-]:after
			`]: {
				'background-color': `${themeSettings.accentColor} !important`
			},

			[`
				${root} .bs-datepicker-head button,
				${root} .bs-datepicker-body table td[class*=select-] span,
				${root} .bs-datepicker-body table td span[class*=select-]
			`]: {
				color: `${themeSettings.accentFontColor} !important`
			},

			[`${root} .bs-datepicker-body table td.week span`]: {
				color: `${themeSettings.accentColor} !important`
			}
		};
	}));
}
