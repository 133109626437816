import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output
} from '@angular/core';

import { ConfirmationDialogAngularComponent } from './ConfirmationDialogAngular.Component';
import { DialogService } from './Dialog.Service';
import { IConfirmationDialogParams } from './IConfirmationDialogParams';

/**
 * Confirmation dialog service that wraps the Angular Bootstrap Modal.
 * Example usage:
 * In your template:
 * <vb-confirmation-dialog
 * 		ng-ref="$ctrl.warningDialog"
 * 		title="Warning"
 * 		message="Are you sure?"
 * 		cancel-text="Cancel"
 * 		action-text="Ok"
 * 		themed="true"
 * >
 * </vb-confirmation-dialog>
 * In component:
 * 		this.warningDialog.open().result
 * 		.then(function () {
 * 			//dialog was accepted
 * 		})
 * 		.catch(function () {
 * 			//dialog was dismissed
 * 		})
 */

@Component({
	selector: 'vb-confirmation-dialog',
	host: {
		style: 'display: none'
	},
	template: `
		<ng-content #content>
		</ng-content>
	`
})
export class VbConfirmationDialogComponent implements AfterViewInit {
	@Input() public actionText: string;
	@Input() public cancelText: string;
	@Input() public headerIconClass: string;
	@Input() public headerIconType: string;
	@Input() public message: string;
	@Input() public rememberText: string;
	@Input() public secondaryActionText: string;
	@Input() public themed: boolean;
	@Input() public title: string;

	@Output() public onReady: EventEmitter<void> = new EventEmitter();

	constructor(
		private elem: ElementRef<HTMLElement>,
		private Dialog: DialogService
	) {}

	public ngAfterViewInit(): void {
		this.onReady.emit();
	}

	public open(): ConfirmationDialogAngularComponent {
		const htmlMessage = this.elem.nativeElement.innerHTML.trim();

		const params: IConfirmationDialogParams = {
			actionText: this.actionText,
			cancelText: this.cancelText,
			headerIconClass: this.headerIconClass,
			headerIconType: this.headerIconType,
			htmlMessage: htmlMessage.length ? htmlMessage: null,
			message: this.message,
			rememberText: this.rememberText,
			secondaryActionText: this.secondaryActionText,
			themed: this.themed,
			title: this.title
		};

		return this.Dialog.getDialog<ConfirmationDialogAngularComponent>('ConfirmationDialog')
			.open({
				initialState: {
					params
				}
			})
			.content;
	}
}
