import { Component, Input } from '@angular/core';

@Component({
	selector: 'sso-error-display',
	template: `
	<div class="system-login-shell">
		<div class="table-display">
			<div class="table-row">
				<div class="table-cell system-login-nav">
					<div class="main-nav">
						<div class="navbar">
							<div class="table-cell brand-cell">
									<branding-logo></branding-logo>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell table-cell-centered-form">
					<div class="system-login col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-xl-4 col-xl-offset-4">
						<div class="system-form">
							<h1>{{ errorMessageKeys[0] | translate }}</h1>

						<div class="system-msg">
							{{ errorMessageKeys[1] | translate }}
						</div>
					</div>
				</div>
				</div>
			</div>
		</div>
	</div>
	`
})
export class SsoErrorDisplayComponent {
	@Input() public errorMessageKeys: string[];
}
