import { LOCALE_ID, Provider } from '@angular/core';

import { getLocale } from './LocaleContext';

//Design borrowed from: https://github.com/armanozak/angular-dynamic-locale
//Allows changing the LOCALE_ID dynamically
export class LocaleId extends String {
	public toString(): string {
		return getLocale().bcp47Locale;
	}

	public valueOf(): string {
		return getLocale().bcp47Locale;
	}
}

export const LocaleProvider: Provider = {
	provide: LOCALE_ID,
	useClass: LocaleId
};
