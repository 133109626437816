<div [attr.aria-label]="placeholder" ngForm="internalForm" role="group" [ngClass]="styles.form">

	<input #textInputField autocomplete="off" name="input" type="text" [attr.aria-label]="'UI_SearchInput_InputField' | translate" [disabled]="isDisabled" [ngClass]="styles.textInput" [placeholder]="placeholder" [(ngModel)]="_internalValue" (ngModelChange)="onChangeInternal()" (focus)="onFocusInternal()" vbUiTextInput>

	<button aria-hidden="true" tabindex="-1" type="submit" [ngClass]="[
			styles.searchButton,
			isSearchSurfaced ? '' : styles.searchButtonHidden
		]" (click)="onSubmitInternal()">
		<span class="glyphicons search"></span>
	</button> <!--For submit to work in some browsers (IE), the button must be displayed. Opacity used instead.-->

	<button type="button" [attr.aria-label]="'UI_SearchInput_Clear' | translate" [disabled]="isDisabled" [hidden]="!isRemoveSurfaced" [ngClass]="styles.clearButton" (click)="onClearInternal()">
		<span class="glyphicons remove_2"></span>
	</button>

</div>