import { Component } from '@angular/core';

import type { ICellRendererAngularComp } from '@ag-grid-community/angular';
import type { ICellRendererParams } from '@ag-grid-community/core';

export interface IIconCellRendererParams {
	getIconClass(cellValue: any, cellFormattedValue?: any): string;
	showFormattedValue?: boolean;
}

interface IIconCellRendererMergedParams extends ICellRendererParams, IIconCellRendererParams {}

@Component({
	selector: 'icon-cell-renderer',
	templateUrl: './IconCellRenderer.Component.html'
})
export class IconCellRendererComponent implements ICellRendererAngularComp {
	public iconClass: string;
	public showFormattedValue: boolean;
	public valueFormatted: any;

	public agInit(params: IIconCellRendererMergedParams): void {
		this.updateCell(params);
	}

	public refresh(params: IIconCellRendererMergedParams): boolean {
		this.updateCell(params);

		return true;
	}

	private updateCell(params: IIconCellRendererMergedParams): void {
		this.iconClass = params.getIconClass(params.value, params.valueFormatted);
		this.showFormattedValue = !!params.showFormattedValue;
		this.valueFormatted = params.valueFormatted;
	}
}
