<section *ngIf="!!selectedColor">
	<section [name]="'colorSelectionForm' + index" [ngModelGroup]="'colorSelector' + index" #colorSelectionForm="ngModelGroup">
		<div class="position-relative margin-top-10 margin-bottom-10">
			<div class="combined-toolbar vertical-align-top" role="group" [attr.aria-label]="accessibilityLabel">
				<div class="table-cell">
					<!-- ngModel not behaving well with input type color.
						it's emitting unneccessary warning.
					-->
					<input type="color" role="button" [attr.aria-label]="'Color_Selector_Picker' | translate" [ngClass]="styles.colorSelector" [name]="'selectColor' + index" [value]="selectedColor" (input)="onColorSelectorChange($event)">
				</div>
				<div class="table-cell table-cell-fill">
					<div class="input-group">
						<input type="text" [name]="'bgColorInput' + index" [attr.aria-label]="'Color_Selector_Input' | translate" class="color-picker-input form-control" required [(ngModel)]="value" #sColor="ngModel" (ngModelChange)="onInputColorChange()" vbColorHex>
						<span class="input-group-btn">
							<button class="btn btn-white" type="button" [ngClass]="styles.resetBtn" (click)="setDefaultColor()">
								{{ 'ResetDefault' | translate }}
							</button>
						</span>
					</div>
					<div *ngIf="sColor.invalid" vbUiErrorMessage role="alert">
						<div *ngIf="sColor.errors.vbColor">
							{{ 'Branding_InvalidColorFormat' | translate }}
						</div>
						<div *ngIf="sColor.errors.required">
							{{ 'ThisFieldIsRequired' | translate }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</section>
