import { AfterViewInit, Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * wrapper for resizeObserver
 */
@Directive({
	exportAs: 'vbOnResize',
	selector: '[vbOnResize]'
})
export class VbOnResizeDirective implements OnInit, OnDestroy, AfterViewInit {
	@Output() public vbOnResize = new EventEmitter<DOMRect>();

	private resizeObserver: ResizeObserver;

	constructor(
		private el: ElementRef,
		private ngZone: NgZone
	) {}

	public ngOnInit(): void {
		this.resizeObserver = new ResizeObserver(entries => {
			this.vbOnResize.emit(entries[0].contentRect as DOMRect);
		});
	}

	public ngAfterViewInit(): void {
		this.ngZone.runOutsideAngular(() => this.resizeObserver.observe(this.el.nativeElement));
	}

	public ngOnDestroy(): void {
		this.resizeObserver.disconnect();
	}

}
