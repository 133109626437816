<!--Initializing-->
<initializing-spinner *ngIf="isInitializing">
</initializing-spinner>

<!--Connecting-->
<connecting-spinner *ngIf="isConnecting && video.videoConference.sipAddress" [destination]="video.videoConference.sipAddress">
</connecting-spinner>

<connecting-spinner *ngIf="isConnecting && video.videoConference.microsoftTeamsMeetingUrl" [destination]="'Microsoft_Teams' | translate">
	<div [ngClass]="styles.msTeamsConnecting">
		{{ 'Microsoft_Teams_ConnectingToMessage' | translate }}
	</div>
</connecting-spinner>

<camera-spinner *ngIf="isConnecting && video.sourceType === VideoSourceType.WEBEX_LIVE_STREAM" [msg]="'WaitingForStream' | translate">
</camera-spinner>

<!--Connection Failed-->
<div *ngIf="isConnectionFailed" class="height-full" layout="column" flex-align="center" flex-justify="center">

	<div [ngClass]="styles.errorMsg">
		<span class="glyphicons warning_sign"></span>
		{{ 'Media_Recording_Connection_Failed' | translate }}

		<div [ngClass]="styles.errorMsgReason">
			<span *ngIf="invalidSipAddress">
				{{ 'Media_Recording_Invalid_Video_Address' | translate }}
			</span>

			<span *ngIf="recordingHoursNotAvailable">
				{{ 'Media_Recording_HoursNotAvailable' | translate }}
			</span>

			<span *ngIf="incomingMediaUnavailable">
				{{ 'Media_Recording_Failed_Media_Unavailable' | translate }}
			</span>
		</div>
	</div>

	<button type="button" *ngIf="!recordingHoursNotAvailable && isInteractiveRecordingDisplay" (click)="retryRecording()" vbUiBtnDanger>
		<span class="glyphicons repeat"></span>
		{{ 'Media_Recording_Retry' | translate }}
	</button>
</div>

<!--Recording (read-only)-->
<camera-spinner *ngIf="isRecording && !isInteractiveRecordingDisplay" [msg]="'Media_Recording_InProgress' | translate">
</camera-spinner>

<!--Recording (interactive) -->
<recording-timer *ngIf="isRecording && isInteractiveRecordingDisplay" class="height-full" [ngClass]="styles.timer" [autoStart]="true" [video]="video" (onStop)="stopRecording()">
</recording-timer>
