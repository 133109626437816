import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';

import { isIe } from 'rev-shared/util/UserAgentUtil';

import styles from './CollapsableSection.module.less';

@Component({
	selector: 'collapsable-section',
	templateUrl: './CollapsableSection.Component.html',
	host: {
		'[class]': 'styles.collapsableSection'
	}
})
export class CollapsableSectionComponent implements OnInit {
	@Input() public startClosed: boolean;
	@Input() public headerTitle: string;
	@Input() public invalidSection: boolean;
	@Output() public toggleCollapsed = new EventEmitter<boolean>();

	public readonly styles = styles;
	public readonly isIe = isIe(); //ngx-collapseModule animation does not work well in ie11 in current version.

	public expanded: boolean;

	public ngOnInit(): void {
		this.expanded = !this.startClosed;
	}

	public get rootClasses(): any {
		return [
			styles.collapsableSectionWrap,
			this.expanded ? styles.collapsed : undefined
		];
	}

	public toggleExpanded(): void {
		this.expanded = !this.expanded;
	}
}
