import { Injectable } from '@angular/core';
import { StateService, UrlService } from '@uirouter/angular';

import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

interface IErrorDetail {
	data?: any; // angularjs
	error?: any; // angular
	status: number;
}

@Injectable({
	providedIn: 'root'
})
export class SecurityRedirectHelperService {
	constructor(
		private $state: StateService,
		private LoginRedirectService: LoginRedirectService,
		private UserContext: UserContextService,
		private urlService: UrlService
	) {}

	public onError(errorDetail: IErrorDetail, fromStateName: string): void {
		if (!errorDetail) {
			return;
		}

		const errorData: any = errorDetail.data || // angularjs
			errorDetail.error; // angular
		const errorStatus: number = errorDetail.status;
		const errorReason: any = errorData?.reason;
		const noLoginRedirect: boolean = errorData?.noLoginRedirect;

		switch (errorStatus) {
			case 401: // unauthorized
				if (this.UserContext.isRegisteredGuest() && !noLoginRedirect) {
					this.UserContext.logOutUser();
					this.redirectToLogin();
				} else if (!this.UserContext.isUserAuthenticated() && !noLoginRedirect) {
					this.redirectToLogin();
				} else {
					const isFromWithinPortal: boolean = fromStateName.startsWith('portal');

					this.$state.go(isFromWithinPortal ? 'portal.401' : '401', { reason: errorReason }, { location: false });
				}
				break;

			case 404: // not found
				this.$state.go('portal.404');
				break;
		}
	}

	private redirectToLogin(): void {
		const fwdUrl: string = this.urlService.url();

		window.setTimeout(() => this.LoginRedirectService.redirectToLogin(fwdUrl), 100);
	}
}
