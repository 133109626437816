import { Directive, Input, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { toggleError } from './validationUtil';

@Directive({
	selector: '[vbMaxLength]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => VbMaxLengthDirective),
			multi: true
		}
	]
})
export class VbMaxLengthDirective implements Validator, OnChanges {
	@Input() public vbMaxLength: any;

	private control: AbstractControl;

	public ngOnChanges(changes: SimpleChanges): void {
		if (this.control) {
			const errors = this.getErrors(this.control);
			toggleError(this.control, 'vbMaxLength', errors?.vbMaxLength);
		}
	}

	public validate(control: AbstractControl): ValidationErrors {
		this.control = control;
		return this.getErrors(control);
	}

	private getErrors(control: AbstractControl): any {
		if (this.vbMaxLength > 0 && control?.value?.length > this.vbMaxLength) {
			return { vbMaxLength: true };
		}
	}
}
