import { NgModule } from '@angular/core';

import { VbUiErrorMessageAngularDirective } from './VbUiErrorMessageAngular.Directive';

@NgModule({
	declarations: [
		VbUiErrorMessageAngularDirective
	],
	exports: [
		VbUiErrorMessageAngularDirective
	]
})
export class ValidationAngularModule { }
