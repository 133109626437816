import { NgModule } from '@angular/core';

import { ToolbarComponent } from './Toolbar.Component';

const exports = [
	ToolbarComponent
];

@NgModule({
	declarations: exports,
	exports
})
export class ToolbarNgxModule {
}
