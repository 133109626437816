export const captureSetupWidth = 920;
export const captureSetupHeight = 695;
export const smallRecordingWidth = 220;
export const smallRecordingHeight = 235;
export const largeRecordingWidth = 860;
export const largeRecordingHeight = 495;

export const audioQueryConstraints = {
	autoGainControl: true,
	echoCancellation: true,
	noiseSuppression: true,
	sampleRate: 48000
};

export const videoQueryConstraints = {
	frameRate: 30,
	height: 720,
	width: 1280
};
