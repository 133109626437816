import { Component, OnInit, OnDestroy, forwardRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { ISparkRoom, SparkService } from 'rev-portal/media/spark/Spark.Service';

import { BaseControlValueAccessor } from 'rev-shared/util/BaseControlValueAccessor';
import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { VbConfirmationDialogComponent } from 'rev-shared/ui/dialog/VbConfirmationDialogAngular.Component';
import { retryUntilSuccess } from 'rev-shared/util/PromiseUtil';

import styles from './webex-teams.module.less';

interface ITeam {
	name: string;
	roomId: string;
	sipAddress: string;
}

@Component({
	selector: 'webex-teams',
	templateUrl: './WebexTeams.Component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => WebexTeamsComponent),
		multi: true
	}]
})
export class WebexTeamsComponent extends BaseControlValueAccessor<ITeam> implements OnInit, OnDestroy {
	@ViewChild('sipAddressErrorDialog', { static: true }) public sipAddressErrorDialog: VbConfirmationDialogComponent;

	public get status(): { [p: string]: boolean } {
		return this._status;
	}

	public set status(value: { [p: string]: boolean }) {
		this._status = value;
	}

	public readonly styles = styles;

	public rooms: ISparkRoom[];
	public sparkAuthenticationRequired: boolean;
	private sparkOnAuthenticateUnsubscribe: IUnsubscribe;
	private _status: { [propKey: string]: boolean };
	public teamName: string;
	public readonly typeaheadString: string = 'title';

	constructor(
		private SparkService: SparkService,
		private Dialog: DialogService,
		private TranslateService: TranslateService,
	) {
		super();
	}

	public ngOnInit(): void {
		this.rooms = [];
		this.loadRooms();
	}

	public ngOnDestroy(): void {
		if (this.sparkOnAuthenticateUnsubscribe) {
			this.sparkOnAuthenticateUnsubscribe();
		}
	}

	public loadRooms(isRetry: boolean = false): Promise<any> {
		this._status = { processing: true };
		this.sparkAuthenticationRequired = false;

		return (isRetry ?
			retryUntilSuccess(() => Promise.resolve(this.SparkService.getRooms(true))) :
			Promise.resolve(this.SparkService.getRooms(true))
		)
			.then(rooms => {
				this._status = { active: true };
				this.rooms = rooms.sort((a, b) => {
					const titleA = a.title;
					const titleB = b.title;
					return titleA < titleB ? -1 : 1;
				});
			})
			.catch(err => {
				if (err.authenticationRequired && !isRetry) {
					this._status = { active: true };
					this.sparkAuthenticationRequired = true;

					this.sparkOnAuthenticateUnsubscribe = this.SparkService.onAuthenticate(() => this.loadRooms(true));
				} else {
					this._status = {
						error: true,
						loadRoomsError: true
					};
				}
			});
	}

	public selectTeam(event): void {
		const item = event.item;
		this.SparkService.getWebexRoomDetails(item.id)
			.then(room => {
				this.teamName = item.title;
				this.updateModelValue({
					roomId: room.roomId,
					name: item.title, //cisco api not sending room name now.
					sipAddress: room.sipAddress
				});
			})
			.catch(() => this.sipAddressErrorDialog.open());
	}

}
