import {
	Directive
} from '@angular/core';

import styles from './vb-ui-error-message.module.less';
@Directive({
	selector: '[vbUiErrorMessage]',
	host: {
		'[class]': 'styles.errorMessage'
	}
})
export class VbUiErrorMessageAngularDirective {
	public readonly styles = styles;
}
