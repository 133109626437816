import { Inject, Injectable, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SignalRHubsConnection, SignalRHubsConnectionState } from 'rev-shared/push/SignalRHubsConnection';
import { SignalRHubsConnectionToken } from 'rev-shared/push/SignalRHubsConnectionToken';

import { SessionService } from './Session.Service';
import { UserContextService } from './UserContext.Service';

/**
 * Keeps the session alive if http requests are going through.
 */
@Injectable()
class SessionExtendHttpInterceptor implements HttpInterceptor {
	private isReady: boolean = false;

	constructor(
		private Session: SessionService,
		private UserContext: UserContextService,
		@Inject(SignalRHubsConnectionToken) private signalRHubsConnection: SignalRHubsConnection
	) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
		this.tryExtendSessionTimeout();

		return next.handle(req);
	}

	private tryExtendSessionTimeout(): void {
		if (!this.isReady) {
			if (this.signalRHubsConnection.getConnectionStatus() !== SignalRHubsConnectionState.Connected) {
				return;
			}

			this.isReady = true;
		}

		if (this.UserContext.isSessionStable()) {
			this.Session.tryExtendTimeout();
		}
	}
}

export const SessionExtendHttpInterceptorProvider: Provider = {
	provide: HTTP_INTERCEPTORS,
	useClass: SessionExtendHttpInterceptor,
	multi: true
};
