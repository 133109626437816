import {
	Directive,
	HostListener
} from '@angular/core';

/***
 * Example:  <input type="text" ng-model="..." vb-disable-submit
 *
 * This will stop the input from submitting the whole form. Added because some components use nested forms that should not interfere with outer form
 ***/
@Directive({
	selector: '[vbDisableSubmit]'
})
export class VbDisableSubmitAngularDirective {
	@HostListener('keydown.enter', ['$event'])
	private onKeyDown(event: KeyboardEvent): void {
		event.preventDefault();
	}
}
