import {
	Attribute,
	Directive,
	ElementRef,
	HostBinding,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	forwardRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { StateService, TransitionService } from '@uirouter/angular';

import styles from './vb-ui-radio-btn-group.module.less';
import secondaryBtnStyles from './vb-btn-secondary.module.less';
/**
 * <div vbUiRadioBtnGroup>
			<button
				type="button"
				[(ngModel)]="buttonValue"
				vbUiRadioBtn="Public">
				Public
			</button>
			<button
				type="button"
				[(ngModel)]="buttonValue"
				vbUiRadioBtn="AllUser">
				All User
			</button>
			<button
				type="button"
				[(ngModel)]="buttonValue"
				vbUiRadioBtn="Private">
				Private
			</button>
 * </div>
 */

@Directive({
	selector: '[vbUiRadioBtn]',
	host: {
		role: 'radio',
		'[class]': 'styles.vbSecondaryBtn'
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VbUiRadioBtnDirective),
			multi: true
		}
	]
})
export class VbUiRadioBtnDirective implements ControlValueAccessor, OnInit, OnDestroy {
	@Input() public activeClass: string;
	@Input() public vbUiRadioBtn: any; // ngModel value this button corresponds with
	@Input() public notThemed: boolean;

	public readonly styles = { ...styles, ...secondaryBtnStyles };

	private unsubscribeTransitionListener: any;
	private onChange: (value: any) => void;
	private onTouched: () => void;
	private modelValue;

	@HostBinding('attr.aria-checked')
	@HostBinding('class.vbUiRadioBtnActive')
	public isActive: boolean;

	@HostBinding('class.theme-accent')
	public get themedClass(): boolean {
		return this.isActive && !this.notThemed;
	}

	constructor(
		@Attribute('uiSref') private uiSref: string,
		private $state: StateService,
		private $transitions: TransitionService,
		private element: ElementRef<HTMLElement>
	) {}

	public ngOnInit(): void {
		if (this.uiSref) {
			this.unsubscribeTransitionListener = this.$transitions.onSuccess(null, () => this.onStateTransition());
			this.onStateTransition();
		}
	}

	public ngOnDestroy(): void {
		this.unsubscribeTransitionListener?.();
	}

	public writeValue(value: any): void {
		this.modelValue = value;
		this.isActive = this.isNgModelActive();
		this.applyProvidedActiveClass();
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	private isNgModelActive(): boolean {
		return this.modelValue === this.vbUiRadioBtn;
	}

	private isUiSrefActiveEq(): boolean {
		return this.$state.is(this.uiSref);
	}

	@HostListener('click')
	public onClick(): void {
		this.writeValue(this.vbUiRadioBtn);
		this.onChange?.(this.vbUiRadioBtn);
		this.onTouched?.();
	}

	private onStateTransition(): void {
		this.isActive = this.isUiSrefActiveEq();
		this.applyProvidedActiveClass();
	}

	private applyProvidedActiveClass(): void {
		if (this.activeClass) {
			this.element.nativeElement.classList.toggle(this.activeClass, this.isActive);
		}
	}
}
