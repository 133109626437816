import {
	Directive,
	Input,
	forwardRef
} from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
	selector: '[vbColorHex]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => VbColorHexDirective),
		multi: true
	}]

})
export class VbColorHexDirective {
	@Input() public vbEmailActive: boolean;
	private readonly colorRegExp = /^#([0-9a-f]{3}){1,2}$/i;

	public validate(control: AbstractControl): ValidationErrors {
		if (!control?.value) {
			return null;
		}

		return control.value.match(this.colorRegExp) ? null : { vbColor: true };
	}
}
