import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { ITemplateMenuService, ITemplate } from 'rev-shared/ui/templates/TemplateMenu.Component';
import { ITemplateInfo } from './WebcastTemplates.Contract';

@Injectable({
	providedIn: 'root'
})
export class WebcastTemplatesService implements ITemplateMenuService {

	constructor(
		private http: HttpClient,
		private DateParsers: DateParsersService,
		private UserContext: UserContextService
	) {}

	public getTemplates(accountId?: string): Promise<ITemplate[]> {
		accountId = accountId || this.UserContext.getAccount().id;
		return lastValueFrom<any>(this.http.get(`/scheduled-events/accounts/${accountId}/templates`))
			.then(result => result.templates);
	}

	public getDefaultTemplate(): Promise<ITemplateInfo> {
		return lastValueFrom<any>(this.http.get('/scheduled-events/templates/default'))
			.then(template => {
				if (template) {
					return this.readTemplateDetail(template);
				}
			});
	}

	public getTemplate(templateId: string): Promise<ITemplateInfo> {
		return lastValueFrom<any>(this.http.get(`/scheduled-events/templates/${templateId}`))
			.then(template => this.readTemplateDetail(template));
	}

	private readTemplateDetail(template: ITemplateInfo): ITemplateInfo {
		const data = template.metadata as any;
		if(!data) {
			return template;
		}

		if (data.preProduction) {
			data.preProduction.durationMs = this.DateParsers.parseTimespan(data.preProduction.duration);
		}

		if (data.vodInfo) {
			data.vodInfo.durationMs = this.DateParsers.parseTimespan(data.vodInfo.duration);
		}

		return template;
	}
}
