<vb-title [title]="'Login_UserAgreement' | translate"></vb-title>

<vb-auth-layout>

	<div [hidden]="!status.processing">
		<vb-loading-spinner [msg]="'Loading' | translate" [size]="'large'">
		</vb-loading-spinner>
	</div>

	<div [hidden]="!status.active" [ngClass]="authLayoutStyles.authForm">

		<div [ngClass]="[authLayoutStyles.centered, authLayoutStyles.logoWrapper]">
			<branding-logo></branding-logo>
		</div>

		<div [ngClass]="authLayoutStyles.heading">
			<h2>{{ 'Login_UserAgreement' | translate }}</h2>
		</div>

		<p [ngClass]="authLayoutStyles.authCustomText" class="util-white-space-pre-wrap">{{ userAgreementMessage }}</p>

		<div class="margin-top-30">
			<button type="button" class="btn btn-primary theme-accent theme-accent-txt" [ngClass]="authLayoutStyles.authBtn" (click)="accept()">
				{{ 'Agree' | translate }}
			</button>

			<button type="button" class="btn margin-top-10 theme-header-bg theme-header-txt" [ngClass]="[authLayoutStyles.authBtn, authLayoutStyles.authCancel]" (click)="decline()">
				{{ 'Decline' | translate }}
			</button>
		</div>

	</div>

</vb-auth-layout>
