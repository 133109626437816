import { Inject, Injectable, Testability } from '@angular/core';

import moment from 'moment';

import { DATE_PARSER_FACTORY_SERVICE_TOKEN } from './DateParser.Provider';
import { SecondMs } from 'rev-shared/date/Time.Constant';
import { isDate, isNumber, isString, isUndefined } from 'rev-shared/util';

import { DateParser } from './DateParser.Contract';
import { DateParserFactory } from './DateParser.Contract';

/**
 * Parses a timespan string in ##h##m##s format to a time value in milliseconds.
 * @param  {String} urlTimespan //h,m parts are optional, s is required:  ##h##m##s, ##m##s
 * @return {Number}             The timespan value in ms.
 */
export function parseUrlTimespan(urlTimespan: string): number {
	if (!isString(urlTimespan) || !urlTimespan.match(/^((\d+)h)?((\d+)m)?((\d+(\.\d*)?)s)$/)) {
		return;
	}

	let urlTimespanMomentInput: string = urlTimespan
		.replace(/h|m/g, ':')
		.slice(0, -1); // drop the required s at the end

	if (urlTimespanMomentInput.length === 5) { // missing hours
		urlTimespanMomentInput = '00:' + urlTimespanMomentInput;
	}

	return moment.duration(urlTimespanMomentInput).asMilliseconds();
}

@Injectable({
	providedIn: 'root'
})
export class DateParsersService {
	private isoDateParser: DateParser;
	public readonly parseUrlTimespan = parseUrlTimespan;

	constructor(
		@Inject(DATE_PARSER_FACTORY_SERVICE_TOKEN) private DateParser: DateParserFactory
	) {
		this.isoDateParser = DateParser('YYYY-MM-DD');
	}

	public parseIsoDate(dateStr: string): number | Date {
		return dateStr && this.isoDateParser(dateStr);
	}

	/**
	 * Parses timespan into milliseconds.
	 * @param {string} timespan of format: [-][d.][hh:]mm:ss[.fffff]
	 * @param {boolean} includeSeconds. If true, use the following format: [-][d.]hh:mm
	 * @return {number} Duration in milliseconds.
	 */
	public readonly parseTimespan = parseTimespan;
	public readonly parseTimespanToSecs = parseTimespanToSecs;

	/**
	 * Utility function to parse an ISO-8601 formatted date/time string
	 * Input: string. Example: 2013-03-31T04:11:08.860Z
	 * Returns: a date object
	 */
	public parseUTCDate(inputStr: string | Date): Date {
		if (!inputStr) {
			return null;
		} else if (isDate(inputStr)) {
			return inputStr;
		}

		const parsedMoment = moment(inputStr, moment.ISO_8601);

		return parsedMoment.isValid() ?
			parsedMoment.toDate() :
			null;
	}
}

/**
 * Parses timespan into milliseconds.
 * @param {string} timespan of format: [-][d.][hh:]mm:ss[.fffff]
 * @param {boolean} includeSeconds. If false, use the following format: [-][d.]hh:mm
 * @return {number} Duration in milliseconds.
 */
export function parseTimespan(timespan: number | string, includeSeconds = true): number {
	if (isNumber(timespan)) {
		return timespan;
	}
	const rx = includeSeconds ?
		/^(-?(\d+\.)?\d+:)?\d+:\d{1,2}(\.\d+)?$/ :
		/^-?(\d+\.)?\d{1,2}:\d\d$/;

	const parts = rx.exec(timespan);

	if (!parts) {
		return;
	}

	// moment assumes xx:yy format is hh:mm.
	if (includeSeconds && isUndefined(parts[1])) {
		timespan = '00:' + timespan;
	}

	return moment.duration(timespan).asMilliseconds();
}

export function parseTimespanToSecs(timespan: number | string, includeSeconds = true): number {
	return parseTimespan(timespan, includeSeconds) / SecondMs;
}
