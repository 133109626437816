import { Component } from '@angular/core';


@Component({
	selector: 'portal-404',
	template: `
		<h1 class="centered-text-block-content">{{ '404_Message' | translate }}</h1>
	`
})
export class Portal404Component {
}
