import { NgbDate } from '../ngb-date';
import { NgbDateStruct } from '../ngb-date-struct';

const PARTS_PER_HOUR = 1080;
const PARTS_PER_DAY = 24 * PARTS_PER_HOUR;
const PARTS_FRACTIONAL_MONTH = 12 * PARTS_PER_HOUR + 793;
const PARTS_PER_MONTH = 29 * PARTS_PER_DAY + PARTS_FRACTIONAL_MONTH;
const BAHARAD = 11 * PARTS_PER_HOUR + 204;
const HEBREW_DAY_ON_JAN_1_1970 = 2092591;
const GREGORIAN_EPOCH = 1721425.5;

function isGregorianLeapYear(year: number): boolean {
	return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

function numberOfFirstDayInYear(year: number): number {
	let monthsBeforeYear = Math.floor((235 * year - 234) / 19);
	let fractionalMonthsBeforeYear = monthsBeforeYear * PARTS_FRACTIONAL_MONTH + BAHARAD;
	let dayNumber = monthsBeforeYear * 29 + Math.floor(fractionalMonthsBeforeYear / PARTS_PER_DAY);
	let timeOfDay = fractionalMonthsBeforeYear % PARTS_PER_DAY;

	let dayOfWeek = dayNumber % 7; // 0 == Monday

	if (dayOfWeek === 2 || dayOfWeek === 4 || dayOfWeek === 6) {
		dayNumber++;
		dayOfWeek = dayNumber % 7;
	}
	if (dayOfWeek === 1 && timeOfDay > 15 * PARTS_PER_HOUR + 204 && !isHebrewLeapYear(year)) {
		dayNumber += 2;
	} else if (dayOfWeek === 0 && timeOfDay > 21 * PARTS_PER_HOUR + 589 && isHebrewLeapYear(year - 1)) {
		dayNumber++;
	}
	return dayNumber;
}

function getDaysInGregorianMonth(month: number, year: number): number {
	let days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
	if (isGregorianLeapYear(year)) {
		days[1]++;
	}
	return days[month - 1];
}

function getHebrewMonths(year: number): number {
	return isHebrewLeapYear(year) ? 13 : 12;
}

/**
 * Returns the number of days in a specific Hebrew year.
 * `year` is any Hebrew year.
 */
function getDaysInHebrewYear(year: number): number {
	return numberOfFirstDayInYear(year + 1) - numberOfFirstDayInYear(year);
}

export function isHebrewLeapYear(year?: number): boolean {
	if (year != null) {
		let b = (year * 12 + 17) % 19;
		return b >= (b < 0 ? -7 : 12);
	}
	return false;
}

/**
 * Returns the number of days in a specific Hebrew month.
 * `month` is 1 for Nisan, 2 for Iyar etc. Note: Hebrew leap year contains 13 months.
 * `year` is any Hebrew year.
 */
export function getDaysInHebrewMonth(month: number, year: number): number {
	let yearLength = numberOfFirstDayInYear(year + 1) - numberOfFirstDayInYear(year);
	let yearType = (yearLength <= 380 ? yearLength : yearLength - 30) - 353;
	let leapYear = isHebrewLeapYear(year);
	let daysInMonth = leapYear
		? [30, 29, 29, 29, 30, 30, 29, 30, 29, 30, 29, 30, 29]
		: [30, 29, 29, 29, 30, 29, 30, 29, 30, 29, 30, 29];
	if (yearType > 0) {
		daysInMonth[2]++; // Kislev gets an extra day in normal or complete years.
	}
	if (yearType > 1) {
		daysInMonth[1]++; // Heshvan gets an extra day in complete years only.
	}
	return daysInMonth[month - 1];
}

export function getDayNumberInHebrewYear(date: NgbDate): number {
	let numberOfDay = 0;
	for (let i = 1; i < date.month; i++) {
		numberOfDay += getDaysInHebrewMonth(i, date.year);
	}
	return numberOfDay + date.day;
}

export function setHebrewMonth(date: NgbDate, val: number): NgbDate {
	let after = val >= 0;
	if (!after) {
		val = -val;
	}
	while (val > 0) {
		if (after) {
			if (val > getHebrewMonths(date.year) - date.month) {
				val -= getHebrewMonths(date.year) - date.month + 1;
				date.year++;
				date.month = 1;
			} else {
				date.month += val;
				val = 0;
			}
		} else {
			if (val >= date.month) {
				date.year--;
				val -= date.month;
				date.month = getHebrewMonths(date.year);
			} else {
				date.month -= val;
				val = 0;
			}
		}
	}
	return date;
}

export function setHebrewDay(date: NgbDate, val: number): NgbDate {
	let after = val >= 0;
	if (!after) {
		val = -val;
	}
	while (val > 0) {
		if (after) {
			if (val > getDaysInHebrewYear(date.year) - getDayNumberInHebrewYear(date)) {
				val -= getDaysInHebrewYear(date.year) - getDayNumberInHebrewYear(date) + 1;
				date.year++;
				date.month = 1;
				date.day = 1;
			} else if (val > getDaysInHebrewMonth(date.month, date.year) - date.day) {
				val -= getDaysInHebrewMonth(date.month, date.year) - date.day + 1;
				date.month++;
				date.day = 1;
			} else {
				date.day += val;
				val = 0;
			}
		} else {
			if (val >= date.day) {
				val -= date.day;
				date.month--;
				if (date.month === 0) {
					date.year--;
					date.month = getHebrewMonths(date.year);
				}
				date.day = getDaysInHebrewMonth(date.month, date.year);
			} else {
				date.day -= val;
				val = 0;
			}
		}
	}
	return date;
}

/**
 * Returns the equivalent Hebrew date value for a give input Gregorian date.
 * `gdate` is a JS Date to be converted to Hebrew date.
 */
export function fromGregorian(gdate: Date): NgbDate {
	const date = new Date(gdate);
	const gYear = date.getFullYear(),
		gMonth = date.getMonth(),
		gDay = date.getDate();
	let julianDay =
		GREGORIAN_EPOCH -
		1 +
		365 * (gYear - 1) +
		Math.floor((gYear - 1) / 4) -
		Math.floor((gYear - 1) / 100) +
		Math.floor((gYear - 1) / 400) +
		Math.floor((367 * (gMonth + 1) - 362) / 12 + (gMonth + 1 <= 2 ? 0 : isGregorianLeapYear(gYear) ? -1 : -2) + gDay);
	julianDay = Math.floor(julianDay + 0.5);
	let daysSinceHebEpoch = julianDay - 347997;
	let monthsSinceHebEpoch = Math.floor((daysSinceHebEpoch * PARTS_PER_DAY) / PARTS_PER_MONTH);
	let hYear = Math.floor((monthsSinceHebEpoch * 19 + 234) / 235) + 1;
	let firstDayOfThisYear = numberOfFirstDayInYear(hYear);
	let dayOfYear = daysSinceHebEpoch - firstDayOfThisYear;
	while (dayOfYear < 1) {
		hYear--;
		firstDayOfThisYear = numberOfFirstDayInYear(hYear);
		dayOfYear = daysSinceHebEpoch - firstDayOfThisYear;
	}
	let hMonth = 1;
	let hDay = dayOfYear;
	while (hDay > getDaysInHebrewMonth(hMonth, hYear)) {
		hDay -= getDaysInHebrewMonth(hMonth, hYear);
		hMonth++;
	}
	return new NgbDate(hYear, hMonth, hDay);
}

/**
 * Returns the equivalent JS date value for a given Hebrew date.
 * `hebrewDate` is an Hebrew date to be converted to Gregorian.
 */
export function toGregorian(hebrewDate: NgbDateStruct | NgbDate): Date {
	const hYear = hebrewDate.year;
	const hMonth = hebrewDate.month;
	const hDay = hebrewDate.day;
	let days = numberOfFirstDayInYear(hYear);
	for (let i = 1; i < hMonth; i++) {
		days += getDaysInHebrewMonth(i, hYear);
	}
	days += hDay;
	let diffDays = days - HEBREW_DAY_ON_JAN_1_1970;
	let after = diffDays >= 0;
	if (!after) {
		diffDays = -diffDays;
	}
	let gYear = 1970;
	let gMonth = 1;
	let gDay = 1;
	while (diffDays > 0) {
		if (after) {
			if (diffDays >= (isGregorianLeapYear(gYear) ? 366 : 365)) {
				diffDays -= isGregorianLeapYear(gYear) ? 366 : 365;
				gYear++;
			} else if (diffDays >= getDaysInGregorianMonth(gMonth, gYear)) {
				diffDays -= getDaysInGregorianMonth(gMonth, gYear);
				gMonth++;
			} else {
				gDay += diffDays;
				diffDays = 0;
			}
		} else {
			if (diffDays >= (isGregorianLeapYear(gYear - 1) ? 366 : 365)) {
				diffDays -= isGregorianLeapYear(gYear - 1) ? 366 : 365;
				gYear--;
			} else {
				if (gMonth > 1) {
					gMonth--;
				} else {
					gMonth = 12;
					gYear--;
				}
				if (diffDays >= getDaysInGregorianMonth(gMonth, gYear)) {
					diffDays -= getDaysInGregorianMonth(gMonth, gYear);
				} else {
					gDay = getDaysInGregorianMonth(gMonth, gYear) - diffDays + 1;
					diffDays = 0;
				}
			}
		}
	}
	return new Date(gYear, gMonth - 1, gDay);
}

export function hebrewNumerals(numerals: number): string {
	if (!numerals) {
		return '';
	}
	const hArray0_9 = ['', '\u05d0', '\u05d1', '\u05d2', '\u05d3', '\u05d4', '\u05d5', '\u05d6', '\u05d7', '\u05d8'];
	const hArray10_19 = [
		'\u05d9',
		'\u05d9\u05d0',
		'\u05d9\u05d1',
		'\u05d9\u05d2',
		'\u05d9\u05d3',
		'\u05d8\u05d5',
		'\u05d8\u05d6',
		'\u05d9\u05d6',
		'\u05d9\u05d7',
		'\u05d9\u05d8',
	];
	const hArray20_90 = ['', '', '\u05db', '\u05dc', '\u05de', '\u05e0', '\u05e1', '\u05e2', '\u05e4', '\u05e6'];
	const hArray100_900 = [
		'',
		'\u05e7',
		'\u05e8',
		'\u05e9',
		'\u05ea',
		'\u05ea\u05e7',
		'\u05ea\u05e8',
		'\u05ea\u05e9',
		'\u05ea\u05ea',
		'\u05ea\u05ea\u05e7',
	];
	const hArray1000_9000 = [
		'',
		'\u05d0',
		'\u05d1',
		'\u05d1\u05d0',
		'\u05d1\u05d1',
		'\u05d4',
		'\u05d4\u05d0',
		'\u05d4\u05d1',
		'\u05d4\u05d1\u05d0',
		'\u05d4\u05d1\u05d1',
	];
	const geresh = '\u05f3',
		gershaim = '\u05f4';
	let mem = 0;
	let result: string[] = [];
	let step = 0;
	while (numerals > 0) {
		let m = numerals % 10;
		if (step === 0) {
			mem = m;
		} else if (step === 1) {
			if (m !== 1) {
				result.unshift(hArray20_90[m], hArray0_9[mem]);
			} else {
				result.unshift(hArray10_19[mem]);
			}
		} else if (step === 2) {
			result.unshift(hArray100_900[m]);
		} else {
			if (m !== 5) {
				result.unshift(hArray1000_9000[m], geresh, ' ');
			}
			break;
		}
		numerals = Math.floor(numerals / 10);
		if (step === 0 && numerals === 0) {
			result.unshift(hArray0_9[m]);
		}
		step++;
	}
	result = result.join('').split('');
	if (result.length === 1) {
		result.push(geresh);
	} else if (result.length > 1) {
		result.splice(result.length - 1, 0, gershaim);
	}
	return result.join('');
}
