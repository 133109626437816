import {
	Directive,
} from '@angular/core';

import styles from './VbUiBtnDanger.module.less';

@Directive({
	selector: '[vbUiBtnDanger]',
	host: {
		'[class]': 'hostClass'
	}
})
export class VbUiBtnDangerDirective {
	public readonly hostClass: string = `${styles.vbUiBtnDanger}`;
}
