export enum DeviceHealthStatus {
	Alert = 'Alert',
	Caution = 'Caution',
	Error = 'Error',
	Healthy = 'Healthy',
	Initializing = 'Initializing',
	Normal = 'Normal',
	Unavailable = 'Unavailable',
	Uninitialized = 'Uninitialized',
	Updating = 'Updating',
	Warning = 'Warning'
}
