import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({ name: 'vbFormatTimespanLargeHours' })
export class VbFormatTimespanLargeHoursPipe implements PipeTransform {
	constructor(
		@Inject(LOCALE_ID) private localeId: string
	) {}

	public transform(formattedTimespan: string): string {
		const timespanPieces: string[] = formattedTimespan.split(':');

		timespanPieces[0] = formatNumber(+timespanPieces[0], this.localeId);

		return timespanPieces.join(':');
	}
}
