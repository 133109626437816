import { NgZone, ElementRef, ChangeDetectorRef } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';
import { debounce } from 'underscore';
import $ from 'jquery';


import {
	Directive,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';

import { isIeOrLegacyEdge, isSafari, getIosVersion } from 'rev-shared/util/UserAgentUtil';

const compatibleIosVersion: number = 11.0;

/**
 * vbWatchDimensions directive
 * Watches the dimensions of the container it is placed on and resizes the specified child content region for best fit while maintaining its proportions.
 *
 * OPTIONS
 *
 * vbWatchDimensions
 * Value should be an array of child elements (raw element or jQuery selection) that you wish to resize so that it maintains its proportion
 * when this element's size has changed. Leveraging teamplate variable (#) is suggested to gain references to elements. if the element is an
 * angular component then use @ViewChild read: ElementRef option to get the html reference.
 *
 * natural-ratio
 * By default this assumes the content is 16:9 aspect. To override, set the natural-ratio boolean input flag to true.
 * and this will calculate the aspect ratio at first run based on the natural dimensions of the provided element to resize.
 *
 */
@Directive({
	selector: '[vbWatchDimensions]'
})
export class VbWatchDimensionsDirective implements OnInit, OnChanges, OnDestroy {
	@Input('vbWatchDimensions') public elementsToResize: Array<HTMLElement|ElementRef>;
	@Input() public naturalRatio: boolean;
	@Input() public padding: number = 32;

	@Output() public dimensionsChanged: EventEmitter<any> = new EventEmitter();


	private debouncedRedraw: () => void;
	private $elementsToResize: JQuery<HTMLElement>;
	private ratio: number;
	private inverse: number;
	private isResizeCompatible: boolean;
	private observer: ResizeObserver;
	private elementHeightCopy: number;
	private $element: JQuery<HTMLElement>;

	constructor(
		ele: ElementRef,
		private ngZone: NgZone,
		private changeDetector: ChangeDetectorRef
	) {
		this.$element = $(ele.nativeElement);
	}

	public ngOnInit(): void {
		this.debouncedRedraw = debounce(() => {
			this.redraw();
			this.changeDetector.detectChanges();
		}, 25);
		this.observer = new ResizeObserver(() => this.debouncedRedraw());
		this.isResizeCompatible = this.checkResizeCompatible();

		if (this.naturalRatio) { //extract the ratio from the natural dimensions of the element
			this.applyNaturalRatioBehavior();
		} else { //use 16:9
			this.ratio = 0.5625;
			this.inverse = 1.77777;
		}

		//redraw on resize
		this.ngZone.runOutsideAngular(() => { // performs very poorly with zone.js
			this.observer.observe(this.$element[0]);

		});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.elementsToResize) {
			this.$elementsToResize = (this.elementsToResize || [])
				.reduce(($acc, el: any) => el ?
					$acc.add(el.nativeElement || el) :
					$acc,
				$());

			if (this.naturalRatio) {
				this.applyNaturalRatioBehavior();
			}
		}
	}

	public ngOnDestroy(): void {
		this.observer.disconnect();
	}

	private applyNaturalRatioBehavior(): void {
		const image: HTMLImageElement = this.$elementsToResize[0] as HTMLImageElement;

		if (!image) {
			return;
		}

		image.onload = () => {
			this.ratio = image.naturalHeight / image.naturalWidth;
			this.inverse = image.naturalWidth / image.naturalHeight;
			this.debouncedRedraw();
		};

		//workaround an IE bug where it doesn't reliably fire the img onload event (this triggers it)
		if (isIeOrLegacyEdge()) {
			this.$elementsToResize.attr('src', this.$elementsToResize.attr('src'));
		}
	}

	private heightByWidthConstraint(width: number): number {
		return width * this.ratio;
	}

	private redraw(): void {
		const elementHeight: number = this.$element.height();
		const elementWidth: number = this.$element.width();
		const thisRatio = elementHeight / elementWidth;

		if (!this.ratio || !isFinite(thisRatio)) {
			return;
		}

		if (thisRatio >= this.ratio) { // constrain by width
			this.resizeByWidth(elementWidth);
		} else { // constrain by height
			if(!this.isResizeCompatible && elementHeight === this.elementHeightCopy) {
				this.elementHeightCopy = this.heightByWidthConstraint(elementWidth - this.padding);
				this.resizeByWidth(elementWidth);
				return;
			}

			this.resizeByHeight(elementHeight);
		}
	}

	private resizeByHeight(elementHeight: number): void {
		const elemHeight: number = elementHeight - this.padding;
		this.elementHeightCopy = elemHeight;

		const height = this.naturalRatio ? '' : elemHeight;
		const width = elemHeight * this.inverse;

		this.$elementsToResize?.css('height', height)
			.width(width);

		this.dimensionsChanged.emit({ height, width });
	}

	private resizeByWidth(width: number): void {
		const elemWidth: number = width - this.padding;
		const elemHeight: number = this.heightByWidthConstraint(elemWidth);

		const calculatedWidth = this.naturalRatio ? '' : elemWidth;

		this.$elementsToResize?.css('width', calculatedWidth)
			.height(elemHeight);

		this.dimensionsChanged.emit({ height: elemHeight, width: calculatedWidth });
	}

	public checkResizeCompatible(): boolean {
		return !isSafari()
			|| getIosVersion() >= compatibleIosVersion;
	}
}
