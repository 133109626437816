import { Component, ElementRef, Input } from '@angular/core';

@Component({
	selector: 'vb-ui-tab',
	templateUrl: './VbUiTab.Component.html'
})
export class VbUiTabComponent {
	@Input() public heading: string;
	@Input() public headerIconClass: string;
	@Input() public isInvalid: boolean;
	@Input() public tabId: string = '' + Math.random();

	public isActive: boolean = false;

	constructor(
		protected el: ElementRef<HTMLElement>
	) {}

	public toggleCssClass(className: string, isActive: boolean): void {
		this.el.nativeElement.classList.toggle(className, isActive);
	}
}
