import { LocaleData } from '../locale/locale.class';
import { Khronos } from '../testing/chain';

//! moment.js locale configuration
//! locale : Bulgarian [bg]
//! author : Iskren Ivov Chernev : https://github.com/ichernev
//! author : Kunal Marwaha : https://github.com/marwahaha
//! author : Matt Grande : https://github.com/mattgrande
//! author : Isaac Cambron : https://github.com/icambron
//! author : Venelin Manchev : https://github.com/vmanchev

export const bgLocale: LocaleData = {
  abbr: 'bg',
  months: 'януари_февруари_март_април_май_юни_юли_август_септември_октомври_ноември_декември'.split('_'),
  monthsShort: 'янр_фев_мар_апр_май_юни_юли_авг_сеп_окт_ное_дек'.split('_'),
  weekdays: 'неделя_понеделник_вторник_сряда_четвъртък_петък_събота'.split('_'),
  weekdaysShort: 'нед_пон_вто_сря_чет_пет_съб'.split('_'),
  weekdaysMin: 'нд_пн_вт_ср_чт_пт_сб'.split('_'),
  longDateFormat: {
    LT: 'H:mm',
    LTS: 'H:mm:ss',
    L: 'D.MM.YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY H:mm',
    LLLL: 'dddd, D MMMM YYYY H:mm'
  },
  calendar: {
    sameDay: '[Днес в] LT',
    nextDay: '[Утре в] LT',
    nextWeek: 'dddd [в] LT',
    lastDay: '[Вчера в] LT',
    lastWeek: function (d: any) {

      switch (d) {
        case 0:
        case 3:
        case 6:
          return '[В изминалата] dddd [в] LT';
        case 1:
        case 2:
        case 4:
        case 5:
          return '[В изминалия] dddd [в] LT';
      }
    },
    sameElse: 'L'
  },
  relativeTime: {
    future: 'след %s',
    past: 'преди %s',
    s: 'няколко секунди',
    ss: '%d секунди',
    m: 'минута',
    mm: '%d минути',
    h: 'час',
    hh: '%d часа',
    d: 'ден',
    dd: '%d дни',
    M: 'месец',
    MM: '%d месеца',
    y: 'година',
    yy: '%d години'
  },
  dayOfMonthOrdinalParse: /\d{1,2}-(ев|ен|ти|ви|ри|ми)/,
  ordinal: function (_num: number): string {

    const number = Number(_num);

    let lastDigit = number % 10,
      last2Digits = number % 100;

    if (number === 0) {
      return number + '-ев';
    } else if (last2Digits === 0) {
      return number + '-ен';
    } else if (last2Digits > 10 && last2Digits < 20) {
      return number + '-ти';
    } else if (lastDigit === 1) {
      return number + '-ви';
    } else if (lastDigit === 2) {
      return number + '-ри';
    } else if (lastDigit === 7 || lastDigit === 8) {
      return number + '-ми';
    } else {
      return number + '-ти';
    }
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 7  // The week that contains Jan 1st is the first week of the year.
  }
};
