import { Attribute, Directive, ElementRef, HostBinding, HostListener, OnInit } from '@angular/core';

@Directive({
	selector: 'disable-password-autocomplete',
	host: {
		autocomplete: 'new-password', // makes Firefox happy, but others deliberately ignore autocomplete for passwords
		type: 'text'
	}
})
export class DisablePasswordAutoCompleteDirective implements OnInit {
	@Attribute('name') public attrName: string;
	@Attribute('type') public attrType: string;

	private isUpdateAndRefocus: boolean;

	constructor(
		private el: ElementRef<HTMLInputElement>
	) {}

	public ngOnInit(): void {
		this.isUpdateAndRefocus = false;

		if (this.attrType !== 'password') {
			return;
		}

		this.el.nativeElement.removeAttribute('name');

		this.setElementReadonly(true);
	}

	@HostBinding('attr.data-name')
	private get dataName(): string {
		return this.attrName; // replace name with data-name to disable Chrome password dropdown,
	}

	@HostListener('blur')
	private onBlur(): void {
		if (this.isUpdateAndRefocus) {
			return;
		}

		this.setElementReadonly(true);
	}

	@HostListener('input change')
	private onChange(): void {
		const type: string = this.el.nativeElement.value.length ? 'password' : 'text';

		this.el.nativeElement.setAttribute('type', type);
	}

	@HostListener('focus')
	private onFocus(): void {
		if (this.isUpdateAndRefocus) {
			this.isUpdateAndRefocus = false;
			return;
		}

		// can't update readOnly while focused on the field in IE, so have to blur and refocus
		this.isUpdateAndRefocus = true;
		this.el.nativeElement.blur();

		this.setElementReadonly(false);

		window.setTimeout(() => this.el.nativeElement.focus());
	}

	private setElementReadonly(value: boolean): void {
		this.el.nativeElement.readOnly = value;
	}
}
