import {
	Component
} from '@angular/core';

import './video-360-indicator.less';

@Component({
	selector: 'video-360-indicator',
	template: `<span aria-label="360" class="vb-icon vb-icon-360-indicator"></span>`
})
export class Video360IndicatorComponent {
}
