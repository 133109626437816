import {
	Component,
} from '@angular/core';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { ThemeService } from './Theme.Service';

import './branding-preview-banner.less';

@Component({
	selector: 'branding-preview-banner',
	templateUrl: './BrandingPreviewBanner.html'
})
export class BrandingPreviewBannerComponent {
	public accountId: string;

	constructor(
		private ThemeService: ThemeService,
		private UserContext: UserContextService
	) {
		this.accountId = UserContext.getAccount().id;
	}

	public get isPreviewActive(): boolean {
		return this.ThemeService.isPreviewActive;
	}
}
