import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { IDialogServiceOptions, DialogService } from './Dialog.Service';

@Injectable()
export class DialogRegistrationService {
	constructor(
		private BsModalService: BsModalService
	) {}

	public register(key: string, config: IDialogServiceOptions): void {
		DialogService.register(key, {
			modalService: this.BsModalService, //Save local instance of modal service, needed for lazy-loaded modals
			...config
		});
	}
}
