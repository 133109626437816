import { AfterContentInit, Input, Directive, Output, EventEmitter, ElementRef, NgZone } from '@angular/core';

import { noop } from 'rev-shared/util';

import { FileWrapper } from './FileWrapper';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { CsrfTokenHeader } from 'rev-shared/security/Tokens';

/**
	File Upload Directive.  Wrapper around jquery fileupload plugin
**/
@Directive({
	selector: '[fileUpload]',
	exportAs: 'fileUpload'
})
export class FileUploadAngularDirective implements AfterContentInit {
	@Input('singleFileUploads') public singleFileUploads: boolean;
	@Input('maxChunkSize') public chunkUploadSizeBytes: number;

	@Output() public onAdd: EventEmitter<any> = new EventEmitter();

	constructor(
		private $element: ElementRef,
		private zone: NgZone,
		private UserContext: UserContextService
	) {}

	public ngAfterContentInit(): void {
		const $el: any = $(this.$element.nativeElement);

		$el.fileupload({
			singleFileUploads: this.singleFileUploads,
			dataType: 'text',
			multipart: true,
			dropZone: this.$element.nativeElement.querySelector('.file-upload-drop-zone'),
			pasteZone: this.$element.nativeElement,
			maxChunkSize: this.chunkUploadSizeBytes,
			formData: {}, //prevents other form inputs from being sent. This can be read from an input if needed.
			add: (event, data) => {
				const file = new FileWrapper(this.UserContext, data, $el);

				this.onAdd.emit({ file });
				this.zone.run(noop);
			},
			beforeSend: xhr => {
				xhr.setRequestHeader(CsrfTokenHeader, this.UserContext.getCsrfToken());
			}
		});
	}
}
