import { Injectable } from '@angular/core';
import { DateParsersService } from 'rev-shared/date/DateParsers.Service';

@Injectable({
	providedIn: 'root'
})
export class SearchUtil {
	constructor(private DateParsers: DateParsersService) {
	}

	public readSearchResult(hits: any[]): any{
		return (hits || []).map(hit => {
			const obj = { id: hit.id, score: hit.score } as any;

			(hit.fields || []).forEach(field => {
				const name = camelCase(field.name);
				let value = field.value;

				switch(field.type){
					case 'Date':
						value = this.DateParsers.parseUTCDate(value);
						break;
					case 'TimeSpan':
						value = this.DateParsers.parseTimespan(value);
						break;
					case 'Int':
					case 'Double':
						value = +value;
						break;
					case 'Boolean':
						value = (value && value.toLowerCase() === 'true');
						break;
				}
				obj[name] = value;
			});

			if (hit.innerHits) {
				obj.innerHits = this.readSearchResult(hit.innerHits);
			}

			return obj;
		});

		function camelCase(name: string): string{
			return name.charAt(0).toLowerCase() + name.substring(1);
		}
	}
}
