import {
	Component, Input
} from '@angular/core';

import { BaseInlineEditAngularComponent } from './BaseInlineEditAngularComponent';

@Component({
	selector: 'vb-ui-inline-edit-boolean',
	templateUrl: './VbUiInlineEditBoolean.Component.html',
})
export class VbUiInlineEditBooleanComponent extends BaseInlineEditAngularComponent<boolean> {
	@Input() public trueLabel: string;
	@Input() public falseLabel: string;
}
