import { VideoStatus } from 'rev-shared/media/VideoStatus';

import { VC_STATUS } from './model/VcStatus';
import { WebcastModel } from './model/WebcastModel';
import { WebcastStatus } from './WebcastStatus';

export function updateWaitingStatusForWebexMeeting(webcast: WebcastModel): void {
	if (!webcast.isWebexLiveStreamType) {
		return;
	}
	switch(webcast.status) {
		case WebcastStatus.Starting:
		case WebcastStatus.VideoSourceStarting:
		case WebcastStatus.RecordingStarting:
			if (webcast.currentUser.isEventAdmin) {
				updateVCStatus(webcast, VC_STATUS.WaitingForStream);
			}
			break;
		case WebcastStatus.Broadcasting:
		case WebcastStatus.InProgress:
		case WebcastStatus.Recording:
			if (webcast.recordingVideoStatus === VideoStatus.STREAMING_WAITING) {
				updateVCStatus(webcast, VC_STATUS.WaitingForStream);
			}
			break;
	}
}

export function updateWaitingStatusForVodSource(webcast: WebcastModel): void {
	if (
		webcast.isVodSource &&
		!webcast.playbacks?.length
		&& webcast.webcastStatus.isStarting
	) {
		updateVCStatus(webcast, VC_STATUS.WaitingForStream);
	}
}

export function updateWaitingOrStartingStatus(webcast: WebcastModel): void {
	if (webcast.adminEnrichedPresentationProfile || webcast.isRtmpStreamType) {
		switch(webcast.status) {
			case WebcastStatus.Starting:
				updateVCStatus(webcast, VC_STATUS.Initializing);
				break;

			case WebcastStatus.WaitingForStream:
				updateVCStatus(webcast, VC_STATUS.WaitingForStream);
				break;
		}
		if (webcast.isRtmpStreamType) {
			updateWaitingStatusForRtmpStream(webcast);
		}

	} else if (webcast.isWebexLiveStreamType) {
		updateWaitingStatusForWebexMeeting(webcast);
	} else if (webcast.isVodSource) {
		updateWaitingStatusForVodSource(webcast);
	}
	else if (webcast.isWebrtcSinglePresenter) {
		updateConnectingStatus(webcast);
	}
}

function updateConnectingStatus(webcast: WebcastModel) {
	if (webcast.recordingVideoStatus === VideoStatus.RECORDING_CONNECTING) {
		webcast.vcStatus.update(VC_STATUS.Starting, true);
	}
	else if (webcast.recordingVideoStatus === VideoStatus.RECORDING_CONNECTION_FAILED) {
		webcast.vcStatus.update(VC_STATUS.Starting, false);
	}
	else if (webcast.recordingVideoStatus === VideoStatus.STREAMING_WAITING) {
		webcast.waitingForVideoSource = true;
		webcast.vcStatus.update(VC_STATUS.WaitingForStream);
	}
}

function updateVCStatus(webcast: WebcastModel, vcStatus: VC_STATUS): void {
	webcast.startingVideoSource = true;
	webcast.vcStatus.update(vcStatus);
}

function updateWaitingStatusForRtmpStream(webcast: WebcastModel): void {
	if (!webcast.isRtmpStreamType) {
		return;
	}
	switch(webcast.status) {
		case WebcastStatus.Starting:
		case WebcastStatus.VideoSourceStarting:
		case WebcastStatus.RecordingStarting:
		case WebcastStatus.Broadcasting:
		case WebcastStatus.InProgress:
		case WebcastStatus.Recording:
			if (webcast.recordingVideoStatus === VideoStatus.STREAMING_WAITING) {
				updateVCStatus(webcast, VC_STATUS.WaitingForStream);
			}
			break;
	}
}
