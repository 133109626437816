
import { Injectable } from '@angular/core';
import { TransitionService, StateDeclaration, StateObject, Rejection } from '@uirouter/angular';

const StateChangeComplete = 'StateChangeComplete';
const StateChangeStarted = 'StateChangeStarted';
const StateChangeError = 'StateChangeError';
const delay = 200;

export interface IStateChangeStatusTransition {
	toState: StateDeclaration;
	toParams: any;
	fromState: StateObject;
	fromParams: any;
}

@Injectable({
	providedIn: 'root'
})
export class StateChangeStatus {
	private activeCount: number = 0;
	private status: string;
	private stateChangingTimeout: number;
	private _transition: IStateChangeStatusTransition;

	constructor(
		private $transitions: TransitionService
	) {
		$transitions.onStart({}, trans => {
			this._transition = {
				toState: trans.to(),
				toParams: trans.params(),
				fromState: trans.$from(), //ui-router doesn't track the state correctly when location=false, but found the right value in $from
				fromParams: trans.$from().params
			};

			++this.activeCount;

			if(!this.stateChangingTimeout) {
				this.stateChangingTimeout = window.setTimeout(() => this.setStarted, delay);
			}
		});

		$transitions.onSuccess({}, () => this.setComplete());

		$transitions.onError({}, trans => {
			const error: Rejection & { status?: number } = trans.error(); // status is missing from the typedef
			this._transition = null;

			if(error && error.status === 401) {
				return;
			}

			this.setError();
		});

	}

	public get complete(): boolean {
		return this.status === StateChangeComplete;
	}

	public get changing(): boolean {
		return this.status === StateChangeStarted;
	}

	public get error(): boolean {
		return status === StateChangeError;
	}

	public get transition(): any {
		return this._transition;
	}

	public get isStateReloading() {
		return this._transition &&
			(this._transition.toState.name === this._transition.fromState.name ||
			//Media states sometimes reload by transitioning to an intermediate dummy state. Forces parent controller to not reload.
				this._transition.toState.name.includes('dummy') ||
				this._transition.fromState.name.includes('dummy'));
	}

	private setStarted(): void {
		this.setStatus(StateChangeStarted);
	}

	private setComplete(): void {
		this.clearTransition();

		this.setStatus(StateChangeComplete);
	}

	private setError(): void {
		this.clearTransition();

		this.setStatus(StateChangeError);
	}

	private clearTransition(): void {
		//there may be overlapping state change flows, so don't wipe out the transition while there's still one in progress
		if (!--this.activeCount) {
			this._transition = null;
		}
	}

	private setStatus(newStatus: string): void {
		this.status = newStatus;

		if(this.stateChangingTimeout) {
			window.clearTimeout(this.stateChangingTimeout);
			this.stateChangingTimeout = null;
		}
	}
}
