import { Component, Input } from '@angular/core';

/**
 * Used under one of our data grid components, this declares groups that column definitions may associate with.
 * These groups are for how columns are displayed in the VbUiDataGridColumnPicker.
 */
@Component({
	selector: 'vb-ui-column-picker-group',
	template: ''
})
export class VbUiDataGridColumnPickerGroup {
	@Input() public headerName: string;
	@Input() public id: string;
	@Input() public isExpanded: boolean;
	@Input() public disableColumnEntrySort: boolean = false;
}
