import { WebcastModel } from './WebcastModel';
import { VideoStatus } from 'rev-shared/media/VideoStatus';
import { objectShallowEqual } from 'rev-shared/util';

export enum VC_STATUS {
	Initializing = 'Initializing',
	Starting = 'Starting',
	Recording = 'Recording',
	WaitingForStream = 'WaitingForStream'
}

export class VcStatus {
	public status: { [key: string]: boolean };

	constructor(private webcast: WebcastModel) {
		this.reset();
	}

	public get isRecording(): boolean {
		if(this.webcast.isWebexLiveStreamType || this.webcast.isRtmpStreamType || this.webcast.isWebrtcSinglePresenter) {
			return this.webcast.webcastStatus.isEventAdminConnected && !this.waitingForStream;
		}
		return this.webcast.webcastStatus.isEventAdminConnected;
	}

	//TODO - number boolean flags are increasing and hard to maintain.
	//will refactor later.
	public update(vcStatus: VC_STATUS, isSuccessful?: boolean, readyToPlay?: boolean) {
		const prevStatus = this.status;

		if (vcStatus === VC_STATUS.Starting && isSuccessful) {
			this.status = { isConnecting: true };
		} else if (vcStatus === VC_STATUS.Starting && !isSuccessful) {
			this.status = { connectionFailed: true };
		} else if (vcStatus === VC_STATUS.Recording && !isSuccessful) {
			this.status = { recordingFailed: true };
		} else if (vcStatus === VC_STATUS.Recording && isSuccessful && !readyToPlay) {
			this.status = { isGenerating: true };
		} else if (vcStatus === VC_STATUS.Recording && isSuccessful && readyToPlay) {
			this.status = {};
		} else if (vcStatus === VC_STATUS.WaitingForStream) {
			this.status = { waitingForStream: true };
		} else if (vcStatus === VC_STATUS.Initializing) {
			this.reset();
		}

		if (!objectShallowEqual(prevStatus, this.status)) {
			this.webcast.update();
		}
	}

	public reset() {
		this.status = { isInitializing: true };
	}

	public get isInitializing(): boolean {
		return this.status.isInitializing;
	}

	public set isInitializing(val: boolean) {
		this.status = { isInitializing: val };
	}

	public get isConnecting(): boolean {
		return this.status.isConnecting;
	}

	public set isConnecting(val: boolean) {
		this.status = { isConnecting: val };
	}

	public get connectionFailed(): boolean {
		return this.status.connectionFailed;
	}

	public get recordingFailed(): boolean {
		return this.status.recordingFailed;
	}

	public get isGenerating(): boolean {
		return this.status.isGenerating;
	}

	public get waitingForStream(): boolean {
		return this.status.waitingForStream;
	}
}
