import {
	Directive,
	ElementRef,
	Input,
	OnChanges,
	SimpleChanges
} from '@angular/core';
import $ from 'jquery';

/**
 * Autoscroll an item horizontally into view within a scrollbox
 *
 * Example: <ul
 * 	vb-scroll-item-x="activeItem.index" //index of the active element
 * 	selector="'li'" //css selector that selects all items
 * 	>
 * 	<li ng-repeat=...
 */
@Directive({
	selector: '[vbScrollItemX]'
})
export class VbScrollItemXDirective implements OnChanges {
	@Input() public selector: string;
	@Input() public vbScrollItemX: number;

	private readonly PADDING: number = 20;
	private $element: JQuery<HTMLElement>;

	constructor(
		element: ElementRef
	) {
		//later - remove jquery dependecy. can be achieved using javascript.
		this.$element = $(element.nativeElement);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.vbScrollItemX) {
			this.update();
		}
	}

	private update(): void {
		const slide = this.$element.find(this.selector).eq(this.vbScrollItemX);

		if (slide[0]) {
			const currentScrollPos = this.$element.scrollLeft();
			const slideLeftScrollPos = slide.offset().left - this.$element.offset().left + currentScrollPos;
			const slideRightScrollPos = slideLeftScrollPos - this.$element.width() + slide.width();

			if (currentScrollPos > slideLeftScrollPos) {
				this.$element.scrollLeft(slideLeftScrollPos - this.PADDING);
			} else if(currentScrollPos < slideRightScrollPos){
				this.$element.scrollLeft(slideRightScrollPos + this.PADDING);
			}
		}
	}
}
