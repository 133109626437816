import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';


import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { HighlightModule } from 'rev-shared/pipes/highlight/Highlight.Module';
import { ThumbnailSheetPreviewModule } from 'rev-shared/ui/thumbnailSheetPreview/ThumbnailSheetPreview.Module';
import { VbProfilePictureModule } from 'rev-shared/ui/profile/VbProfilePicture.Module';

import { VbQuerySelectorBaseComponent } from './VbQuerySelectorBase.Component';
import { VbUiSingleUserSelectComponent } from './VbUiSingleUserSelectComponent/VbUiSingleUserSelect.Component';
import { VbUiVideoPreviewSelectComponent } from './VbUiVideoPreviewSelectComponent/VbUiVideoPreviewSelect.Component';

const declarations = [
	VbUiVideoPreviewSelectComponent,
	VbUiSingleUserSelectComponent,
	VbQuerySelectorBaseComponent
];

@NgModule({
	declarations: declarations,
	exports: declarations,
	imports: [
		ButtonsAngularModule,
		CommonModule,
		CssRulesAngularModule,
		DateAngularModule,
		FormsModule,
		HighlightModule,
		ReactiveFormsModule,
		ScrollingModule,
		ThumbnailSheetPreviewModule,
		TranslateModule,
		TypeaheadModule,
		VbProfilePictureModule
	]
})
export class VirtualScrollTypeaheadAngularModule {}
