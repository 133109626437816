<ng-template #optionTemplateRef let-item="item" let-insight="insight">
	<ng-container [ngSwitch]="optionTemplate">
		<access-entity-option-row *ngSwitchCase="InsightTemplate.AccessEntityOptionRow" [item]="item" [insight]="insight"></access-entity-option-row>
	</ng-container>
</ng-template>

<ng-template #assignedTemplateRef let-item="item" let-insight="insight">
	<ng-container [ngSwitch]="assignedTemplate">
		<access-entity-assigned-row *ngSwitchCase="InsightTemplate.AccessEntityAssignedRow" [item]="item" [insight]="insight"></access-entity-assigned-row>

		<category-admin-assigned-row *ngSwitchCase="InsightTemplate.CategoryAdminAssignedRow" [item]="item" [insight]="insight"></category-admin-assigned-row>
	</ng-container>
</ng-template>

<insight [name]="name" [attr.aria-label]="label" [insightOptions]="insightOptions" [(ngModel)]="value" (ngModelChange)="onModelChange()" [disabled]="isDisabled" [optionTemplate]="optionTemplate ? optionTemplateRef : undefined" [assignedTemplate]="assignedTemplate ? assignedTemplateRef : undefined">
</insight>
