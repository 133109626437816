import moment from 'moment';

export class DateUtil {
	public static readonly MOMENT_MEDIUM_DATE_FORMAT = 'll';

	public static addDays(date: Date|undefined, numDays: number): Date {
		return moment(date).add(numDays, 'd').toDate();
	}

	public static addHours(date: Date|undefined, numHours: number): Date {
		return moment(date).add(numHours, 'h').toDate();
	}

	public static addMinutes(date: Date|undefined, numMins: number): Date {
		return moment(date).add(numMins, 'm').toDate();
	}

	public static addMonths(date: Date|undefined, numMonths: number): Date {
		return moment(date).add(numMonths, 'M').toDate();
	}

	public static addYears(date: Date|undefined, numYears: number): Date {
		return moment(date).add(numYears, 'y').toDate();
	}

	public static daysBetween(d1: Date, d2: Date): number {
		return Math.round(moment(d1).diff(d2, 'd', true));
	}

	public static daysInMonth(date: Date): number {
		return moment(date).daysInMonth();
	}

	public static daysInMonthOfYear(year: number, month: number): number {
		return moment({
			month: month + 1,
			year
		}).daysInMonth();
	}

	public static getEndOfDay(d: Date = new Date()): Date {
		return moment(d).endOf('d').toDate();
	}

	// gets the UTC Offset in ms
	public static getOffset(date: Date): number {
		return moment.duration(moment(date).utcOffset(), 'm').asMilliseconds();
	}

	/// returns a new Date set to the beginning of the given day/week/month/year
	// d:  a date object or null. If null then current time is used.
	public static getStartOfDay(d: Date = new Date()): Date {
		return moment(d).startOf('d').toDate();
	}

	public static getStartOfMonth(d: Date = new Date()): Date {
		return moment(d).startOf('M').toDate();
	}

	public static getStartOfYear(d: Date = new Date()): Date {
		return moment(d).startOf('y').toDate();
	}

	public static getStartOfWeek(d: Date = new Date()): Date {
		return moment(d).startOf('w').toDate();
	}

	// returns current time of day in ms.
	public static getTimeOfDay(d: Date): number {
		return d.getTime() - DateUtil.getStartOfDay(d).getTime();
	}

	public static getToday(): Date {
		return DateUtil.getStartOfDay();
	}

	public static getYesterday(): Date {
		return DateUtil.getStartOfDay(moment().subtract(1, 'd').toDate());
	}

	public static fromTimestamp(t: number): Date {
		return new Date(t);
	}

	public static isDateRangeOverlap(start1: Date, end1: Date, start2: Date, end2: Date): boolean {
		return end2 > start1 && start2 < end1;
	}

	public static isSameDay(date1: Date, date2: Date): boolean {
		return date1.toDateString() === date2.toDateString();
	}

	public static isValidDate(dateStr: string, format?: string, localeId?: string, strict?: boolean): boolean {
		return moment(dateStr, format, localeId, strict).isValid();
	}

	public static constructMomentLongDateFormat(localeId: string): { [key: string]: string } {
		const LONG_DATE_FORMAT_KEYS: moment.LongDateFormatKey[] = ['LT', 'LTS', 'L', 'LL', 'LLL', 'LLLL'];
		const localeData = moment.localeData(localeId);

		return LONG_DATE_FORMAT_KEYS.reduce((formats, key) => {
			const format = localeData.longDateFormat(key);
			if (format) {
				formats[key] = format;
			}
			return formats;
		}, {});
	}
}
