import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';

import { VbUiSearchInputComponent } from './VbUiSearchInput.Component';


const components = [
	VbUiSearchInputComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		FormsModule,
		TextInputAngularModule,
		TranslateModule
	]
})
export class SearchInputModule {}
