import { NgModule } from '@angular/core';
import { VbTextAreaDirective } from './VbTextArea.Directive';

const directives = [
	VbTextAreaDirective
];

@NgModule({
	declarations: directives,
	exports: directives
})
export class VbTextAreaModule {}
