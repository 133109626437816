import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { object as _object, sortBy } from 'underscore';

import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { UserAccountService } from 'rev-shared/security/UserAccount.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { IVideoField, IRegistrationField } from './Metadata.Contract';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface IAccountFields<T> {
	[accountId: string]: T[];
}

@Injectable({
	providedIn: 'root'
})
export class MetadataService {
	private readonly videoFields: IAccountFields<IVideoField> = {};
	private readonly registrationFields: IAccountFields<IRegistrationField> = {};

	public readonly customRegFieldPrefix: string;

	private videoFields$ = new BehaviorSubject<IAccountFields<IVideoField>>(this.videoFields);
	private registrationFields$ = new BehaviorSubject<IAccountFields<IRegistrationField>>(this.registrationFields);

	constructor(
		TranslateService: TranslateService,
		private http: HttpClient,
		private UserAccount: UserAccountService,
		private UserContext: UserContextService
	) {
		this.customRegFieldPrefix = `(${TranslateService.instant('Custom')})`;
	}

	public get currentAccountVideoFields(): IVideoField[] {
		return this.videoFields[this.UserAccount.currentAdminAccount.id];
	}

	public getVideoFields(accountId): Observable<IVideoField[]> {
		return this.videoFields$.pipe(map(fields => fields[accountId]));
	}

	public getRegistrationFields(accountId): Observable<IRegistrationField[]> {
		return this.registrationFields$.pipe(map(fields => fields[accountId]));
	}

	public getUserContextVideoFields(): IVideoField[]{
		return [...this.videoFields[this.UserContext.getAccount().id]];
	}

	public getAccountVideoFields(accountId: string): IVideoField[] {
		return this.videoFields[accountId];
	}

	public getAccountRegistrationFields(accountId: string): IRegistrationField[] {
		return this.registrationFields[accountId];
	}

	public getVideoField(id: string, accountId?: string): IVideoField {
		if(!accountId){
			accountId = this.UserAccount.currentAdminAccount.id;
		}

		const field = (this.videoFields[accountId] || []).find(field => field.id === id);

		return { ...field };
	}

	public loadAccountVideoFields(accountId: string): Promise<void>{
		return lastValueFrom(this.http.get<any>(`/media/accounts/${accountId}/video-fields`))
			.then(result => {
				this.videoFields[accountId] = result.videoFields || [];
			});
	}

	public getRegistrationField(id: string, accountId: string): IRegistrationField {
		const field = (this.registrationFields[accountId] || []).find(field => field.id === id);

		return { ...field };
	}

	public loadAccountRegistrationFields(accountId: string): Promise<void> {
		return lastValueFrom(this.http.get<any>(`/scheduled-events/accounts/${accountId}/webcast-registration-fields`))
			.then(result => {
				const fields = result.webcastRegistrationFields || [];
				this.registrationFields[accountId] = sortBy(fields, f => -f.includeInAllWebcasts);
			});
	}

	public unloadFields(accountId: string): void {
		delete this.registrationFields[accountId];
		delete this.videoFields[accountId];
	}

	public updateVideoFields(accountId: string, fields: IVideoField[]): void {
		this.videoFields[accountId] = fields;
		this.videoFields$.next(this.videoFields);
	}
	public updateRegistrationFields(accountId: string, fields: IRegistrationField[]): void {
		this.registrationFields[accountId] = fields;
		this.registrationFields$.next(this.registrationFields);
	}
}
