<section [ngClass]="[
	styles.collapsableSectionWrap,
	!expanded ? styles.collapsed : '',
	invalidSection ? styles.invalidSection : ''
]">

	<header class="theme-accent-low" layout="row" flex-align="center" flex-wrap="false" [ngClass]="styles.header" (click)="toggleExpanded()" [attr.aria-label]="headerTitle">
		<h1 flex="fill" [ngClass]="styles.title">
			{{ headerTitle }}
			<span class="glyphicons circle_exclamation_mark" [ngClass]="styles.invalidIcon" [tooltip]="'UI_CollapsableSection_Invalid' | translate">
			</span>
		</h1>

		<button [ngClass]="styles.btnCircle" [tooltip]="(expanded ? 'UI_CollapsableSection_Collapse' : 'UI_CollapsableSection_Expand') | translate" [attr.aria-label]="(expanded ? 'UI_CollapsableSection_Collapse' : 'UI_CollapsableSection_Expand') | translate" type="button">
			<span class="glyphicons" [ngClass]="{
					circle_plus: !expanded,
					circle_minus: expanded
				}">
			</span>
		</button>
	</header>

	<div [ngClass]="styles.contentWrap" [collapse]="!expanded" [isAnimated]="!isIe">
		<div [ngClass]="styles.content">
			<ng-content></ng-content>
		</div>
	</div>

</section>


