import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

import { PushService } from 'rev-shared/push/PushService';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

@Injectable({
	'providedIn': 'root'
})
export class UserSubscriptionsService {
	public contentSubscriptions = { channel: {}, category: {} };

	constructor(
		private http: HttpClient,
		private UserContext: UserContextService,
		private PushService: PushService,
	) {}

	public loadUserSubscriptions(): Promise<any> {
		if (!this.UserContext.isUserAuthenticated()) {
			return Promise.resolve();
		}
		const userId = this.UserContext.getUser().id;
		return lastValueFrom(this.http.get<any>(`/media/users/${userId}/subscriptions`))
			.then(response => {
				response.categorySubscriptions.forEach(categoryId => {
					this.contentSubscriptions.category[categoryId] = true;
				});

				response.channelSubscriptions.forEach(channelId => {
					this.contentSubscriptions.channel[channelId] = true;
				});
			});
	}

	public saveContentSubscription(subscriptionId: any, subscriptionType: string, subscribe?: boolean): Promise<any> {
		return this.PushService.dispatchCommand(subscribe ? 'media:Subscribe' : 'media:Unsubscribe', {
			subscriptionId,
			subscriptionType
		}).then(() => {
			if(subscribe) {
				this.contentSubscriptions[subscriptionType.toLocaleLowerCase()][subscriptionId] = true;
			} else {
				delete this.contentSubscriptions[subscriptionType.toLocaleLowerCase()][subscriptionId];
			}
		});
	}

}
