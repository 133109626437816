<ng-template [vbCssRules]="themeStyleOverrides">
</ng-template>

<ng-template #itemListTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
	<div [ngClass]="styles.itemList">
		<cdk-virtual-scroll-viewport itemSize="25" #itemsViewport [ngClass]="styles.viewport">
			<div [ngClass]="styles.itemListItem" *cdkVirtualFor="let match of dataSource; index as index" [class.active]="isActive(index)" (click)="selectMatch(index, $event)" (mouseenter)="activateItem(index)">
				<div [ngClass]="styles.profileContainer" *ngIf="match.id !== ''">
					<vb-profile-picture [ngClass]="styles.profilePicture" [profileUrl]="match.profileImageUri" [size]="'medium'">
					</vb-profile-picture>
					<h5>{{match.name}} ({{match.username}})</h5>
				</div>
			</div>
		</cdk-virtual-scroll-viewport>
	</div>
</ng-template>

<div>
	<div *ngIf="!isValueSelected">
		<div [ngClass]="styles.inputContainer">
			<span [ngClass]="styles.inputIcon" class="glyphicons search"></span>
			<input #queryInput [(ngModel)]="search" [typeahead]="typeaheadInput$" [typeaheadAsync]="true" [optionsListTemplate]="itemListTemplate" [typeaheadOptionsLimit]="maxInt" [typeaheadWaitMs]="200" (typeaheadOnSelect)="onSelect($event)" typeaheadOptionField="searchField" [attr.aria-label]="ariaLabel">
		</div>
		<span [ngClass]="styles.annotation">{{ 'SingleUserSelect' | translate }}</span>
	</div>
	<div *ngIf="isValueSelected" [ngClass]="styles.selectedOwnerContainer">
		<vb-profile-picture [ngClass]="styles.profilePicture" [profileUrl]="value.profileImageUri" [size]="'medium'">
		</vb-profile-picture>
		<h5>{{value.name}} ({{value.username}})</h5>
		<button (click)="clearSelection()" [attr.aria-label]="'Remove' | translate">
			<span class="glyphicons remove_2"></span>
		</button>
	</div>
</div>
