import { Directive, ElementRef, HostListener } from '@angular/core';


@Directive({
	selector: '[vbSelectOnClick]'
})
export class VbSelectOnClickDirective {
	constructor(
		private el: ElementRef
	) { }

	@HostListener('click')
	public onClick() {
		this.el.nativeElement.select();
	}
}
