import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild,
	forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { noop } from 'rev-shared/util';

import styles from './VbUiSearchInput.Component.module.less';

export interface IVbUiSearchInputOnSearchEvent {
	query: string;
}

/**
 * A specialized text input component for searching or filtering.
 * Has an icon off to the right when empty. When populated, a clear button is surfaced.
 * May be used in 2 ways:
 * - Leveraging the ngModel binding to respond to input changes on the fly.
 * - Leveraging outputs to respond to explicit submissions.
 */
@Component({
	selector: 'vb-ui-search-input',
	templateUrl: './VbUiSearchInput.Component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VbUiSearchInputComponent),
			multi: true
		}
	]
})
export class VbUiSearchInputComponent implements AfterViewInit, ControlValueAccessor {
	@Input() public placeholder: string;

	@Output() public onClear: EventEmitter<any> = new EventEmitter();
	@Output() public onSearch = new EventEmitter<IVbUiSearchInputOnSearchEvent>();

	public _internalValue: string;
	public isDisabled: boolean;
	public readonly styles = styles;
	@ViewChild('textInputField') public textInputField: ElementRef;

	private onChangeCallback: (value: string) => void = noop;
	private onTouchedCallback: () => void;

	public ngAfterViewInit(): void {
		if (!this.placeholder) {
			console.warn('[vb-ui-search-input] Please provide a placeholder.');
		}
	}

	public get isRemoveSurfaced(): boolean {
		return !!this._internalValue?.length;
	}

	public get isSearchSurfaced(): boolean {
		return !this.isRemoveSurfaced;
	}

	public onChangeInternal(): void {
		this.onChangeCallback(this._internalValue);
	}

	public onClearInternal(): void {
		this._internalValue = '';

		this.setFocusToTextInput();

		this.onChangeInternal();
		this.onClear.emit();
	}

	public onFocusInternal(): void {
		this.onTouchedCallback();
	}

	public onSubmitInternal(): void {
		this.onSearch.emit({
			query: this._internalValue
		} as IVbUiSearchInputOnSearchEvent);

		this.setFocusToTextInput(); // IE shifts focus on form submit
	}

	public registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	public setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	public writeValue(value: any): void {
		this._internalValue = value;
	}

	private setFocusToTextInput(): void {
		this.textInputField.nativeElement.focus();
	}
}
