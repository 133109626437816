export function compare<T>(a: T, b: T): number {
	return a > b ? 1 :
		a < b ? -1 : 0;
}

export function orderBy<T, V>(items: T[], valueFn: (item: T) => V, ascending: boolean = true): T[] {
	return items.concat().sort((a, b) => {
		const valA = valueFn(a);
		const valB = valueFn(b);
		return ascending ? compare(valA, valB) : compare(valB, valA);
	});
}
