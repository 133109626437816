import { Directive } from '@angular/core';

import styles from './vb-text-input.module.less';

/**
 * Applies our standard text input styling to a native text input element.
 */
@Directive({
	selector: '[vbUiTextInput]',
	host: {
		'[class]': 'hostClass'
	}
})
export class VbUiTextInputDirective {
	public hostClass: string = `${styles.TextInput} ${styles.TextInputFocusBorder} theme-accent-border-focus`;
}
