import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VbGridListComponent } from './VbGridList.Component';
import { VbGridListColumnComponent } from './VbGridListColumn.Component';
import { VbGridListReorderComponent } from './VbGridListReOrder.Component';
import { VbGridToolbarColumnDirective } from './VbGridToolbarColumn.Directive';
import { VbGridListRowComponent } from './VbGridListRow.Component';
import { VbGridRowCollectionComponent } from './VbGridRowCollection.Component';

const components = [
	VbGridListComponent,
	VbGridListColumnComponent,
	VbGridListReorderComponent,
	VbGridListRowComponent,
	VbGridToolbarColumnDirective,
	VbGridRowCollectionComponent
];

@NgModule({
	imports: [
		CommonModule
	],
	declarations:components,
	exports: components
})
export class VbGridListModule {}
