import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { throwError, merge, Subscription } from 'rxjs';
import { take, takeUntil, takeWhile, switchMap } from 'rxjs/operators';

import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { noop } from 'rev-shared/util';

import { ConfirmationDialogAction } from './ConfirmationDialogAction';
import { IConfirmationDialogParams } from './IConfirmationDialogParams';
import { IConfirmationDialogActionData } from './IConfirmationDialogActionData';

import styles from './ConfirmationDialog.module.less';

@Component({
	selector: 'confirmation-dialog',
	templateUrl: './ConfirmationDialogAngular.Component.html'
})
export class ConfirmationDialogAngularComponent implements OnInit, OnDestroy {
	@Input() public params: IConfirmationDialogParams;

	@Output() public onAction: EventEmitter<IConfirmationDialogActionData> = new EventEmitter();
	@Output() public onDismiss: EventEmitter<void> = new EventEmitter();
	@Output() public onHide: EventEmitter<void> = new EventEmitter(); // either Action or Dismiss (any hide reason)

	private _result: Promise<IConfirmationDialogActionData | void>;
	public readonly ConfirmationDialogAction = ConfirmationDialogAction;
	private isAlive: boolean = true;
	public remember: boolean = false;
	public readonly styles = styles;
	private dismissSub: Subscription;

	constructor(
		private bsModalRef: BsModalRef,
		private bsModalService: BsModalService
	) {}

	public ngOnInit(): void {
		const takeWhileAlive = takeWhile(() => this.isAlive);

		// Hide for any reason
		this.bsModalService.onHide
			.pipe(
				takeWhileAlive,
				take(1)
			)
			.subscribe(() => this.onHide.emit());

		// Listen for any internal or external hide, but ignore those preceded by an action.
		this.dismissSub = this.bsModalService.onHide
			.pipe(
				takeWhileAlive,
				takeUntil(this.onAction.asObservable()),
				take(1)
			)
			.subscribe(() => this.onDismiss.emit());

		// Merge into the expected result Promise where dismiss is considered the catch scenario.
		this._result = lastValueFrom<any>(merge(
			this.onAction,
			this.bsModalService.onHide.pipe(
				switchMap(() => throwError(null))
			)
		).pipe(
			takeWhileAlive,
			take(1)
		));

		this._result.catch(noop);
	}

	public ngOnDestroy(): void {
		this.isAlive = false;

		this.dismissSub?.unsubscribe();
	}

	public get result(): Promise<IConfirmationDialogActionData | void> {
		return this._result;
	}

	public hide(): void {
		this.bsModalRef.hide();
	}

	public onActionInternal(action: ConfirmationDialogAction): void {
		this.onAction.emit({
			action,
			remember: this.remember
		});

		this.bsModalRef.hide();
	}
}
