import {
	TargetState,
	Transition,
	TransitionService
} from '@uirouter/angular';

import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';

import { SecurityContextService } from './SecurityContext.Service';
import { SecurityRedirectHelperService } from './SecurityRedirectHelper.Service';

export function StateInterceptorRun($transitions: TransitionService): void {
	// Error handling
	$transitions.onError({}, (transition: Transition) => {
		const error: any = transition.error();
		const errorDetail: any = error && error.detail;
		const SecurityRedirectHelper = transition.injector().get<SecurityRedirectHelperService>(SecurityRedirectHelperService);

		SecurityRedirectHelper.onError(errorDetail, transition.from().name);
	});

	// Processing of our `secureRedirects` custom config on the state declaration
	$transitions.onBefore({}, (transition: Transition): Promise<TargetState> => {
		const redirectStates = (transition.to() as IVbNg2StateDeclaration).secureRedirects;
		const SecurityContext = transition.injector().get<SecurityContextService>(SecurityContextService);

		if (redirectStates) { // not already preventing the change
			return SecurityContext.$promise
				.then(() => {
					const redirectState: any = SecurityContext.getFirstAllowedStateChange(redirectStates);

					if (redirectState) {
						return transition.router.stateService.target(redirectState, transition.params());
					}

					return transition.router.stateService.target('401', transition.params(), { reload: true });
				});
		}
	});

	// Processing of our `resetZoom` custom config on the state declaration
	$transitions.onSuccess({}, (transition: Transition) => {
		if ((transition.to() as IVbNg2StateDeclaration).resetZoom) {
			resetZoom();
		}
	});

	/**
	 * Resets the zoom in mobile browsers. However, some browsers have started to block this.
	 */
	function resetZoom(): void {
		const viewportMeta: HTMLMetaElement = document.head.querySelector('meta[name="viewport"]') as HTMLMetaElement;
		const originalViewportMetaContent = viewportMeta.content;

		// force zoom out by intermitently applying a max-scale
		viewportMeta.content = originalViewportMetaContent + ', maximum-scale=1';
		viewportMeta.content = originalViewportMetaContent;

		window.scrollTo(0, 0);
	}
}
