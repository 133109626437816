import { LocaleData } from '../locale/locale.class';

// ! moment.js locale configuration
// ! locale : Romanian [ro]
//! author : Vlad Gurdiga : https://github.com/gurdiga
//! author : Valentin Agachi : https://github.com/avaly
// ! author : Earle white: https://github.com/5earle

function relativeTimeWithPlural(num: number, withoutSuffix: boolean, key: string): string {
  let format: {[key:string]: string} = {
    ss: 'secunde',
    mm: 'minute',
    hh: 'ore',
    dd: 'zile',
    MM: 'luni',
    yy: 'ani'
  };

  let separator = ' ';
  if (num % 100 >= 20 || (num >= 100 && num % 100 === 0)) {
    separator = ' de ';
  }
  return num + separator + format[key];
}


export const roLocale: LocaleData = {
  abbr: 'ro',
  months: 'ianuarie_februarie_martie_aprilie_mai_iunie_iulie_august_septembrie_octombrie_noiembrie_decembrie'.split('_'),
  monthsShort: 'ian._febr._mart._apr._mai_iun._iul._aug._sept._oct._nov._dec.'.split('_'),
  monthsParseExact: true,
  weekdays: 'duminică_luni_marți_miercuri_joi_vineri_sâmbătă'.split('_'),
  weekdaysShort: 'Dum_Lun_Mar_Mie_Joi_Vin_Sâm'.split('_'),
  weekdaysMin: 'Du_Lu_Ma_Mi_Jo_Vi_Sâ'.split('_'),
  longDateFormat: {
    LT: 'H:mm',
    LTS: 'H:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY H:mm',
    LLLL: 'dddd, D MMMM YYYY H:mm'
  },
  calendar: {
    sameDay: '[azi la] LT',
    nextDay: '[mâine la] LT',
    nextWeek: 'dddd [la] LT',
    lastDay: '[ieri la] LT',
    lastWeek: '[fosta] dddd [la] LT',
    sameElse: 'L'
  },
  relativeTime: {
    future: 'peste %s',
    past: '%s în urmă',
    s: 'câteva secunde',
    ss: relativeTimeWithPlural,
    m: 'un minut',
    mm: relativeTimeWithPlural,
    h: 'o oră',
    hh: relativeTimeWithPlural,
    d: 'o zi',
    dd: relativeTimeWithPlural,
    M: 'o lună',
    MM: relativeTimeWithPlural,
    y: 'un an',
    yy: relativeTimeWithPlural
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 7  // The week that contains Jan 1st is the first week of the year.
  }
};
