import { Directive, EventEmitter, Input, AfterViewInit, OnChanges, Output, SimpleChanges } from '@angular/core';

import styles from './InlineEdit.module.less';

@Directive({})
export class BaseInlineEditAngularComponent<TValue> implements AfterViewInit, OnChanges {
	@Input() public required: boolean;
	@Input() public value: TValue;

	@Output() public onSubmit = new EventEmitter<TValue>();
	@Output() public onCancel = new EventEmitter<void>();

	public readonly styles = styles;

	public internalValue: any;

	public ngAfterViewInit(): void {
		this.internalValue = this.value;
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if(changes.value) {
			this.internalValue = this.value;
		}
	}

	public cancel(): void {
		this.onCancel.emit();
	}

	public submit(): void {
		this.onSubmit.emit(this.internalValue);
	}
}
