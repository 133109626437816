import { EventEmitter, Output, Component } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { throwError, merge } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';

import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { noop } from 'rev-shared/util';

@Component({
	selector: 'vb-dialog-base',
	template:''
})
export class VbDialogBaseComponent<T> {
	@Output() public onAction: EventEmitter<any> = new EventEmitter();
	private _result: Promise<T>;

	constructor(
		private bsModalRef: BsModalRef,
		private bsModalService: BsModalService
	) {}

	public ngOnInit(): void {
		this._result = lastValueFrom<any>(merge(
			this.onAction,
			this.bsModalService.onHide.pipe(
				switchMap(() => throwError(null))
			)
		).pipe(
			take(1)
		));

		this._result.catch(noop);
	}

	public get result(): Promise<T> {
		return this._result;
	}

	public close(): void {
		this.bsModalRef.hide();
	}

	public onActionInternal(params?: any): void {
		this.onAction.emit({
			params
		});

		this.bsModalRef.hide();
	}
}
