import { Pipe, PipeTransform } from '@angular/core';

import { formatTimespanShort } from './DateFormatters';

@Pipe({ name: 'vbTimespan' })
export class VbTimespanPipe implements PipeTransform {
	public transform(input: number, hours?: boolean, seconds?: boolean): string {
		return formatTimespanShort(input, hours, seconds);
	}
}
