<camera-spinner [ngClass]="styles.spinner" [msg]="'Media_Recording_InProgress' | translate" [state]="SpinnerState.INACTIVE">
</camera-spinner>

<div [ngClass]="styles.timer">
	{{ recordingTime }}
</div>

<button type="button" *ngIf="isVideoUploader" [ngClass]="styles.stopBtn" (click)="stopRecording()" vbUiBtnDanger>
	<span class="glyphicons stop icon-light"></span>
	{{ 'Media_Recording_Stop' | translate }}
</button>
