import { NgModel } from '@angular/forms';
import { Input, Directive, AfterViewInit, Self, Host } from '@angular/core';
import { isArray } from 'rev-shared/util';

/**
 * Clears a single error key: <input [vbClearOnChange]="'key'"
 *
 * Clears multiple error keys: <input [vbClearOnChange]="['key1', 'key2', ...]"
 */
@Directive({
	selector: '[vbClearOnChange]'
})
export class ClearOnChangeDirective implements AfterViewInit {
	@Input() public vbClearOnChange: string | string[];

	constructor(
		@Host() public ngModel: NgModel
	) {
	}

	public ngAfterViewInit(): void {

		this.ngModel.control.registerOnChange(() => {
			if(!this.ngModel.invalid) {
				return;
			}

			const keys = isArray(this.vbClearOnChange) ? this.vbClearOnChange : [this.vbClearOnChange];
			const errors = keys.reduce((errors, key) => (delete errors[key], errors), this.ngModel.errors);
			this.ngModel.control.setErrors(errors);
		});
	}
}
