import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, map, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
//Stores height and other layout info of main nav header to be used in layout calculations elsewhere.
export class MainHeaderPositionService {
	private heightSubject$ = new BehaviorSubject<number>(undefined);
	private transparencyActiveSubject$ = new BehaviorSubject<boolean>(false);

	public transparencyActive$: Observable<boolean> = this.transparencyActiveSubject$.asObservable();

	public readonly height$: Observable<number> = combineLatest([
		this.heightSubject$.asObservable(),
		this.transparencyActiveSubject$.asObservable()
	])
		.pipe(
			map(([val, isTransparencyActive]) => isTransparencyActive ? 0 : val)
		);

	public isTransparencyActive: boolean;
	public isTransparencySupported: boolean;

	public get height(): number {
		return this.heightSubject$.getValue();
	}

	public get layoutHeight(): number {
		return this.isTransparencyActive ? 0 : this.height;
	}

	public setHeight(h: number): void {
		this.heightSubject$.next(h);
	}

	public setTransparency(isActive: boolean): void {
		this.transparencyActiveSubject$.next(isActive);

		//backward compatibility for now.
		this.isTransparencyActive = isActive;
	}
}
