export class CacheFactory<T=any> {
	private map: Map<string, any> = new Map(); // according to spec, the insertion order is preserved for iteration when using string keys

	constructor(
		private capacity: number
	) {}

	public get(key: string): T {
		return this.map.get(key);
	}

	public put(key: string, value: T): void {
		this.map.set(key, value);

		this.enforceCapacity();
	}

	public remove(key: string): void {
		this.map.delete(key);
	}

	private enforceCapacity(): void {
		const entriesIterator = this.map.entries();

		while (this.map.size > this.capacity) {
			const [entryKey] = entriesIterator.next().value;

			this.remove(entryKey);
		}
	}
}
