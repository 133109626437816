import { SignalRHubsConnection } from './SignalRHubsConnection';

export default function SignalrRun(SignalRStartup: Promise<any>, SignalRHubsConnection: SignalRHubsConnection): void {
	const retryTime: number = 600000; // 10 min
	const minRetryDelay: number = 5000;
	const maxRetryDelay: number = 20000; // Randomize retry delay to break up large groups of users all reconnecting at the same time

	function rand(min: number, max: number): number {
		return Math.random() * (max - min) + min;
	}

	SignalRStartup.then(() => {
		const options: SignalR.ConnectionOptions = SignalRHubsConnection.options;
		const cfg: any = SignalRHubsConnection.getConnectionOptions();
		let reconnecting: boolean = false;

		if(!cfg.autoReconnect) {
			return;
		}

		SignalRHubsConnection.on('disconnected', () => {
			console.log('Signalr disconnected');

			if(!reconnecting && SignalRHubsConnection.started) {
				reconnecting = true;
				reestablishConnection();
			}
		});

		SignalRHubsConnection.on('ConnectionReestablished', () => reconnecting = false);

		function reestablishConnection() {
			console.log('SignalR disconnected, attempting to reconnect');

			const retryUntil: number = Date.now() + retryTime;

			tryConnect();

			function tryConnect(): void {
				window.setTimeout(() => {
					if(SignalRHubsConnection.started) {
						console.log('Attempting to reestablish signalR connection');

						SignalRHubsConnection.start(options, true)
							.catch(() => {
								if(Date.now() > retryUntil) {
									SignalRHubsConnection.fire('ReestablishConnectionFailed');
									console.error('Unable to reestablish signalR connection');
								} else {
									console.log('Connection attempt failed, will retry');
									tryConnect();
								}
							});
					}
				}, rand(minRetryDelay, maxRetryDelay), false);
			}
		}
	});
}
