import { Directive } from '@angular/core';

import styles from './vb-btn-secondary.module.less';

@Directive({
	selector: '[vbUiBtnSecondary]',
	host: {
		'[class]': 'styles.vbSecondaryBtn'
	}
})
export class VbUiBtnSecondaryDirective {
	public readonly styles = styles;
}
