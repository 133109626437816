
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VbHttpParameterCodec } from './HttpParameterCodec';

@Injectable()
export class HttpParamsInterceptor implements HttpInterceptor {

	/**
		Formatting/cleanup of requests. Fixes several issues with default query param formatting:
			null and undefined were not being pruned
			character formatting issues - for example '+' is converted to space instead of getting encoded
	 */
	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const oldParams = request.params;
		const data = {};
		for (const key of oldParams.keys()) {
			if (oldParams.get(key) != null) {
				data[key] = oldParams.getAll(key);
			}
		}

		return next.handle(request.clone({
			params: new HttpParams({ encoder: new VbHttpParameterCodec(), fromObject: data })
		}));
	}
}

export const HttpParamsInterceptorProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: HttpParamsInterceptor,
	multi: true
};
