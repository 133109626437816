import { NgModule } from '@angular/core';

import { VbOnResizeDirective } from './VbOnResize.Directive';
import { OffsetHeightModelAngularDirective } from './OffsetHeightModelAngular.Directive';

const declarations = [
	VbOnResizeDirective,
	OffsetHeightModelAngularDirective
];

@NgModule({
	declarations: [
		declarations
	],
	exports: [
		declarations
	]

})
export class WindowSizeAngularModule {}
