import {
	Directive,
	Input,
	forwardRef
} from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';

import { ValidationRules } from 'rev-shared/util/ValidationRules';

@Directive({
	selector: '[vbEmail]',
	providers: [{
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => VbEmailDirective),
		multi: true
	}]

})
export class VbEmailDirective {
	@Input() public vbEmailActive: boolean;

	public validate(control: AbstractControl): ValidationErrors {
		return (this.vbEmailActive === false || ValidationRules.checkEmail(control.value)) ?
			null :
			{ vbEmail: true };
	}
}
