import { EMPTY, merge, Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { PushBus } from 'rev-shared/push/PushBus.Service';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { DeviceHealthStatus } from 'rev-shared/device/SharedDeviceContract';
import { WebcastStatus } from '../WebcastStatus';
import { WebcastModel } from './WebcastModel';
import { WebcastService } from '../Webcast.Service';
import { isPushError } from 'rev-shared/push/PushService';

export class RecordingStatus {
	public isDeviceOffline: boolean;
	public isProcessingRequest: boolean;
	private _isFailed: boolean;
	public isSetToRecord: boolean;
	public recordingCount: boolean;
	public recordingDeviceId: string;
	public hasBeenActive: boolean;
	public isStopping: boolean;
	public isRecording: boolean;
	private autoStopRecordingHandler: (err: any) => Promise<void>;

	constructor(
		private webcast: WebcastModel,
		private PushBus: PushBus,
		private WebcastService: WebcastService
	){
		this.isProcessingRequest =
			this.webcast.status === WebcastStatus.RecordingStarting ||
			this.webcast.status === WebcastStatus.RecordingStopping;
	}

	public reset(): void {
		this.isActive = false;
		this.isProcessingRequest = false;
		this._isFailed = false;
	}

	public startRecording(): Promise<void> {
		this.isProcessingRequest = true;
		return this.WebcastService.startRecording(this.webcast)
			.then((result: any) => {
				if(result.type === 'StartRecordingFailed'){
					return Promise.reject(result.type);
				}
			})
			.catch((err: any) => {
				console.error('start recording error: ', err);

				if(isPushError(err)){
					if(err.hasIssue('AlreadyStarting')) {
						return;
					}
					this.reset();
					return Promise.reject(err.getIssueIds()[0]);
				}
				this.reset();
				return Promise.reject(err);
			});
	}

	//isAutoStop set when user clicks stop broadcast button during a recording
	public stopRecording(isAutoStop: boolean = false): Promise<void> {
		const result = this.WebcastService.stopRecording(this.webcast);
		if (!isAutoStop) {
			this.isSetToRecord = false;
		}

		if(isAutoStop && this.autoStopRecordingHandler) {
			return this.autoStopRecordingHandler(result);
		}

		return result;
	}

	public subscribePush(): IUnsubscribe {
		const subscription = this.webcast.currentUser.isEventAdminOrModerator$.pipe(
			switchMap(isAdmin => !isAdmin ? EMPTY : merge(
				new Observable(() => this.subscribeEventAdminPush()),
				this.getRecordingDevicePushObservable()
			))
		)
			.subscribe();

		return (() => subscription.unsubscribe()) as any;
	}

	public initRecordingDeviceStatus(): void {
		if (this.deviceId && this.webcast.currentUser.isEventAdmin) {
			this.isProcessingRequest = true;
			this.WebcastService.getRecordingDeviceDetails(this.deviceId)
				.then(({ healthStatus }) => {
					this.isDeviceOffline = healthStatus === DeviceHealthStatus.Error;
				})
				.catch(err => console.error('error in initial status', err))
				.finally(() => this.isProcessingRequest = false);
		}
	}

	private subscribeEventAdminPush(): IUnsubscribe {
		const reset = () => this.reset();
		return this.PushBus.subscribe(this.webcast.id, undefined, {
			RecordingStarting: data => {
				this.webcast.recording.deviceId = data.recordingDeviceId;
				this.isProcessingRequest = true;
			},

			RecordingStarted: data => {
				this.isActive = true;
				this.isProcessingRequest = false;
				this.webcast.recording.deviceId = data.recordingDeviceId;
			},

			StartRecordingFailed: () => {
				this.isProcessingRequest = false;
				this.isFailed = true;
				this.resetRecordingDevice();
			},

			RecordingStopping: () => {
				this.isProcessingRequest = true;
			},

			StopRecordingFailed: () => {
				this.isProcessingRequest = false;
			},

			RecordingDeviceStopped: data => {
				this.resetRecordingDevice();
				this.isReconnecting = data.recordingIsReconnecting;
			},

			RecordingStopped: data => {
				this.reset();
				this.resetRecordingDevice();
				this.isReconnecting = data.recordingIsReconnecting;
			},

			FailedRecordingStopped: data => {
				this.reset();
				this.resetRecordingDevice();
				this.isReconnecting = data.recordingIsReconnecting;
			},

			RecordingStreamStatusUpdated: data => {
				this.isReconnecting = data.isReconnecting;
			},

			WebcastStarted: reset,
			WebcastEnded: reset,
			ScheduledEventStopped: reset
		});
	}

	private getRecordingDevicePushObservable() {
		return this.webcast.webcast$.pipe(
			map(w => w.recordingDeviceId),
			distinctUntilChanged(),
			switchMap(id => !id ? EMPTY :
				this.PushBus.getObservable(id, 'Webcast.EventAdmin', {
					DeviceOffline: () => {
						this.isDeviceOffline = true;
						return EMPTY;
					},

					DeviceOnline: () => {
						this.isDeviceOffline = false;
						return EMPTY;
					}
				})
			)
		);
	}

	private resetRecordingDevice(): void {
		this.deviceId = null;
		this.isDeviceOffline = false;
	}

	public setAutoStopRecordingHandler(handler: (stopRecording: Promise<void>) => Promise<void>): void {
		this.autoStopRecordingHandler = handler;
	}

	public get deviceId(): string {
		return this.webcast.recordingDeviceId;
	}
	public set deviceId(value: string) {
		this.webcast.recordingDeviceId = value;
		this.webcast.update({
			recordingDeviceId: value
		});
	}

	public get isActive(): boolean {
		return this.webcast.isRecording;
	}
	public set isActive(value: boolean) {
		this.webcast.isRecording = value;
	}

	public get isDeviceUnavailable(): boolean {
		return this.isDeviceOffline;
	}

	public get isFailed(): boolean {
		return this._isFailed;
	}
	public set isFailed(value: boolean) {
		this._isFailed = !!value;
		this.isActive = false;
		this.isProcessingRequest = false;
	}

	public get isReconnecting(): boolean {
		return this.webcast.recordingIsReconnecting;
	}

	public set isReconnecting(value: boolean) {
		this.webcast.recordingIsReconnecting = value;
	}

	public get initiateAutoRecording(): boolean {
		return !this.isDeviceOffline &&
			(
				this.webcast.isRecordingMandatory() ||
					this.webcast.isStartOrStopRecordingAllowed() && this.isSetToRecord
			);
	}
}
