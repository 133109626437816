import {
	Directive,
	HostListener,
	Output,
	EventEmitter,
	Input
} from '@angular/core';

@Directive({
	selector: '[vbKeypress]',
})
export class VbKeypressDirective {
	@Input() public isPreventDefault: boolean;
	@Input() public isKeyUp: boolean = false;

	@Output() public enterKeyPressed = new EventEmitter<KeyboardEvent>();

	@HostListener('keydown.enter', ['$event'])
	public onKeydownEnterHit(event: KeyboardEvent) {
		if (!this.isKeyUp) {
			this.emitKeyPressEvent(event);
		}
	}

	@HostListener('keyup.enter', ['$event'])
	public onKeyupEnterHit(event: KeyboardEvent) {
		if (this.isKeyUp) {
			this.emitKeyPressEvent(event);
		}
	}

	public emitKeyPressEvent(event: KeyboardEvent) {
		this.enterKeyPressed.emit(event);

		if (this.isPreventDefault) {
			event.preventDefault();
			return false;
		}
	}
}
