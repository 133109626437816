import { IRules, IDeclarations } from './CssRules.Contract';

export function getCss(rules: IRules): string {
	return Object.entries(rules || {})
		.map(([selector, ruleBody]) => {
			if(selector.startsWith('@media')){
				return getMediaRule(selector, ruleBody as IRules);
			}

			return getRule(selector, ruleBody as IDeclarations);
		})
		.join('');
}

function getRule(selector: string, declarations: IDeclarations): string {
	return `${selector} {${getDeclarationsBlock(declarations)}}`;
}

function getMediaRule(mediaQuery: string, ruleBody: IRules){
	return `${mediaQuery} {${getCss(ruleBody)}}`;
}

function getDeclarationsBlock(declarations: IDeclarations): string {
	return Object.entries(declarations).map(([property, value]) => `${property}:${value};`)
		.join('');
}

export function appendStyles(id: string): HTMLStyleElement {
	const style = document.createElement('style');
	if(id) {
		style.id = id;
	}

	document.head.appendChild(style);

	return style;
}

export function applyStyles(style: HTMLStyleElement, rules: IRules): void {
	if(style) {
		style.innerHTML = getCss(rules);
	}
}

export function removeStyle(style: HTMLStyleElement): void {
	if(style) {
		document.head.removeChild(style);
	}
}
