import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { IInsightOptions, IInsightRecord, IQueryPage } from './Insight.Contract';
import { sort } from './util';

export class OptionsDataSource extends DataSource<IInsightRecord> {
	private items$: BehaviorSubject<IInsightRecord[]>;

	public overLimit: boolean;
	public loading: boolean;

	constructor(
		private insightOptions: IInsightOptions
	) {
		super();

		this.items$ = new BehaviorSubject<IInsightRecord[]>([...this.insightOptions.data]);
	}

	public get items() {
		return this.items$.getValue();
	}

	public connect(viewer: CollectionViewer): Observable<IInsightRecord[]> {
		return this.items$.asObservable();
	}

	public disconnect(): void {
		this.reset();
	}

	public teardown(): void {
		this.items$.complete();
	}

	public updateStaticOptions(query: string): void {
		const data = this.filterOptions(this.insightOptions.data, item => this.insightOptions.filter(query, item));
		this.items$.next(data);
	}

	public setQueryResults(query: string, { items, count }: IQueryPage): void {
		this.loading = false;
		this.overLimit = query && items.length < count;

		const data = this.filterOptions(items);
		this.items$.next(data);
	}

	public setLoading(): void {
		this.loading = true;
	}

	public reset(): void {
		this.loading = false;
		this.overLimit = false;
		this.items$.next([]);
	}

	private filterOptions(data: IInsightRecord[], showItem?: (_:IInsightRecord) => boolean): IInsightRecord[] {
		data = data || [];
		data = data.filter(item => item._loaded && (!showItem || showItem(item)));
		data = sort(data, this.insightOptions.fieldDefs.orderBy, this.insightOptions.fieldDefs.ascending);
		return data;
	}
}
