export enum BufferingSubType {
	INITIAL = 'Initial',
	PLAYBACK = 'Playback'
}

export function getBufferingSubType(isInitial: boolean): string {
	return isInitial ?
		BufferingSubType.INITIAL :
		BufferingSubType.PLAYBACK;
}
