export enum VideoSourceType {
	API = 'API',
	REV = 'REV',
	VIDEO_CONFERENCE = 'VIDEO CONFERENCE',
	WEBEX = 'WEBEX',
	WEBEX_EVENTS = 'WebexEvents',
	WEBEX_LIVE_STREAM = 'WebexLiveStream',
	RTMP_STREAM = 'RtmpStream',
	LIVE_ENRICHMENT = 'LiveEnrichment',
	WEBRTC_RECORDING = 'WebrtcRecording',
	WEBRTC_SINGLE_PRESENTER = 'WebrtcSinglePresenter'
}

export const DualPlaybackSourceTypes: string[] = [
	VideoSourceType.VIDEO_CONFERENCE,
	VideoSourceType.WEBRTC_RECORDING,
	VideoSourceType.WEBRTC_SINGLE_PRESENTER
];
