import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';

@Component({
	selector: 'vb-playback-menu-button-legacy',
	templateUrl: './VbPlaybackMenuButtonLegacy.Component.html'
})
export class VbPlaybackMenuButtonLegacyComponent implements OnInit {
	@Input() public playbackOptions: any[];
	@Input() public selectedPlaybackUrl: string;

	@Output() public onPlaybackOptionChange: EventEmitter<{ playbackOption: any }> = new EventEmitter();
	@Output() public onToggle: EventEmitter<{ open: boolean }> = new EventEmitter();

	public selectedPlayback: { url: string };

	public ngOnInit(): void {
		this.selectedPlayback = { url: this.selectedPlaybackUrl };
	}

	public onToggleInternal(open: boolean): void {
		this.onToggle.emit({ open });
	}

	public updateSelection(playbackOption: any): void {
		if (this.selectedPlayback.url !== playbackOption.url) { //ignore if it's already selected
			this.selectedPlayback.url = playbackOption.url;

			this.playbackOptions.forEach(option =>	option.selected = false);
			playbackOption.selected = true;

			this.onPlaybackOptionChange.emit({ playbackOption });
		}
	}
}
