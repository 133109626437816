import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { CssRulesAngularModule } from 'rev-shared/ui/css/Css.AngularModule';

import { VbAuthLayoutComponent } from './VbAuthLayout.Component';

@NgModule({
	declarations: [VbAuthLayoutComponent],
	exports: [VbAuthLayoutComponent],
	imports: [
		CommonModule,
		TranslateModule,
		CssRulesAngularModule
	]
})
export class VbAuthLayoutModule {}
