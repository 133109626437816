import { Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';

@Injectable()
export class VbTranslateParser extends TranslateDefaultParser {
	public templateMatcher = /{\s?([^{}\s]*)\s?}/g; // close to the default, but with single {} instead of {{}}

	public getValue(target: any, key: string): any {
		return super.getValue(target, key[0].toLowerCase() + key.slice(1));
	}
}
