import { Injectable } from '@angular/core';
import moment from 'moment';

import { locale$ } from 'rev-shared/bootstrap/LocaleContext';
import { distinct } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class MomentLocaleLoader {
	private result: Promise<void> = null;

	public load(): Promise<void> {
		if(this.result) {
			return this.result;
		}
		return this.result = this.init();
	}

	private init(): Promise<void> {
		return new Promise(resolve => {
			locale$.pipe(distinct())
				.subscribe(({ momentLocale }) => {
					if(!momentLocale) {
						resolve();
						return;
					}
					this.loadLocale(momentLocale)
						.then(resolve);
				});
		});
	}

	private loadLocale(locale: string): Promise<any> {
		return import(`moment/locale/${locale}.js`)
			.then(() => moment.locale(locale));
	}
}
